import { useUserPreferencesState } from "./useUserPreferences";
import { useCallback } from "react";
import { useProjectState } from "../Hooks/useProject";
import { applicationsTypes } from '../controller/Constants'
export function useGetTechnologiesCoverage() {
  const [useUserPreferences] = useUserPreferencesState();
  const [useProject,] = useProjectState()
  const { config } = useProject;

  return useCallback((binsArray) => {
    if (binsArray.length <= 0)
      return []
    if (useUserPreferences.technologies.length > 1)
      return []

    let applicationsThresholds = []
    if (config && config.dataAccess && config.dataAccess.applications) {
      const applications = config.dataAccess.applications;
      applications.forEach(app => {
        Object.entries(app.thresholds).forEach(([technology, { ranges, operationalThreshold }]) => {
          if (technology !== useUserPreferences.technologies[0]) {
            return
          }
          let count = 0;
          binsArray.forEach(bin => {
            if (bin.signal > operationalThreshold) {
              count++;
            }
          });
          applicationsThresholds.push({ name: app.name, label: app.name === applicationsTypes.VOICE_CALL ? `${applicationsTypes.VOICE_CALL} (default)` : app.name, value: Number((count / binsArray.length) * 100).toFixed(), range: ranges, })
        });
      });

    }

    return applicationsThresholds

  }, [useUserPreferences.technologies, useProject]);
}
