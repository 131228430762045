import React, { useEffect, useMemo } from "react";
import GeoJsonDataDrawer from "../../Common/GeoJsonDataDrawer/GeoJsonDataDrawer";
import { convertDataToGeoJson } from "./utils"

const PREDICTION_BASE_STYLE = {
    fillOpacity: 0.6,
    strokeWeight: 2,
    strokeOpacity: 0,
}
const SAMPLE_BASE_STYLE = {
    fillOpacity: 1,
    strokeOpacity: 0,
    strokeWeight: 2,
}
const styleConfig = {
    "prediction": (feature) => {
        const color = feature.getProperty('color');
        return {
            ...PREDICTION_BASE_STYLE,
            fillColor: color,
            strokeColor: color,
            zIndex: feature.getProperty('zIndex')
        }
    },
    "sample": (feature) => {
        const color = feature.getProperty('color');
        return {
            ...SAMPLE_BASE_STYLE,
            fillColor: color,
            strokeColor: color,
            zIndex: feature.getProperty('zIndex')
        }
    },
}

function CoveragOverlay({ data, map, listenersConfig }) {
    const geoJson = useMemo(() => convertDataToGeoJson(data), [data])
    const coveragLayer = useMemo(() => new window.google.maps.Data(), [])

    return <GeoJsonDataDrawer dataLayer={coveragLayer} map={map} data={geoJson} listenersConfig={listenersConfig} styleConfig={styleConfig} />;
}

export default CoveragOverlay;

//TODO: docs =>
    // const data = {
    //     bins:"",
    //     algorithm:"",
    //     binResolution:""
    // }