import Table from 'react-bootstrap/Table';
import React, { forwardRef, useEffect, useState } from "react";
import { defaultTechnologiesCoverage, techUsageCategoriesThresholds } from '../../../controller/Constants';

function ApplicationQualityTable({ data, lineData, date, name, providers }, ref) {
  const [tableData, setTableData] = useState();
  useEffect(() => {
    setTableData(data[0]?.applicationQuality)
  }, [data]);

  return (
    <div id={name} ref={(element) => ref.current.add(element)}  >
      {tableData && <Table class="table table-image" hover style={{ marginRight: "auto", marginLeft: "auto", width: "60%" }} >

        <thead>
          <tr>
            <th>Application</th>

            {providers.map((item, index) => (
              <th>{providers[index]}</th>))}
          </tr>
        </thead>

        {/* to do do with loop  */}

        <tbody >
          <tr>
            <td>Email & Text message</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Email & Text message']} %</td>
            ))}
          </tr>
          <tr>
            <td>Voice call</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Voice call']} %</td>
            ))}

          </tr>
          <tr>
            <td>Audio app</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Audio app']} %</td>
            ))}
          </tr>
          <tr>
            <td>Download files</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Download files']} %</td>
            ))}
          </tr>
          <tr>
            <td>Web browsing</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Web browsing']} %</td>
            ))}
          </tr>
          <tr>
            <td>Video sharing</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Video sharing']} %</td>
            ))}
          </tr>
          <tr>
            <td>Streaming app</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Streaming app']} %</td>
            ))}
          </tr>
          <tr>
            <td>Gaming</td>
            {providers.map((item, index) => (
              <td>{tableData[providers[index]]['Gaming']} %</td>
            ))}
          </tr>

        </tbody>
      </Table>}
    </div>
  );
}

export default forwardRef(ApplicationQualityTable);
