import Constants from "../Constants";
import { fetchAuth, handleError, navigateResponse, getDateRangePatch } from "./helper";
import poll from "../polling";

const getRange = (start, end) => Array.from({ length: end - start }, (v, k) => k + start);

export const Sensors = class {
  static async getSensorsInfo(projectId) {
    try {
      var url = new URL(`${Constants.REST_URL}projects/${projectId}/sensorsinfo`)
      const res = await fetchAuth(url, { method: "GET" }).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }
  static async deleteSensorsInfo(projectId, sensorInfoId) {
    try {
      var url = new URL(`${Constants.REST_URL}projects/${projectId}/sensorsinfo`)
      url.searchParams.append('sensorinfoid', sensorInfoId);
      const res = await fetchAuth(url, { method: "DELETE" }).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }
  static async createS3Multipart(projectId, fileName) {
    try {
      var url = new URL(`${Constants.REST_URL}get-sensors-uploader/${projectId}`)
      url.searchParams.append('fileName', fileName);
      const res = await fetchAuth(url, { method: "POST" }).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }
  static async uploadS3part(sensorInfoId, PartNumber, options) {
    try {
      var url = new URL(`${Constants.REST_URL}sensors-upload-part`)
      url.searchParams.append('PartNumber', PartNumber);
      url.searchParams.append('SensorInfoId', sensorInfoId);
      const res = await fetchAuth(url, options).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }
  static async completeS3multiPart(sensorInfoId, eTagsList) {
    try {
      var url = new URL(`${Constants.REST_URL}complete-sensors-uploader`)
      url.searchParams.append('SensorInfoId', sensorInfoId);
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({ "eTagsList": eTagsList })
      }).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }


  static async getSensorsCoverage(projectId, userPreferences, windowViewPolygon, resolution) {
    try {
      let pageNumber = 0;
      const batchSize = 5;
      let url = new URL(`${Constants.REST_URL}get-sensors-coverage/${projectId}`)
      let sensors = [];
      const params = {
        "requestPreferences": userPreferences,
        "tech": userPreferences.technologies[0],
        "userPolygon": userPreferences.polygon,
        "dateRange": getDateRangePatch(userPreferences.relativeDateRange),
        windowViewPolygon,
        resolution,
        pageNumber,
      }
      const realRes = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify(params)
      }).then(navigateResponse);

      const totalPagesNum = realRes.total_pages
      for (let i = 0; i < totalPagesNum; i += batchSize) {
        const tasks = [];
        getRange(i, i + batchSize).forEach(index => {
          if (index >= totalPagesNum) return;
          tasks.push(fetchAuth(url, {
            method: "POST",
            body: JSON.stringify({ ...params, pageNumber: index })
          }).then(navigateResponse))
        })

        const sensorsBatch = await Promise.all(tasks);
        sensors.push(...sensorsBatch.flatMap(batch => batch.sensors_groups));
      }

      return sensors;
    } catch (error) {
      handleError(error);
    }
  }

  static async checkSensorInfoStatus(sensorInfoId) {
    try {
      var url = new URL(`${Constants.REST_URL}check-sensors-info`)
      url.searchParams.append('SensorInfoId', sensorInfoId);
      const res = await fetchAuth(url, { method: "GET" }).then(navigateResponse);
      return res;
    } catch (error) {
      handleError(error);
    }
  }

  static async pollingSensorInfoStatus(sensorInfoId) {
    const validateStatus = status => status === "Ready";
    const POLL_INTERVAL = 2000;
    try {
      return poll({
        fn: Sensors.checkSensorInfoStatus(sensorInfoId),
        validate: validateStatus,
        interval: POLL_INTERVAL,
      }).then(status => console.log("status: ", status))

    } catch (error) {
      handleError(error);
    }
  }
};




