import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import Collapse from "react-bootstrap/Collapse";
import Alert from "react-bootstrap/Alert";
import {useTranslation} from "react-i18next";

const Container = styled.div`
    position:absolute;
    bottom: 0;
    left:10px;
    .alert-view{
        width:17vw;
    }
    .spinner{
      margin-bottom: 10px;
    }
    .progress{
        margin-top:10px;
    }
`;

function AsyncLoader({loading, progress}) {
    const [progressState, setProgressState] = useState({...progress, steps: 0});
    const {steps, end} = progressState;
    const percent = 100 * (steps / (end || 0.00000001));
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpen(loading);
        }, loading ? 0 : 1000);
        return () => {
            clearTimeout(timeoutId)
        };
    }, [loading]);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setProgressState(progress);
        }, 200);
        return () => {
            clearTimeout(timeoutId)
        };
    }, [progress.steps, progress.end]);
    return (<Container>
        <Collapse in={open}>
            <div>
                <Alert className={'alert-view'} variant={'light'}>
                    {'Rearrange Progress Indicator'}
                    <ProgressBar className={'progress'} animated variant="warning" now={percent}
                                 label={`${percent.toFixed(0)}%` + (percent > 32 ? ` | ${steps}/${end}` : '')}/>
                </Alert>
            </div>
        </Collapse>
    </Container>);
}

export default AsyncLoader;