import { useEffect, useState } from "react";
import { getCenterdPolygon } from "../../../../controller/common";
import { useUserPolygonDisplayedState } from "../../../../Hooks/common";

export const useUserPolygonControls = () => {
    const [userPolygonDisplayed, toggleUserPolygonDisplayed] = useUserPolygonDisplayedState();
    const [userPolygonInEditMode, setUserPolygonInEditMode] = useState(false);
    const [userPolygonPath, setUserPolygonPath] = useState([]);

    useEffect(() => {
        if(userPolygonDisplayed)
            setUserPolygonInEditMode(true)
        else
            setUserPolygonInEditMode(false)
    }, [userPolygonDisplayed])

    // useEffect(() => {
    //       if(!userPolygonDisplayed){

    //         //   setUserPolygonPath([]);
    //       }else{
    //           setUserPolygonInEditMode(true);
    //       } 
    // },[userPolygonDisplayed])

    const toggleUserPolygonEditMode = () => {
        setUserPolygonInEditMode(inEditMode => !inEditMode);
    }

    const resetPolygonToCenter = () => {
        setUserPolygonPath(getCenterdPolygon(window.mainMap));
    }

    const clearPolygon = () => {
        setUserPolygonPath([]);
        toggleUserPolygonDisplayed();
    }

    return {
        userPolygonPath,
        setUserPolygonPath,
        userPolygonDisplayed,
        userPolygonInEditMode,
        toggleUserPolygonEditMode,
        resetPolygonToCenter,
        clearPolygon,
    }
}