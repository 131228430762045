import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

const ModalContent = styled.div`
   width: 40vw;
`;
function NoProjectsAvailableAlert({ onClose: closeDialog }) {

    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div style={{ display: "flex", justifyContent: "center", margin: "0" }} className="section-title">
                    <h4 style={{ margin: "0" }}>{"There are no projects available. please contenc administrator"}</h4>
                </div>
            </Modal.Header>
        </ModalContent>
    );
}

export default NoProjectsAvailableAlert;