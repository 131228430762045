import React, { useCallback, useEffect, useMemo, useState } from "react";
import ColorMapping from "./ColorMapping";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import SelectAreaButton from "./SelectAreaButton";
import styled from "styled-components";
import useProject, { useIsFeaturePermitted } from "../../Hooks/useProject";
import Constants, { featuresPermissions, mapControlsDisplayType, mapLayerTypes } from "../../controller/Constants";
import MapLayerControls from "./MapLayerControls";
import useAsyncLoading from "../../Hooks/useAsyncLoading";
import AsyncLoader from "./AsyncLoader";
import { useShowChartsByPolygonActions, useUser, useUserGroups } from "../../Hooks/common";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import PredictionModeSwitch from "./PredictionModeSwitch";
import SearchLocation from "./SearchLocation";
import { filterControlsConfig } from "../../configuations/mapControlsDisplay";
import ActionLayer from "./action_layer";
import FilterControls from "./FilterControls";
import AnalysisCharts from "./AnalysisCharts";
import { Button } from "react-bootstrap";
import WiderPrediction from "./WiderPrediction";
import { usePredictionState } from "../../Hooks/common";


const Div = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  ${(props) => (props.top ? "top: " + props.top + ";" : "")}
  ${(props) => (props.bottom ? "bottom: " + props.bottom + ";" : "")}
    ${(props) => (props.left ? "left: " + props.left + ";" : "")}
    ${(props) => (props.right ? "right: " + props.right + ";" : "")}
    transform:translateX(-50%);
  width: auto;
  z-index: 99;
  border-radius: 0;
  //background: rgba(250, 250, 250,1);
  padding: 0 20px;
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.map.control};
  &.zoom {
    padding: 0;
    text-align: center;
    width: 40px;
    span {
      font-size: 15px;
    }
  }
  h4 {
    margin-bottom: 4px;
  }
`;

const Spacer = styled.div`
 margin-top: ${(props) => (props.height ? props.height + "px" : "0")}; 
 margin-bottom: ${(props) => (props.height ? props.height + "px" : "0")}; 
 margin-left:${(props) => (props.width ? props.width + "px" : "0")};
 margin-right:${(props) => (props.width ? props.width + "px" : "0")};
`;

function MapControls({ requireLogin, projectFetched }) {

  const { zoom, mapLoaded } = useSelector(mapStateToProps);
  const user = useUser();
  const { HEAT_MAP, CELLULAR, IOT, SITE_PLANING } = mapLayerTypes;
  const isColorMapper = projectFetched && !requireLogin;
  const isSelectAreaButton = projectFetched && !requireLogin;
  const { loading, progress } = useAsyncLoading();
  const [isFilterControlsDisplayed, setIsFilterControlsDisplayed] = useState();
  const project = useProject();
  const userGroups = useUserGroups();
  const [predictionState] = usePredictionState();
  const isAdmin = userGroups.includes(Constants.userGroups.ADMIN);
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const getAllProviders = () => project.config.dataAccess.availableProviders.map(({ _id }) => _id) || [];
  const getFirstProvider = () => [project.config.dataAccess.availableProviders[0]._id];
  const filterControlsDisplayConfig = getFilterControlsDisplayConfig();
  const isFeaturePermitted = useIsFeaturePermitted();
  const permissions = useMemo(() => ({
    PREDICTION_IS_PERMITTED: isFeaturePermitted(featuresPermissions.PREDICTION),
  }), [project])

  const { ShowChartsByPolygon, setShowChartsByPolgon } = useShowChartsByPolygonActions()
  useEffect(() => {
    const isDisplayed = isAdmin || Object.values(filterControlsDisplayConfig || {}).some((item) => item);
    setIsFilterControlsDisplayed(isDisplayed);
  }, [userPreferences, project]);

  function getFilterControlsDisplayConfig() {
    return userPreferences.currentMapLayer === IOT || userPreferences.currentMapLayer === SITE_PLANING
      ? filterControlsConfig[project.mapControlsDisplayType || mapControlsDisplayType.STANDARD]
      : filterControlsConfig[userPreferences.currentMapLayer];
  }



  function handleBestProvider() {
    const newBestProvider = !userPreferences.bestProvider ?? false;
    let providers = userPreferences.providers;
    if (newBestProvider) {
      providers = getAllProviders();
    }
    else {
      providers = getFirstProvider();
    }
    setUserPreferences({ ...userPreferences, providers: providers, bestProvider: newBestProvider, });
  }

  function handlePrediction() {
    setUserPreferences({ ...userPreferences, signalsType: ["rsrp"] });
  }

  return (
    Boolean(user.isAuthenticated && mapLoaded) && (
      <>
        {user.isAuthenticated && project.name && (
          <Div top={"10px"} left={"50%"}>
            <h4 style={{ marginTop: 5 }}>{project.name}</h4>
          </Div>
        )}

        {isFilterControlsDisplayed && userPreferences && <FilterControls isAdmin={isAdmin} displayConfig={filterControlsDisplayConfig} />}
        {/* <MapLayerControls /> */}
        {ShowChartsByPolygon && <AnalysisCharts />}
        {Boolean(isColorMapper) && <ColorMapping />}
        {Boolean(isSelectAreaButton) && <SelectAreaButton />}
        <ActionLayer>
          <SearchLocation />
          {(userPreferences.currentMapLayer !== HEAT_MAP && userPreferences.currentMapLayer !== SITE_PLANING) &&
            <Button
              variant={userPreferences.bestProvider ? "primary" : "secondary"}
              onClick={handleBestProvider}>
              Best Provider
            </Button>}
          <Spacer width={10} />
          {userPreferences.currentMapLayer === CELLULAR &&
            <PredictionModeSwitch mapLayer={userPreferences.currentMapLayer} handlePrediction={handlePrediction} predictionPermission={(permissions.PREDICTION_IS_PERMITTED)} />}

          {(userPreferences.currentMapLayer === CELLULAR || userPreferences.currentMapLayer === SITE_PLANING) && <>
            <Spacer width={10} />
            {(predictionState) && <WiderPrediction />}
          </>}


        </ActionLayer>
        <AsyncLoader loading={loading} progress={progress} />
      </>
    )
  );
}


MapControls.propTypes = {
  requireLogin: PropTypes.bool.isRequired,
  projectFetched: PropTypes.bool.isRequired,
  // isCostsTable: PropTypes.bool.isRequired,
};

function mapStateToProps(reduxState) {
  return {
    zoom: reduxState.map.mapZoom,
    mapLoaded: reduxState.map.loaded,
  };
}

export default MapControls;
