
import { Col, Row, Container } from "react-bootstrap"
import { chartsTypes } from "./chartsDashboard";
import { TECHNOLOGYS } from '../../controller/Constants'
import { forwardRef } from "react";
import CoveragReliabilityTable from "./tables/CoveragReliabilityTable";

function CoverageReliability({ data, date, providers }, ref) {
    return (<>
    {
        data && <Container fluid style={{ textAlign: "center", width: "100%" }} >
            <Row  >
                <Col > <CoveragReliabilityTable providers={providers} data={data} date={date} ref={ref} name={`${chartsTypes.COVERAGE_RELIABILITY}-${TECHNOLOGYS._4G}-all`}/> </Col>
            </Row>
        </Container>
    }
    </>
    );
}
export default forwardRef(CoverageReliability);

