//optimize
const math = require('mathjs');

export function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = { "count": 0, "list": [] }
    }
    acc[key].list.push(obj)
    acc[key].count++
    return acc
  }, [])
}

function getRange(key) {
  switch (true) {
    case (key <= 0 && key >= -20):
      return 0;
    case (key < -20 && key >= -40):
      return 1;
    case (key < -40 && key >= -60):
      return 2;
    case (key < -60 && key >= -80):
      return 3;
    case (key < -80 && key >= -100):
      return 4;
    case (key < -100 && key >= -120):
      return 5;
    default:
      return -1
  }
}

export function groupByRange(objectArray) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[0]
    var range = getRange(key)
    if (!acc[range]) {
      acc[range] = { "count": 0, "list": [] }
    }
    acc[range].list.push(obj[1].list)
    acc[range].count += obj[1].count
    return acc
  }, [])
}

export function sumAllKeysValue(groupedBySignalRange) {
  var sum = 0;
  for (const [key, value] of Object.entries(groupedBySignalRange)) {
    console.log(key, value);//what with -1
    sum += value.count;
  }
  return sum;
}

function pdf(value, mean = 0, standardDeviation = 1) {
  const dividend =
    Math.E ** -((value - mean) ** 2 / (2 * standardDeviation ** 2));
  const divisor = standardDeviation * Math.sqrt(2 * Math.PI);
  return dividend / divisor;
}

function uniq(a) {
  return Array.from(new Set(a));
}

function createRange(start, end, step = 1) {
  const len = Math.floor((end - start) / step) + 1
  return Array(len).fill().map((_, idx) => start + (idx * step))
}


export function toDataStructureAsPrecent(list, data, provider, mean, std) {
  // var listOfRange = [-60, -65, -70, -75, -80, -85, -90, -95, -100, -105, -110, -115, -120, -125, -130]
  // const listOfRange =[...Array(10).keys()]
  const listOfRange = createRange(-60, -150, -1);
  for (const l of listOfRange) {
    console.log(l);
    const x = data.findIndex(object => { return object.name === l })
    if (x === -1) {
      data.push({
        name: l,
        cellcom: 0,
        pelephone: 0,
        partner: 0,
      });
    }
    if (provider === 'cellcom')
      data[data.findIndex(object => { return object.name === l })].cellcom = pdf(l, mean, std)
    if (provider === 'pelephone')
      data[data.findIndex(object => { return object.name === l })].pelephone = pdf(l, mean, std)
    if (provider === 'partner')
      data[data.findIndex(object => { return object.name === l })].partner = pdf(l, mean, std)
  }
  return data;
}


export function calcMean(listOfAllSignals) {
  if (listOfAllSignals.length == 0)
    return 0
  let mean = math.mean(listOfAllSignals)
  let std = math.std(listOfAllSignals);
  return { mean, std }
}

// export function calcStdandMean(groupedBySignalRange, sumOfAllKeysValue, data) {
//   const list =[]
//   for (const [key, value] of Object.entries(groupedBySignalRange)) {
//     console.log(key, value);
//     list.push()
//     if (key != "-1"){
//       // data[key].dBm = pdf((value.count/sumOfAllKeysValue)*100,70,20);
//        data[key].dBm = (value.count/sumOfAllKeysValue);
//     }

//   }
// }
// const x = (std) => {
//   var m =math.mean([1.1334655709832813,43.241711533012186, 51.77103995466138, 3.655426466421082,0,0])// need expected value
//   var s =math.std([1.1334655709832813,43.241711533012186, 51.77103995466138, 3.655426466421082,0,0])
//     return {m,s}
//   }