import { events, errors } from "../Constants";
import { application } from "../../App";
import { configureStore } from "../../store";

import EventEmitter from "../EventEmitter";
import {
  handleTokenExpirationEvent,
  isIdTokenExpired,
} from "../../Hooks/useAuthentication";
import { SET_FETCH_LOADER } from "../../store/actionTypes";

const store = configureStore();

export async function navigateResponse(res) {
  if (res.ok) {
    const data = await res.json();
    if (data && data.presignedUrl) {
      return await fetch(data.presignedUrl)
        .then(navigateResponse)
        .catch(handleError);
    }
    return data;
  }
  if (Number(res.status) === 401) {
    throw Error(errors.UNAUTHORIZED);
  }
  console.log("Error response: ", res);
  throw Error(
    "there were problem with the server response, status: " +
      res.status +
      "\nErrorMessage: " +
      (await res.json())
  );
}

export function userNotLoggedIn() {
  const eventName = events.REFRESH_USER_TOKEN;
  if (process.env.REACT_APP_STAGE === "dev") {
  }
  const isDispatched = EventEmitter.dispatch(eventName);
  console.log(eventName + (isDispatched ? "" : " not ") + " dispatched");

  throw Error(errors.TRY_AGAIN);
}

export function handleError(err) {
  if (err.message === errors.UNAUTHORIZED) {
    return userNotLoggedIn();
  }
  console.error(err);
  throw err;
}

export async function fetchAuth(url, options = { headers: {} }) {
  const storeState = application.store.getState();
  let idToken = storeState.user.idToken;

  try {
    if (isIdTokenExpired(idToken)) {
      idToken = await handleTokenExpirationEvent();
    }
  } catch (error) {
    console.error("Failed to refresh the token: ", error);
    throw error;
  }

  console.log(`fetchAuth to: ${url}`);
  let response;
  try {
    response = await fetch(url, {
      ...options,
      headers: { ...options.headers, Authorization: idToken },
    });
  } catch (error) {
    console.error("Failed to fetch: ", error);
    throw error;
  }

  console.log("response: ", response);
  const parsedResponse = await response.clone().json();
  console.log("parsedResponse: ", parsedResponse);
  if (parsedResponse.StatusCode === 202) {
    store.dispatch({ type: SET_FETCH_LOADER, payload: true });
    console.log("Polling for response");
    for (let i = 0; i < 6; i++) {
      await new Promise((resolve) => setTimeout(resolve, 30000));
      console.log("awaited 30 seconds");
      let pollingResponse;
      let pollingParsedResponse;
      try {
        if (isIdTokenExpired(idToken)) {
          idToken = await handleTokenExpirationEvent();
        }
        pollingResponse = await fetch(url, {
          ...options,
          headers: { ...options.headers, Authorization: idToken },
        });
        pollingParsedResponse = await pollingResponse.clone().json();
      } catch (error) {
        console.error("Failed to fetch during polling: ", error);
        throw error;
      }

      if (pollingParsedResponse.StatusCode !== 202) {
        if (pollingParsedResponse.StatusCode >= 400) {
          console.error(
            "Failed to fetch during polling, status: ",
            pollingParsedResponse.StatusCode
          );
          throw new Error(
            `Fetch failed with status: ${pollingParsedResponse.StatusCode}`
          );
        }
        return pollingResponse;
      }
    }
    console.warn(
      "Polling attempts exhausted, final status: ",
      parsedResponse.StatusCode
    );
  }
  return response;
}

export function getDateRangePatch(relativeDateRange) {
  const getYear = (date) => {
    return date.split("-")[0];
  };
  const getMonth = (date) => {
    return date.split("-")[1];
  };
  const dayInMS = 1000 * 60 * 60 * 24;
  let dateRange;

  switch (relativeDateRange.dateType) {
    case "month":
      dateRange = {
        from: new Date(Date.now() - 30 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0),
      };
      break;
    case "week":
      dateRange = {
        from: new Date(Date.now() - 7 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0),
      };
      break;
    case "day":
      dateRange = {
        from: new Date(Date.now() - 1 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0),
      };
      break;
    case "date":
      if (!relativeDateRange.date) {
        dateRange = {
          from: 0,
          to: 0,
        };
      } else {
        const selectedMonth = getMonth(relativeDateRange.date);
        const selectedYear = getYear(relativeDateRange.date);
        dateRange = {
          from: new Date(selectedYear, selectedMonth - 1, 1).setUTCHours(
            0,
            0,
            0,
            0
          ),
          to: new Date(selectedYear, selectedMonth, 1).setUTCHours(0, 0, 0, 0),
        };
      }
      break;

    default:
      dateRange = {
        from: String(new Date().setUTCHours(0, 0, 0, 0)),
        to: String(new Date().setUTCHours(0, 0, 0, 0)),
      };
      break;
  }
  return {
    from: Number(dateRange.from),
    to: Number(dateRange.to),
  };
}
