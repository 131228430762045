import React, { useEffect, useState, useRef, forwardRef } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LabelList,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { colorsForCoveragePerformance } from '../Constants';
import { barData1 } from "./data";
import { groupBy, groupByRange, sumAllKeysValue, toDataStructureAsPrecent } from "./helper";

//handle -minus 
//data ganery for power density and preformence bar chart

function BarRechartComponentTemp({ barData, name, width, height }) {
  // const inputRef = useRef(null);
  const dataStruct = [
    // {
    //   name: "-20 -40",
    //   dBm: 0,
    // },
    {
      name: " > -80",
      dBm: 0,
    },
    {
      name: "-80 -90",
      dBm: 0,
    },
    {
      name: "-90 -105",
      dBm: 0,
    },
    {
      name: "-105 -120",
      dBm: 0,
    },
    {
      name: "< -120",
      dBm: 0,
    },
  ];

  function extractData(barData) {
    if (barData !== undefined) {
      var groupedBySignal = groupBy(barData, 'signal')
      var groupedBySignalEntries = Object.entries(groupedBySignal)
      var groupedBySignalRange = groupByRange(groupedBySignalEntries)
      const sumOfAllKeysValue = sumAllKeysValue(groupedBySignalRange);
      return toDataStructureAsPrecent(groupedBySignalRange, sumOfAllKeysValue, dataStruct);
    }
  }

  return (
    <div id={name}>
    {/* <div ref={refa}> */}

      <ResponsiveContainer width={width} height={height}>
        <BarChart
          width={width}
          height={height}
          // width="100%"
          // height="100%"
          data={extractData(barData) || []}
          margin={{
            top: 5,
            right: 30,
            left: -5,
            bottom: 5
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" style={{fontSize: '10px'}}
          >
            <Label value="[dBm]" offset={0} position="insideBottomRight" fontSize={11} style={{fontWeight:"500"}}/>
            {/* <Label value="Pages of my website" offset={0} position="insideBottom" /> */}
          </XAxis>
          <YAxis type="number" domain={[0, 100]} style={{fontSize: '10px'}} >
            <Label value="[%]" offset={5} position="insideTopLeft" fontSize={13} style={{fontWeight:"500"}} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey="dBm" fill="#82ca9d" legendType="none" >
            <LabelList dataKey="dBm" position="top" />
            {
              dataStruct.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorsForCoveragePerformance[index % 6]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>

    </div>

  );
}
export default BarRechartComponentTemp;
