
import { Form } from "react-bootstrap";
import MultiSelectList from '../Common/MultiSelect/MultiSelectList'



const FeturesPremitionsManager = ({ selectedFeature, setSelectedFeature }) => {

    return (
        <Form.Group>
            <Form.Label
                htmlFor="Select_Features" column={false}>{"Select Features"}
            </Form.Label>
            <MultiSelectList optionsList={selectedFeature} inputType={'checkbox'}
                onSelect={setSelectedFeature}
            />
        </Form.Group>
    )
}


export default FeturesPremitionsManager;