import Constants from "../../controller/Constants";

function logoutUser() {
    localStorage.removeItem(Constants.localStorage.ID_TOKEN);
    localStorage.removeItem(Constants.localStorage.REFRESH_TOKEN);
    localStorage.removeItem(Constants.localStorage.ACCESS_TOKEN);

    return {user: {}, idToken: '', isAuthenticated: false}
}

function setUser(action) {
    const {idToken, refreshToken, accessToken} = action;
    if (typeof idToken !== "string" && typeof refreshToken !== "string") {
        throw Error('tokens arent valid: idToken:' + idToken + ', refreshToken: ' + refreshToken +'\n: action: '+JSON.stringify(action));
    }
    localStorage.setItem(Constants.localStorage.ID_TOKEN, idToken);
    localStorage.setItem(Constants.localStorage.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(Constants.localStorage.ACCESS_TOKEN, accessToken);
    return {user: action.user, idToken, isAuthenticated: true}
}

export {setUser, logoutUser}