import React, { useEffect, useState, useRef, useMemo } from "react";
import MapWrapperVnila from "./MapWrapperVnila";
import { useDispatch, useSelector } from "react-redux";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import {
  ecnoRange,
  featuresPermissions,
  mapLayerTypes,
  mapLayerValues,
  modals,
  rsrqRange,
  snrRange,
} from "../../controller/Constants";
import {
  useHeatMapPraimeryRatingState,
  useApplicationLayerState,
  useDebounce,
  useFetchLoader,
  usePlacementsActions,
  usePlacementsForChartsActions,
  usePredictionState,
  useSetTechnologiesCoverage,
  useUserPolygonDisplayedState,
} from "../../Hooks/common";
import SensorsMap from "./sensorsMap";
import useProject, {
  useIsFeaturePermitted,
  useProjectParams,
} from "../../Hooks/useProject";
import { useGetTechnologiesCoverage } from "../../Hooks/useGetTechnologiesCoverage";
import "./MapManegarIsFinal.css";
import {
  useIndoorFloorEnvState,
  useUserEnvironment,
} from "../../Hooks/useUserEnvironment";
import { useTimeOfDay } from "../../Hooks/useTimeOfDay";
import LocationMarker from "./LocationMarker";
import {
  useHandleFeachCoverage,
  useApllyFactors,
} from "./MapManegarIsFinalHooks";
import {
  EMPTY_PLACEMENT,
  EMPTY_SENSORS_PLACEMENT,
  getCurrentAreaOfIntrest,
  isUserPreferencesValid,
  transformBinsValues,
  getColor,
  applyDisplayedLabel,
  applyPraimerySignal,
  getSignalsRangesForHeatMap,
} from "./MapManegarIsFinalUtils";
import PolygonsOverlay from "./PolygonsOverlay";
import CoveragOverlay from "./CoveragOverlay";
import { providerFields } from "../../controller/Constants";
import { useAllProviders } from "../../Hooks/useAllProviders";
import CellularSites from "./CellularSites";
import uniqid from "uniqid";
import { SET_CURRENT_DIALOG } from "../../store/actionTypes";
import { useUserPolygonControls } from "./PolygonsOverlay/hooks/useUserPolygonControls";

const latLngToGeoJsonCoord = ({ lng, lat }) => [lng, lat];

function generateBounds(center, radius) {
  const KM_TO_LAT_LNG = 1 / (40075.017 / 360);
  const step = (KM_TO_LAT_LNG * (radius / 2)) / 1000;
  return [
    { lat: center.lat - step, lng: center.lng - step },
    { lat: center.lat + step, lng: center.lng - step },
    { lat: center.lat + step, lng: center.lng + step },
    { lat: center.lat - step, lng: center.lng + step },
  ];
}

const noo = (bins, binResolution) => {
  const geoJson = {
    type: "FeatureCollection",
    features: [],
  };

  for (const bin of bins) {
    geoJson.features.push(getGeoPolygon(bin, binResolution));
  }
  return geoJson;
};

const getGeoPolygon = (bin, binResolution) => {
  const feature = {
    type: "Feature",
    properties: {
      letter: "G",
      color: "blue",
      rank: "7",
      ascii: "71",
    },
    geometry: {
      type: "Polygon",
      coordinates: [[]],
    },
  };
  const featureCopy = JSON.parse(JSON.stringify(feature));
  featureCopy.geometry.coordinates[0].push(
    ...generateBounds(bin.location, binResolution).map((point) =>
      latLngToGeoJsonCoord(point)
    )
  );
  return featureCopy;
};

const cellularSitesList = [
  {
    location: {
      lat: 31.7555857819,
      lng: 35.2012200236,
    },
    id: uniqid(),
  },
  {
    location: {
      lat: 31.8044950819,
      lng: 35.2012200236,
    },
    id: uniqid(),
  },
  {
    location: {
      lat: 31.8362861269,
      lng: 35.2098523578,
    },
    id: uniqid(),
  },
];

function MapManegarIsFinal() {
  const { mapLoaded } = useSelector(mapStateToProps);
  const project = useProject();
  const [mapBounds, setMapBounds] = useState(null);
  const [mapZoom, setMapZoom] = useState(0);
  const [placement, setPlacement] = useState(EMPTY_PLACEMENT);
  // const [cellularSites, setCellularSites] = useState(cellularSitesList);
  const [cellularSites, setCellularSites] = useState([]);
  const [editAntennaSites, setEditAntennaSites] = useState([]);
  const [sensorsPlacement, setSensorsPlacement] = useState(
    EMPTY_SENSORS_PLACEMENT
  );
  const [displayedPlacement, setDisplayedPlacement] = useState(placement);
  const [userPolygonPath, setUserPolygonPath] = useState([]);
  const [binDitails, setBinDitails] = useState(null);
  const [predictionState, setPredictionState] = usePredictionState();
  const userPolygonRef = useRef(null);
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const debouncedMapZoom = useDebounce(mapZoom, 1500);
  const debouncedMapBounds = useDebounce(mapBounds, 1500);
  const debouncedUserPolygonPath = useDebounce(userPolygonPath, 1500);
  const getTechnologiesCoverage = useGetTechnologiesCoverage();
  const setTechnologiesCoverage = useSetTechnologiesCoverage();
  const infoWindowRef = useRef(null);
  const onfirstLoad = useRef(true);
  const [indoorFloorEnv, setIndoorFloorEnv] = useIndoorFloorEnvState();
  const [heatMapPraimeryRating, setHeatMapPraimeryRating] =
    useHeatMapPraimeryRatingState();
  const [applicationLayer, setApplicationLayer] = useApplicationLayerState();
  const userEnvironment = useUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const { setPlacementsForCharts } = usePlacementsForChartsActions();
  const TIME_OUT_TO_AVOID_RACE_CONDITION_THAT_FAILS_SOMETIMES = 500;
  const isHeatMapLayer =
    userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP;
  const isCellularLayer =
    userPreferences.currentMapLayer === mapLayerValues.CELLULAR;
  const prevAreaOfIntrest = useRef(null);
  const isIotLayer = userPreferences.currentMapLayer === mapLayerTypes.IOT;
  const isInSitePlanningLayer =
    userPreferences.currentMapLayer === mapLayerTypes.SITE_PLANING;
  const apllyFactors = useApllyFactors();
  const projectParams = useProjectParams();
  const { devParams } = projectParams;
  const { systemGain } = devParams;
  const user = useSelector((state) => state.user);
  const isBestServerLayer = userPreferences.bestProvider;
  const allProviders = useAllProviders();
  const dispatch = useDispatch();
  const coverageData = useMemo(
    () => ({
      bins: displayedPlacement.bins,
      binResolution: displayedPlacement.info.binResolution,
      algorithm: displayedPlacement.info.algorithm,
    }),
    [displayedPlacement]
  );
  const sensorsData = useMemo(() => {
    return {
      bins: sensorsPlacement.bins,
      binResolution: sensorsPlacement.resolution,
      algorithm: "static-clustering",
    };
  }, [sensorsPlacement]);
  const isAdmin = user.user.groups[0] === "admin";
  useHandleFeachCoverage({
    setPlacement,
    setCellularSites,
    cellularSites,
    setSensorsPlacement,
    editAntennaSites,
    userPreferences,
    predictionState,
    debouncedMapZoom,
    debouncedMapBounds,
    prevAreaOfIntrest,
    project,
    applicationLayer,
  });
  const projects = useSelector((state) => state.map.projects);
  const {
    userPolygonDisplayed,
    userPolygonInEditMode,
    toggleUserPolygonEditMode,
  } = useUserPolygonControls();

  const handleSetMapBounds = (bounds) => {
    if (project._id === "") return;
    setMapBounds(bounds);
  };

  const clearBinDitails = () => {
    setBinDitails(null);
  };

  useEffect(() => {
    const bins = displayedPlacement.bins.flatMap((bin) => bin.clusteredBins);
    const rating = getSignalsRangesForHeatMap(bins);
    setHeatMapPraimeryRating(rating);
  }, [displayedPlacement]);

  useEffect(() => {
    // effect to center map to project polygon
    if (!mapLoaded || project._id === "") return;
    setTimeout(() => {
      const mapBounds = new window.google.maps.LatLngBounds();
      project.polygon.forEach((point) => mapBounds.extend(point));
      window.mainMap.fitBounds(mapBounds, {
        bottom: 200,
        right: 200,
        left: 200,
        top: 100,
      });
    }, TIME_OUT_TO_AVOID_RACE_CONDITION_THAT_FAILS_SOMETIMES);
    if (!isAdmin && projects.length > 0 && onfirstLoad.current === true) {
      setTimeout(() => {
        //// insert here all polygons point
        const mapBounds = new window.google.maps.LatLngBounds();
        const convertArrayLocationToObject = (arrayOfPointArray) =>
          arrayOfPointArray.map((point) => ({ lat: point[1], lng: point[0] }));
        projects.forEach((project) =>
          convertArrayLocationToObject(project.polygon.coordinates[0]).forEach(
            (point) => mapBounds.extend(point)
          )
        );
        window.mainMap.fitBounds(mapBounds);
      }, TIME_OUT_TO_AVOID_RACE_CONDITION_THAT_FAILS_SOMETIMES);
      onfirstLoad.current = false;
    }
  }, [mapLoaded, project.polygon]);

  const handleYellow = (binWithColor) => {
    if (binWithColor.color === "#f6eb14") return "c1b931";
    return binWithColor.color.split("#")[1];
  };

  const getContent = (binDitails) => {
    debugger;
    let lis = "";
    if (isIotLayer) {
      lis = `<div class="sensorInfoWindow">`;
      const binWithColor = getBinWithColor(binDitails);
      lis += `<div class="sensorInfoLine">Signal: ${
        binDitails.signal != null
          ? `<span class="signal-color-${handleYellow(
              binWithColor
            )}">${binDitails.signal.toFixed(1)}</span >`
          : "no data"
      } </div><br>`;
      const hasMultyProviders = (binDitails.providers ?? []).length > 1;
      if ((binDitails.providers ?? []).length > 0) {
        lis += `<div class="sensorInfoLine">Providers:</div>`;
        lis += `<div class="sensorInfoBody">`;
        for (
          let index = 0;
          index < (binDitails.providers ?? []).length;
          index++
        ) {
          const provider = binDitails.providers[index];
          lis += `<div>${hasMultyProviders ? `${index + 1}.` : ""}${
            provider.id
          }&nbsp;:&nbsp;${
            provider.signal ? provider.signal.toFixed(1) : "No data"
          } </div>`;
        }
        lis += `</div><br>`; // end providers
      }
      lis += `<div class="sensorInfoLine">Sensors Info:</div>`;
      binDitails.bins.map((sensor) => {
        lis += `<div class="sensorInfoBody">`;
        if (sensor.id) lis += `<div>Id:&nbsp;${sensor.id}</div>`;
        Object.keys(sensor).map((field) => {
          if (
            sensor[field] !== null &&
            sensor[field] !== undefined &&
            field !== "id"
          ) {
            // this is like this beacuse i want the value false
            if (field === "height")
              lis += ` <div>Height:&nbsp;${sensor.height}</div>`;
            else if (field === "indoor")
              lis +=
                sensor[field] === true
                  ? `<div>Indoor:&nbsp;&#10003;</div>`
                  : "";
            else if (field === "type")
              lis += ` <div>Type:&nbsp;${sensor.type}</div>`;
            else lis += ` <div>${sensor[field]}</div>`;
          }
        });
        lis += `</div>`; // end Sensors Info
        lis += `<br>`;
      });

      lis += `</div>`; // end sensorInfoWindow
    } else if (isBestServerLayer) {
      lis = binDitails.bins
        .sort((a, b) => (a.signal < b.signal ? 1 : -1))
        .reduce((acc, bin) => {
          const binWithColor = getBinWithColor(bin);
          const signal =
            binWithColor.signal !== null
              ? `${Number(binWithColor.signal).toFixed(2)} dbm`
              : "No data";
          const provider = project.config.dataAccess.availableProviders.find(
            ({ _id }) => binWithColor.provider === _id
          );
          acc += `<div><div class="infoWindowLine"><div>${
            provider.name
          }</div> &nbsp; &nbsp;&nbsp;&nbsp;<div class="signal-color-${handleYellow(
            binWithColor
          )}">${signal}</div></div>`; //point.getProviders()
          return acc;
        }, `<div id="infoWindow">`);
    } else if (isHeatMapLayer || isCellularLayer || isInSitePlanningLayer) {
      let labelPostFix;
      if (
        userPreferences?.signalsType?.[0] === "rsrq" ||
        userPreferences?.signalsType?.[0] === "snr" ||
        userPreferences?.signalsType?.[0] === "ecno"
      )
        labelPostFix = "dB";
      else labelPostFix = isHeatMapLayer ? "" : "dBm";
      const binWithColor = getBinWithColor(binDitails);
      lis = `<div class="infoWindowLine"> &nbsp; &nbsp;&nbsp;&nbsp;<div class="signal-color-${handleYellow(
        binWithColor
      )}">${binDitails.label} ${labelPostFix}</div>`;
    } else {
      throw Error("mapLayer not sepported to display Bin ditails!");
    }
    return lis + `</div>`;
  };

  const clearPlacement = () => {
    setPlacement(EMPTY_PLACEMENT);
    setSensorsPlacement(EMPTY_SENSORS_PLACEMENT);
  };

  const clearDisplayedPlacement = () => {
    setDisplayedPlacement({ ...placement, bins: [] });
    setCellularSites([]);
    setSensorsPlacement({ ...sensorsPlacement, bins: [] });
  };

  const transformPlacementToDisplayPlacement = ({ bins, mapLayer }) => {
    let updatedBins = [];
    if (mapLayer === "iot") {
      updatedBins = apllyColor(bins);
    } else {
      updatedBins = apllyFactors(bins);
      updatedBins = applyPraimerySignal({ bins: updatedBins, mapLayer }); // transformSignalToDisplayedSignal ...  what is a beeter name?
      updatedBins = applyDisplayedLabel({ bins: updatedBins, mapLayer });
      updatedBins = apllyColor(updatedBins);
    }
    return updatedBins;
  };

  useEffect(() => {
    clearPlacement(); // clear original placements
  }, [project._id]);

  useEffect(() => {
    if (!infoWindowRef.current) return;
    if (isInSitePlanningLayer) return;
    if (userPolygonInEditMode) return;
    if (!binDitails) return infoWindowRef.current.close();
    infoWindowRef.current.setPosition(binDitails.center);
    infoWindowRef.current.setContent(getContent(binDitails));
    infoWindowRef.current.setOptions({ disableAutoPan: true });
    infoWindowRef.current.open(window.mainMap);
  }, [binDitails]);

  useEffect(() => {
    if (!mapLoaded) return;
    const infowindow = new window.google.maps.InfoWindow({
      hideCloseButton: true,
      content: `<div>1234456</div><div>1234456</div>`,
    });
    infoWindowRef.current = infowindow;
  }, [mapLoaded]);

  const onPolygonChange = (polygon) => {
    setUserPolygonPath(polygon);
  };

  useEffect(() => {
    // it is effectId=2. order dos MATTER! shuld be after effectId=1 !!!!
    const updatePrevAreaOfIntrest = async () => {
      const areaOfIntrest = await getCurrentAreaOfIntrest(
        debouncedMapBounds,
        project.polygon,
        1
      );
      prevAreaOfIntrest.current = areaOfIntrest;
    };

    updatePrevAreaOfIntrest();
  }, [debouncedMapBounds]);

  useEffect(() => {
    const newPolygon = {
      type: "Polygon",
      coordinates: debouncedUserPolygonPath,
    };
    setUserPreferences({ ...userPreferences, polygon: newPolygon });
  }, [debouncedUserPolygonPath]);

  useEffect(() => {
    console.log("placementDavid", placement);
  }, [placement]);

  useEffect(() => {
    if (infoWindowRef.current) infoWindowRef.current.close();
    if (placement.bins.length === 0) return clearDisplayedPlacement();
    const updatedBins = transformPlacementToDisplayPlacement({
      bins: placement.bins,
      mapLayer: userPreferences.currentMapLayer,
    });
    setDisplayedPlacement({ ...placement, bins: updatedBins });
    setPlacementsForCharts({ ...placement, bins: updatedBins });
    // setCellularSites(updatedBins.sort(() => 0.5 - Math.random()).slice(0, 5))
  }, [placement]);

  useEffect(() => {
    const technologiesCoverage = getTechnologiesCoverage(
      displayedPlacement.bins
    );
    setTechnologiesCoverage(technologiesCoverage);
  }, [project, displayedPlacement.bins, userPolygonPath]);

  const getRange = (userPreferences) => {
    if (userPreferences?.signalsType?.[0] === "rsrq") return rsrqRange;
    if (userPreferences?.signalsType?.[0] === "snr") return snrRange;
    if (userPreferences?.signalsType?.[0] === "ecno") return ecnoRange;
    const { config } = project;
    const applicationList = config?.dataAccess?.applications;
    const selectedApplication = applicationList?.find(
      ({ name }) => name === applicationLayer
    );
    const range =
      selectedApplication?.thresholds[userPreferences.technologies[0]]?.ranges;
    return range;
  };

  const getBinWithColor = (bin) => {
    const binList = apllyColor([bin]);
    return binList[0];
  };

  const apllyColor = (bins) => {
    const range = getRange(userPreferences);
    if (!range) {
      // When application doen't have range for technolagy
      setApplicationLayer("Voice call");
      return;
    }
    return bins.map((bin) => ({
      ...bin,
      color: getColor(
        bin.signal,
        userPreferences.currentMapLayer,
        userPreferences.technologies[0],
        userPreferences.signalsType[0],
        range
      ),
    }));
  };

  useEffect(() => {
    // useEffectId = 2
    if (placement.bins.length > 0) {
      const updatedBins = transformPlacementToDisplayPlacement({
        bins: placement.bins,
        mapLayer: userPreferences.currentMapLayer,
      });
      setDisplayedPlacement({ ...placement, bins: updatedBins });
      setPlacementsForCharts({ ...placement, bins: updatedBins });
    }
  }, [
    userEnvironment,
    indoorFloorEnv,
    timeOfDay,
    systemGain,
    applicationLayer,
  ]);
  const handleSetBinDitails = (event) => {
    const displayInfo = event.feature.getProperty("display");
    if (displayInfo) {
      setBinDitails(displayInfo);
    }
  };

  const listenersConfig = [
    { eventName: "mouseover", callback: handleSetBinDitails },
    { eventName: "mouseout", callback: clearBinDitails },
  ];

  return (
    <>
      <MapWrapperVnila
        setMapBounds={handleSetMapBounds}
        setMapZoom={setMapZoom}
        setBinDitails={setBinDitails}
      />
      {mapLoaded && mapBounds && (
        <PolygonsOverlay
          onPolygonChange={onPolygonChange}
          projectPolygon={project.polygon}
          projectId={project._id}
          projectName={project.name || "nameless project"}
          mapLoaded={mapLoaded}
          projects={user.user.groups[0] === "admin" ? [project] : projects}
          userPolygonDisplayed={userPolygonDisplayed}
          userPolygonInEditMode={userPolygonInEditMode}
          toggleUserPolygonEditMode={toggleUserPolygonEditMode}
        />
      )}
      {isIotLayer && (
        <SensorsMap
          data={sensorsData}
          map={window.mainMap}
          listenersConfig={listenersConfig}
        />
      )}
      {!isIotLayer && mapLoaded && (
        <CoveragOverlay
          data={coverageData}
          map={window.mainMap}
          listenersConfig={listenersConfig}
        />
      )}
      {isInSitePlanningLayer && mapLoaded && (
        <CellularSites
          data={cellularSites}
          map={window.mainMap}
          onAplly={setEditAntennaSites}
          projectName={project.name}
        />
      )}
      {!isIotLayer && <LocationMarker bins={displayedPlacement.bins} />}
      {/* <CellularSites2></CellularSites2> */}
      {/* <button onClick={() => setCellularSites([
                ...cellularSites,
                {
                    location: { lat: cellularSites[0].location.lat + 0.001, lng: cellularSites[0].location.lng + 0.001 },
                    id: uniqid()
                }
            ])}></button> */}
    </>
  );
}

function mapStateToProps(reduxState) {
  return { mapLoaded: reduxState.map.loaded };
}
export default MapManegarIsFinal;
