import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import {openDialog} from "../../store/actionCreators/general";

const ModalContent = styled.div`
  width: 20vw;
`;
const SubmitButton = styled(Button)`
  &.btn[type="submit"] {
    margin-top: 20px !important;
  }
`;
const P = styled.h3`
  font-weight: normal;
  color: #000;
  margin-bottom: 7px;
`;

function TryAgainGetCoverage() {
  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{"Alert" + " !"}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        <P>Preparing your data.</P>
        <P> please try again later</P>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(() => ({}), {openDialog})(TryAgainGetCoverage);
