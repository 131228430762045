import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import styled from "styled-components";
import Collapse from 'react-bootstrap/Collapse'
import useProject from "../../Hooks/useProject";
import {useDisplayedAntennasActions} from "../../Hooks/displayedSectors"; 
import {technologies} from "../../controller/models/enums"
import RadioList from '../Common/Inputs/RadioList';
import {DisplayedAntennasManager as DAM} from "../../controller/models/classes"



const StyledRow = styled.div`
background-color: #eef0f3;
padding: 4px 0 ;
`;

const StyledColumn = styled.div`
display:flex;
flex-direction: column;
`;
const ModalContent = styled.div`
        width:40vw;
        .radio-container{
            margin-left:15px;
    
            .radio-form-group.col {
                margin-top:0;
                margin-bottom:0;

                
                .radio-list-item.form-check{
                    margin-right:30px;
                    padding-top:0;
                    padding-bottom:0;
                    margin-bottom:8px;
                }
            }
        }
`;

const SubmitButton = styled(Button)`
    &.btn[type=submit]{
      margin-top: 20px !important;
    }
`;

function DisplayCoverage({ onClose: closeDialog, ...props}) {
    const [open, setOpen] = useState(false);
    const project = useProject();
    const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions(); 
    const technologiesPairs = listToPairs(technologies);

    const selectionScopes = ['pre design', 'post design', 'hybrid site mode'];
    const [PRE, POST, HYBRID] = selectionScopes;

    function listToPairs(list) {
        let pairsList = [];
        for (let i = 0; i < list.length; i += 2) {
            pairsList.push(list.slice(i, i + 2));
        }
        return pairsList;
    }

    function getfilteredSectors(site, filter) {
        let sectors = [];
        let filterSiteMode = filter.siteMode;
        if (filterSiteMode === "hybrid") {
            if (filter.defaultSiteMode === "preDesign") {
                filterSiteMode = site.preDesign.sectors.length > 0 ? "preDesign" : "postDesign";
            } else if (filter.defaultSiteMode === "postDesign") {
                filterSiteMode = site.postDesign.sectors.length > 0 ? "postDesign" : "preDesign";
            }
        }
        if (filterSiteMode === "preDesign") {
            sectors = site.preDesign.sectors
        }
        else if (filterSiteMode === "postDesign") {
            sectors = site.postDesign.sectors
        }

        const filteredSectors = sectors.filter(
            sector => filter.technologys.includes(sector.technology) || filter.technologys.length === 0);  
        return filteredSectors.map(sector => ({            
            siteId: site._id,                                
            sectorId: sector._id,                            
            antennaModel: displayedAntennas.find(antenna => antenna.sectorId === sector._id)?.antennaModel || sector.antennaModels[0]            
        }));
    }

    function handleDisplayCoverage(filter) {
        const filteredSectors = project.sites.flatMap(site => (getfilteredSectors(site, filter))); 
        const displayIds = filteredSectors.map(antenna => antenna.sectorId);
        const updatedAntennas = DAM.getUpdatedAntennas(displayedAntennas, filteredSectors, displayIds); 
        updateDisplayedAntennas(updatedAntennas); 
        closeDialog();
    }

    function onFormSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());
        let technologys = Object.keys(formDataObj).filter(key => key.startsWith("technology")).map(key => (key.replace("technology_", "")));
        const filter = {
            technologys: technologys,
            siteMode: formDataObj?.["siteMode"],
            defaultSiteMode: formDataObj?.["defaultsiteMode"]
        };
        handleDisplayCoverage(filter);
    }

    function handleCheckbox({target}) {
        const show = target.value === "hybrid";
        setOpen(show);
    }

    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{'Display Coverage'}</h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onFormSubmit}>
                    <h4>{'Design stage'}</h4>
                    <StyledRow>
                        {/*todo:noam, please use your generic component RadioList*/}
                        <Form.Row className={'radio-container'}>
                            <RadioList
                                className={'radio-scope-choice'}
                                values={selectionScopes}
                                currentValue={open}
                                onItemClick={setOpen}
                                renderLabels={val => (val[0].toUpperCase() + val.slice(1))}
                              //label={t('Choose_Scope')}
                            >
                            </RadioList>
                        </Form.Row>
                    </StyledRow>
                    <Collapse in={open === HYBRID}>
                        <div id="example-collapse-text">
                            <h4 className=" mt-4">{'Default Site Mode'}</h4>
                            <StyledRow>
                                <Form.Row className="w-50  d-flex justify-content-around">
                                    <Form.Check type="radio" id="customControlAutosizing22" name="defaultsiteMode"
                                                value="preDesign" label={"Pre design"} defaultChecked/>
                                    <Form.Check type="radio" id="customControlAutosizing3" name="defaultsiteMode"
                                                value="postDesign" label={"Post design"}/>
                                </Form.Row>
                            </StyledRow>
                        </div>
                    </Collapse>
                    <h4 className=" mt-4">{'technologies'}</h4>
                    <StyledRow>
                        <Form.Row className="w-100 d-flex justify-content-around">
                            {technologiesPairs.map(([firstEl, secondEl]) => {
                                return <StyledColumn>
                                    <Form.Check
                                        type="checkbox"
                                        id={firstEl}
                                        name={"technology_" + firstEl}
                                        value={true}
                                        label={firstEl}
                                        className="mb-2"
                                    />

                                    {secondEl && <Form.Check
                                        type="checkbox"
                                        id={secondEl}
                                        name={"technology_" + secondEl}
                                        value={true}
                                        label={secondEl}/>}
                                </StyledColumn>
                            })}
                        </Form.Row>
                    </StyledRow>
                    <Form.Group className="center">
                        <SubmitButton type={'submit'} variant={'primary'}
                                      className="width-100">{'Apply'}</SubmitButton>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </ModalContent>
    )
}

export default DisplayCoverage;