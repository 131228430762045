import { useRef, forwardRef, useImperativeHandle } from 'react';
import styled from "styled-components";

const StyledConfigFileManager = styled.div`
    display: none;
`;

function FileManager(props, ref) {
    const downloadButtonRef = useRef(null);
    const inputRef = useRef(null);
    const { fileName, getFileName, data, getData, onFileChange, accept } = props;
    
    useImperativeHandle(ref, () => ({
        uploadFile,
        downloadFile
    }));

    const uploadFile = () => {
        inputRef.current?.click();
    }

    const downloadFile = () => {
        if(getData) return handleDownloadFile({name: getFileName(), contents: getData()});
        downloadButtonRef.current?.click();
    }
 
    const handlOnChange = () => {
        inputRef.current?.files &&
            handleUploadFile(inputRef.current.files[0]);
    };

    const handleUploadFile = (file) => {
        const fileReader = new FileReader();

        fileReader.onload = (ev) => {
            onFileChange(ev.target.result);
        };

        if (file)
            fileReader.readAsText(file);
    }

    const handleDownloadFile  = ({name, contents}) => {
        const downloadLink = document.createElement('a');
        downloadLink.download = name;
        downloadLink.href = "data:text/json;charset=utf-8," + JSON.stringify(contents)
        downloadLink.click();
        downloadLink.remove();
    }

    return (
        <StyledConfigFileManager >
            <a ref={downloadButtonRef}
                href={"data:text/json;charset=utf-8," + JSON.stringify(data)}
                download={fileName}>
            </a>
            <input
                ref={inputRef}
                onClick={event => event.target.value = null}
                onChange={handlOnChange}
                type="file"
                className="d-none"
                accept={accept}
            />
        </StyledConfigFileManager>
    );
}

export default forwardRef(FileManager);