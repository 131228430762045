import Constants from "../Constants";
import { errors } from "../Constants";
import { fetchAuth, handleError, navigateResponse } from "./helper";

export const City = class {
  static async getCities() {
    try {
      const res = await fetchAuth(`${Constants.REST_URL}cities`,
        {
          method: "GET",
        }
      );
      return await navigateResponse(res);
    } catch (e) {
      // if (e.message === errors.NO_PROJECT_ID) return;
      handleError(e);
    }
  }

};