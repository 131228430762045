import React, { useCallback, useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";

import { Container } from "../StyledComponents/cardCollapseContainer";
import { useIndoorFloorEnvState } from "../../Hooks/useUserEnvironment";
import { useDebounce } from "../../Hooks/common";

import styled from "styled-components";
import { featuresPermissions } from "../../controller/Constants";
import { useIsFeaturePermitted } from "../../Hooks/useProject";

const StylessButton = styled.button`
  &&,
  &&:active {
    /* margin-right: auto; */
    white-space: nowrap;
    //background: none;
    color: ${({ active }) => (active ? "#f5cb42" : "inherit")};
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  &&:active {
    color: #f5cb42;
  }
  &&:disabled {
    cursor: inherit;
    &&:active {
      color: inherit;
    }
    .list-group-item {
      background-color: #e7eaed;
    }
  }
  flex-grow: 4;
`;

const FlexedDiv = styled.div`
  display: flex;
  gap: 4px;
  background-color: #ebe8e1;
  justify-content: center;
`;
const StyledNumberInput = styled.div`
padding: 0px 10px;

`;

const Input = styled.input.attrs({
  type: 'number',
})`
    background: #00aec9;
    padding: 0px 0px;

    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    text-transform: uppercase;
    width: 100%;
    border-radius: 5px;
    height: 35px;
    border-color: transparent;
    box-shadow: 0px;
    outline: none;
    transition: 0.15s;
    text-align: center;
    &:active {
      background-color: #f1ac15;
    }
  `

const Title = styled.div`
  white-space: nowrap;
  margin: 0px;
`;

const StyledIcon = styled.div`
  padding: 0px 10px;
  font-size: 20px;
`;

const StyledCard = styled(Card)``;

function CardCollapseEnv({ title, values, isIcon, defaultOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [indoorFloorEnv, setIndoorFloorEnv] = useIndoorFloorEnvState();
  const [localIndoorFloorEnv, setLocalIndoorFloorEnv] = useState(indoorFloorEnv);
  const [arrowUp, setArrowUp] = useState(isOpen);
  const debouncedLocalIndoorFloorEnv = useDebounce(localIndoorFloorEnv, 1000);
  const isFeaturePermitted = useIsFeaturePermitted();
  const indoorCoveragePermitted = isFeaturePermitted(featuresPermissions.INDOOR_COVERAGE);
  const inputColor = indoorCoveragePermitted ? "#f5cb42" : "#C0C0C0";

  useEffect(() => {
    const timeId = setTimeout(() => {
      setArrowUp(isOpen);
    }, 400);
    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen]);

  useEffect(() => {
    setIndoorFloorEnv(debouncedLocalIndoorFloorEnv)
  }, [debouncedLocalIndoorFloorEnv]);

  return (
    <Container>
      <StyledCard className={"map-layer-card smart-city"}>
        <Card.Header className={"card-header-sf"} onClick={() => setIsOpen(!isOpen)}>
          <div className={"title-layer"}>
            <Title>{title}</Title>
          </div>
          <i className={`fa fa-angle-${!arrowUp ? "down" : "up"}`} aria-hidden="true" />
        </Card.Header>
        <div>
          <Collapse in={isOpen} className={"card-body-collapse"}>
            <FlexedDiv className={"card-body-content"}>
              {values.map(({ value, label, active, onClick, iconLabel, disabled }) => (
                <StylessButton disabled={disabled} value={value} active={active} onClick={() => onClick(value)}>
                  <ListGroup.Item>
                    {isIcon ? (
                      <StyledIcon>
                        <i className={`fa fa-${iconLabel}`} aria-hidden="true"></i>
                      </StyledIcon>
                    ) : (
                      label
                    )}
                  </ListGroup.Item>
                </StylessButton>
              ))}
            </FlexedDiv>
          </Collapse>
          <Collapse in={isOpen && values.find(({ iconLabel }) => iconLabel === "home").active} className={"card-body-collapse"}>
            <FlexedDiv className={"card-body-content"}>

              <FlexedDiv  >
                <p style={{ margin: "4px 10px 0px 10px", color: "black" }}  >Floor</p>
                <input disabled={!indoorCoveragePermitted} value={localIndoorFloorEnv} onChange={(e) => setLocalIndoorFloorEnv(Number(e.target.value))} style={{ margin: "4px", padding: 0, color: inputColor, "text-align": "center" }} min={0} max={50} className="env-pactor-ploor-input" type="number" />
              </FlexedDiv>
            </FlexedDiv>
          </Collapse>
        </div>
      </StyledCard>
    </Container>
  );
}

export default CardCollapseEnv;
