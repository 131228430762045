import React, { useEffect, useState, useMemo } from "react";
import BSButton from "react-bootstrap/Button";
import styled from "styled-components";
import { dateRangeTypes, mapLayerTypes, providerFields, TECHNOLOGYS_LIST, SIGNALS_LIST, userEnvironments, featuresPermissions, TECHNOLOGIES_CALCULATION_RADIUS, TECHNOLOGYS_BANDS } from "../../controller/Constants";
import { useSetUserEnvironment, useUserEnvironment } from "../../Hooks/useUserEnvironment";
import CardCollapse from "./CardCollapse";
import CardCollapseEnv from "./CardCollapseEnv";
import { useTimeOfDay, useSetTimeOfDay } from "../../Hooks/useTimeOfDay";
import { timeOfDay as timeOfDayConstants } from "../../controller/Constants";
import { useUserPreferencesState, useUserPreferencesActions } from "../../Hooks/useUserPreferences";
import { useDebounce, usePredictionState } from "../../Hooks/common";
import { Container } from "../StyledComponents/cardCollapseContainer";
import { getFormatedStringDate, getInitialDateProjectCanAccessDataFrom, isUserPreferencesContentEquals } from "../../controller/common";
import CardCollapseWithDatePicker from "./CardCollapseWithDatePicker";
import useProject, { useIsFeaturePermitted } from "../../Hooks/useProject";
import { getProviderDisplayName } from "../Common/AllProvidersManger";

const Position = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 200;
`;
const StyledContent = styled.div`
  margin: 0 10px;
  z-index: 100;
`;

const ClearMapButton = styled(BSButton)`
  &&&,
  &&&:focus {
    font-family: "Lato", sans-serif;
    border: 0px #8db6f7 solid;
    background-color: #ffff;
    color: #413c4a;
    margin-top: 5px;
    &:hover {
      color: #ffff;
    }
  }
`;

function FilterControls({ isAdmin, displayConfig }) {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const userEnvironment = useUserEnvironment();
  const setUserEnvironment = useSetUserEnvironment();
  const timeOfDay = useTimeOfDay();
  const { clearUserPreferences } = useUserPreferencesActions();
  const setTimeOfDay = useSetTimeOfDay();
  const [localUserPreferences, setLocalUserPreferences] = useState(userPreferences);
  const debouncedUserPreferences = useDebounce(localUserPreferences, 1000);
  const { MEN, CAR, HOME } = userEnvironments;
  const { MORNING, AFTERNOON, EVENING } = timeOfDayConstants;
  const isBestServer = userPreferences.bestProvider;
  const project = useProject();
  const [predictionState] = usePredictionState();
  const isFeaturePermitted = useIsFeaturePermitted();
  const permissions = useMemo(() => ({
    SIGNALS_TYPES_IS_PERMITTED: isFeaturePermitted(featuresPermissions.SIGNALS_TYPES),
    BANDS_IS_PERMITTED: isFeaturePermitted(featuresPermissions.BANDS),

  }), [project])

  function getMinDate(project) {
    const showDataFrom = getInitialDateProjectCanAccessDataFrom(project);
    return new Date(showDataFrom.getFullYear(), showDataFrom.getMonth())
  }

  function getRelevantSignalsType() {
    const filteredList = localUserPreferences?.technologies.includes("3G") ? SIGNALS_LIST.filter((x) => x === "rsrp" || x === "ecno")
                                                            : SIGNALS_LIST.filter((x) => x === "rsrp" || x === "rsrq" || x === "snr");
    return filteredList
  }

  function getRelevantBands() {
    return TECHNOLOGYS_BANDS[localUserPreferences.technologies];
  }

  function getMaxDate() {
    const today = new Date()
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    return new Date(todayYear, todayMonth - 1, 31)// one month beack
  }

  const providerValues = {
    controlName: "providers",
    title: "Provider",
    isIcon: false,
    defaultOpen: true,
    values: project.config.dataAccess.availableProviders.map((provider) => ({
      value: provider[providerFields._ID],
      label: getProviderDisplayName(provider),
      active: localUserPreferences?.providers.includes(provider[providerFields._ID]), // only for strings values
      onClick: (val) => handlMultiSelectChange("providers", [val]),
      disabled: false,
    })),
  };

  const signalValues = {
    controlName: "signalsType",
    title: "Signal type",
    isIcon: false,
    defaultOpen: true,
    values: [...getRelevantSignalsType()].map((signal) => ({
      value: signal,
      label: localUserPreferences?.technologies.includes("3G") && signal === "rsrp" ? "RSCP" : signal.toUpperCase(),
      active: localUserPreferences?.signalsType?.includes(signal),
      onClick: (val) => handlChange("signalsType", [val]),
      disabled: predictionState,
    })),
  };

  const bandsValues = {
    controlName: "bands",
    title: "Bands",
    isIcon: false,
    defaultOpen: true,
    values: [...getRelevantBands()].map((band) => ({
      value: band,
      label: band,
      active: localUserPreferences?.bands?.includes(band),
      onClick: (val) => handlMultiSelectChange("bands", [val]),
      disabled: false,
    })),
  };

  const technologyValues = {
    controlName: "technologies",
    title: "Technology",
    isIcon: false,
    defaultOpen: true,
    values: [...TECHNOLOGYS_LIST].map((tech) => ({
      value: tech,
      label: tech,
      active: localUserPreferences?.technologies.includes(tech),
      onClick: (val) => handlChange("technologies", [val]),
      disabled: false,
    })),
  };

  const dateRangeValues = {
    controlName: "dateRange",
    title: "Date range",
    isIcon: false,
    defaultOpen: true,
    values: ["day", "week", "month"].map((date) => ({
      value: date,
      label: `last ${date}`,
      active: localUserPreferences?.relativeDateRange?.dateType === date,
      onClick: (val) => handlChangeDateButton(val),
      disabled: false,
    })),
  };

  const environmentValues = {
    controlName: "environmentFactor",
    title: "Environment",
    isIcon: true,
    values: [MEN, CAR, HOME].map((env) => ({
      value: env,
      label: env,
      iconLabel: env === MEN ? "male" : env === CAR ? "car" : "home",
      active: userEnvironment === env,
      onClick: (val) => setUserEnvironment(val),
      disabled: false,
    })),
  };

  const timeOfDayValues = {
    controlName: "dayTimeFactor",
    title: "Day time",
    isIcon: false,
    values: [MORNING, AFTERNOON, EVENING].map((time) => ({
      value: time,
      label: time,
      active: false,// timeOfDay === time,
      onClick: (val) => setTimeOfDay(val),
      disabled: true,
    })),
  };

  const values = [
    providerValues,
    technologyValues,
    // datesValues,
    environmentValues,
    timeOfDayValues,
  ];

  function resetBins() {
    clearUserPreferences();
  }

  async function handlChange(category, value) {
    const newUserPreferences = JSON.parse(JSON.stringify(localUserPreferences));
    newUserPreferences[category] = value;
    // if (category === "technologies") {
    //   newUserPreferences["bands"] = [...TECHNOLOGYS_BANDS[newUserPreferences.technologies]]
    // }
    setLocalUserPreferences(newUserPreferences);
  }

  async function handlMultiSelectChange(category, value) {
    const destructedValue = value[0];
    const newUserPreferences = JSON.parse(JSON.stringify(localUserPreferences));
    if (!('bands' in newUserPreferences)) newUserPreferences.bands = [];
    newUserPreferences[category] = newUserPreferences[category].includes(destructedValue) ?
      newUserPreferences[category].filter(item => item !== destructedValue) : [...newUserPreferences[category], destructedValue]; // using "includes only for strings"

    if (newUserPreferences.providers.length === project.config.dataAccess.availableProviders.length) newUserPreferences.bestProvider = true;
    else newUserPreferences.bestProvider = false;

    setLocalUserPreferences(newUserPreferences);
  }

  async function handlChangeDate(date) {
    if (!date) {
      handlChange("relativeDateRange", { dateType: "date", date: null });
      return;
    }

    const dateString = getFormatedStringDate({ originalDate: date, toFormat: "yyyy-mm" })
    handlChange("relativeDateRange", { dateType: "date", date: dateString })
  }

  async function handlChangeDateButton(val) {
    if (val === localUserPreferences?.relativeDateRange?.dateType) {
      handlChange("relativeDateRange", { dateType: "date", date: null });
      return;
    }

    handlChange("relativeDateRange", { dateType: val, date: null });
  }

  useEffect(() => {
    async function handleUpdateUserPreferences() {
      console.log({ debouncedUserPreferences })

      setUserPreferences(debouncedUserPreferences);
    }
    if (!isUserPreferencesContentEquals(debouncedUserPreferences, userPreferences)) { // this condition stop infinite loop
      handleUpdateUserPreferences();
    }
  }, [debouncedUserPreferences]);

  useEffect(() => {
    setLocalUserPreferences(userPreferences);
  }, [userPreferences]);
  return (
    <Position id="coverage-filters">
      <StyledContent>
        {displayConfig &&
          [providerValues,
            technologyValues]
            .filter(({ controlName }) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
        }
        {permissions.SIGNALS_TYPES_IS_PERMITTED && displayConfig?.signalsType &&
          [
            signalValues]
            .filter(({ controlName }) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
        }
        {permissions.BANDS_IS_PERMITTED && displayConfig?.bands &&
          [
            bandsValues]
            .filter(({ controlName }) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
        }
        {displayConfig &&
          <CardCollapseWithDatePicker
            title={dateRangeValues.title}
            values={dateRangeValues.values}
            isIcon={dateRangeValues.isIcon}
            defaultOpen={Boolean(dateRangeValues.defaultOpen)}
            onChange={handlChangeDate}
            minDate={getMinDate(project)}
            maxDate={getMaxDate(project)}
            active={
              localUserPreferences?.relativeDateRange?.dateType === dateRangeTypes.DATE &&
              localUserPreferences.relativeDateRange.date}
            monthString={localUserPreferences?.relativeDateRange?.date}
          />
        }

        {displayConfig && (userPreferences?.signalsType?.[0] !== "rsrq" && userPreferences?.signalsType?.[0] !== "snr" && userPreferences?.signalsType?.[0] !== "ecno") && [environmentValues].map((value) => (
          <CardCollapseEnv title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
        ))}
        {false && displayConfig &&
          [
            timeOfDayValues]
            .filter(({ controlName }) => isAdmin || displayConfig[controlName])
            .map((value) => (
              <CardCollapse title={value.title} values={value.values} isIcon={value.isIcon} defaultOpen={Boolean(value.defaultOpen)} />
            ))
        }

        <Container>{displayConfig && <ClearMapButton onClick={resetBins}>{"Clear Map"}</ClearMapButton>}</Container>
      </StyledContent>
    </Position>
  );
}

export default FilterControls;
