import { polygonSchemaTypes } from '../../controller/Constants';
const {LAT_LNG} = polygonSchemaTypes;
let modulejsts;
(async () => {
    const ans = await import('./jstsWrapper.mjs');
    modulejsts = ans;
})()

export const getIntersection = async ({ polygons, responesType = LAT_LNG }) => {
    const convertTo = {
        latLng: (polygon) => polygon.map(coord => ({ lat: coord.y, lng: coord.x })),
        mongo: (polygon) => polygon.map(coord => [coord.y, coord.x])
    }                    

    if(!responesType in Object.keys(convertTo)) throw Error(`'getIntersection' don't sepport responesType:${responesType}`);                           
    const jstsPolygon = await modulejsts.getIntersection({ polygons });
    return convertTo[responesType](jstsPolygon.getCoordinates());
}