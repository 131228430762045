import validate from "validate.js";
import { latLngToPoint, pointToLatLng } from "./mapper";
import Constants, { errors, dateRangeTypes, DAY_IN_MILLISECONDS } from "./Constants";

export const getCenterdPolygon = (map) => {
  const center = map.getCenter();
  const bounds = map.getBounds();
  const zoom = map.getZoom();
  return getInitialPolygonPath(center, bounds, zoom, map);
}

export function isLight(color, lightness = 180) {
  var red, green, blue, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    red = color[1];
    green = color[2];
    blue = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));
    // eslint-disable-next-line
    red = color >> 16;
    // eslint-disable-next-line
    green = (color >> 8) & 255;
    // eslint-disable-next-line
    blue = color & 255;
  }
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));
  // Using the HSP value, determine whether the color is light or dark
  return hsp > lightness;
}

export function generateDateRange(relativeDateRange) {
  const dateType = relativeDateRange.dateType;
  const dayInMS = 1000 * 60 * 60 * 24;
  let from;
  let to;
  let date;
  if (dateType === dateRangeTypes.DAY) {
    from = new Date(Date.now() - 1 * dayInMS).setUTCHours(0, 0, 0, 0);
    to = new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0);
  } else if (dateType === dateRangeTypes.WEEK) {
    from = new Date(Date.now() - 7 * dayInMS).setUTCHours(0, 0, 0, 0);
    to = new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0);
  } else if (dateType === dateRangeTypes.MONTH) {
    from = new Date(Date.now() - 30 * dayInMS).setUTCHours(0, 0, 0, 0);
    to = new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0);
  } else if (dateType === dateRangeTypes.DATE) {
    if (!relativeDateRange.date) {
      from = 0;
      to = 0;
      date = relativeDateRange.date;
    } else {
      if (!dateIsValid(relativeDateRange.date)) throw Error("relativeDateRange of dateType date is not valid");
      const { year, month } = getYearAndMonthFromDateString(relativeDateRange.date);
      from = new Date(Date.UTC(year, month - 1)); // month start from 0
      to = new Date(Date.UTC(year, month));
      date = relativeDateRange.date;
    }
  }
  return { dateType: dateType, from: new Date(from), to: new Date(to), date: relativeDateRange.date }; ////
}

export const toUtcDate = ({ date, addition }) => {
  const { year, month } = getYearAndMonthFromDateString(date)
  return new Date(Date.UTC(year, month - addition))

}

export const splitArryToChunks = ({ array, chunkSize = 1 }) => {
  const res = []
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res
}

export function capitalizeFirstLetter(string) {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getMonthDiff(date1, date2) {
  if (!(date1 instanceof Date && date2 instanceof Date)) throw Error(`invalid arguments: ${{ date1, date2 }}`);
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

const getThisDayLastYear = () => {
  const now = new Date();
  return new Date(now.setFullYear(now.getFullYear() - 1));
}

export function getInitialDateProjectCanAccessDataFrom(project) {
  if (!project) throw Error(`invalid argument: ${{ project }}`);
  const startFrom = project.config?.dataAccess?.startFrom;
  return (startFrom && new Date(startFrom)) || getThisDayLastYear();
}

export function handlePatchToSepportSygnalType(userPreferences) {
  const mapLayer = userPreferences.currentMapLayer
  if(mapLayer === "cellular") return ((userPreferences.signalsType) ? userPreferences.signalsType?.[0] : "rsrp");
  return mapLayer === "heatmap" ? "rssi" : "rsrp"
} 
export function setDateToFirstDayOfTheMonth(date) {
  return date.setDate(1)
}

function getYearAndMonthFromDateString(date) {
  if (!date) throw Error(`invalid arguments: ${{ date }}`);
  const year = date.split("-")[0];
  const month = date.split("-")[1];
  return { year, month };
}

function dateIsValid(dateStr) {
  const regex = /^\d{4}-\d{1,2}$/;
  if (dateStr.match(regex) === null) {
    return false;
  }
  return true;
}

export function getFormatedStringDate({ originalDate, toFormat }) {
  if (!(originalDate && toFormat)) throw Error(`invalid arguments: ${{ originalDate, toFormat }}`);
  let date;
  try {
    date = new Date(originalDate);
  } catch (err) {
    console.log(`invalid Date argument, tryed to parse: ${originalDate}`);
    console.log(err);
    throw Error(err);
  }

  if (toFormat === "yyyy-mm") {
    return `${date.getUTCFullYear()}-${date.getMonth() + 1}`;
  } else {
    throw Error(`format ${toFormat} is not supported`);
  }
}

export function ifFuncExec(obj) {
  if (typeof obj === "function") {
    return obj();
  }
  return obj;
}

export function ifShow(booleanValue) {
  return Boolean(booleanValue || booleanValue === 0);
}

export function typeCheck(obj, type, enums) {
  const enumCheck = () => (enums ? enums.includes(obj) : true);
  if (!enumCheck()) return false;
  if (obj === null) return true;
  switch (type) {
    case String:
      return validate.isString(obj);
    case Number:
      return validate.isNumber(obj);
    default:
      throw Error("unsporrted type: " + type);
  }
}


export function deprecationError() {
  throw Error(errors.FUNCTION_DEPRECATED);
}

export function debounceCallback(fn, time) {
  let timeout;
  return function () {
    const args = arguments;
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
}

export function zip(a, b) {
  return Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]]);
}

export function deepCompare() {
  var i, l, leftChain, rightChain;

  function compare2Objects(x, y) {
    let p;
    if (isNaN(x) && isNaN(y) && typeof x === "number" && typeof y === "number") return true;
    if (x === y) return true;
    if (
      (typeof x === "function" && typeof y === "function") ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString();
    }
    if (!(x instanceof Object && y instanceof Object)) return false;
    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) return false;
    if (x.constructor !== y.constructor) return false;
    if (x.prototype !== y.prototype) return false;
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) return false;
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) return false;
      else if (typeof y[p] !== typeof x[p]) return false;
    }
    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) return false;
      else if (typeof y[p] !== typeof x[p]) return false;

      switch (typeof x[p]) {
        case "object":
        case "function":
          leftChain.push(x);
          rightChain.push(y);
          if (!compare2Objects(x[p], y[p])) return false;
          leftChain.pop();
          rightChain.pop();
          break;
        default:
          if (x[p] !== y[p]) return false;
          break;
      }
    }
    return true;
  }

  if (arguments.length < 1) throw Error("missing functin arguments, al least 2 should be supplied");
  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []; //Todo: this can be cached
    rightChain = [];
    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }
  return true;
}

export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export function getInitialPolygonPath(center, bounds, zoom, map, sizeProp) {
  const point = latLngToPoint(center, bounds, zoom, map);
  const size = sizeProp ? sizeProp : 150;
  const topRight = { x: point.x + size, y: point.y - size };
  const topLeft = { x: point.x - size, y: point.y - size };
  const bottomRight = { x: point.x + size, y: point.y + size };
  const bottomLeft = { x: point.x - size, y: point.y + size };
  return [topRight, topLeft, bottomLeft, bottomRight].map((point) => {
    const latLng = pointToLatLng(point, bounds, zoom, map);
    const { lat, lng } = latLng;
    return { lat: lat(), lng: lng() };
  });
}

export function getDefaultPolygonAccordingToMapCenter(map, sizeProp) {
  const center = map.getCenter();
  const bounds = map.getBounds();
  const zoom = map.getZoom();
  const point = latLngToPoint(center, bounds, zoom, map);
  const size = sizeProp ? sizeProp : 150;
  const topRight = { x: point.x + size, y: point.y - size };
  const topLeft = { x: point.x - size, y: point.y - size };
  const bottomRight = { x: point.x + size, y: point.y + size };
  const bottomLeft = { x: point.x - size, y: point.y + size };
  return [topRight, topLeft, bottomLeft, bottomRight].map((point) => {
    const latLng = pointToLatLng(point, bounds, zoom, map);
    const { lat, lng } = latLng;
    return { lat: lat(), lng: lng() };
  });
}

const toFloats = ({ lat, lng }) => ({ lat: ifFuncExec(lat), lng: ifFuncExec(lng) });

export function compare2Paths(path, pathArray) {
  //function locationEquals(loc1, loc2) we can use it ?
  const compareLocations = (location, location2) => location.lat === location2.lat && location.lng === location2.lng;
  return path.length === pathArray.length && path.every((location, i) => compareLocations(toFloats(location), toFloats(pathArray[i])));
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getTokenIdParameter(tokenKey) {
  const idTokenStored = localStorage.getItem(Constants.localStorage.ID_TOKEN);
  //refactor: if localStorage in not undefined take the idToken from user.idToken (redux)
  if (!idTokenStored) throw "idToken doesn't exist in local storge";
  const tokenDecoded = decodeJwtToken(idTokenStored);
  const tokenParam = tokenDecoded[tokenKey];
  return tokenParam;
}
export function decodeJwtToken(token) {
  return token.startsWith("Bearer ") ? parseJwt(token.substring(7)) : parseJwt(token);
}

export function locationEquals(loc1, loc2) {
  return loc1.lat === loc2.lat && loc1.lng === loc2.lng;
}

export function isDatesEquals(loc1, loc2) {
  if (loc1.dateType !== loc2.dateType) {
    return false;
  }

  if (loc1.dateType === "date") {
    return loc1.date === loc2.date
  }

  return true;
}

export function isExponentialFormat(num) {
  return String(num).includes("e")
}

export function getDivisionAndkeepExponentialNotation({ exponential, dividedBy, digitsAfterDecPoint = 2 }) {
  const num = Number(exponential) / dividedBy;
  const exponentialAns = num.toExponential(digitsAfterDecPoint);
  return exponentialAns;
}

export function isUserPreferencesContentEquals(UP1, UP2) {
  return (
    UP1.currentMapLayer === UP2.currentMapLayer &&
    isDatesEquals(UP1.relativeDateRange, UP2.relativeDateRange) &&
    UP1.providers.isArrayOfStringsOrNumbersHaveSameValues(UP2.providers) &&
    UP1.technologies.isArrayOfStringsOrNumbersHaveSameValues(UP2.technologies) &&
    UP1.signalsType.isArrayOfStringsOrNumbersHaveSameValues(UP2.signalsType) &&
    'bands' in(UP1) === 'bands' in(UP2) && 'bands' in(UP1) && 'bands' in(UP2) &&
    UP1.bands.isArrayOfStringsOrNumbersHaveSameValues(UP2.bands)

  );
}

const getLastMonth = () => new Date(new Date().getFullYear(), new Date().getMonth() - 1);

export const resetRelativeDateRange = () => {
  const lastMonth = getLastMonth();
  const dateString = getFormatedStringDate({ originalDate: lastMonth, toFormat: "yyyy-mm" });
  return { dateType: "date", date: dateString };
}

export const getLast30DaysDateRange = () => {
  const dayInMS = 1000 * 60 * 60 * 24;
  const dateRange = {
    from: new Date(Date.now() - 30 * dayInMS).setUTCHours(0, 0, 0, 0),
    to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0)
  }
  return dateRange;
}

export const getTimesteamp = (date) => { }

export const initialFetures = (feturesArray) => {
  const returnArray = feturesArray.map((f) => ({ label: f.label, value: f.name, checked: f.isPermitted }))
  return returnArray
}

export const convertFeturesToSend = (selectedFeturesArray) => {
  return selectedFeturesArray.map((feature) => ({ name: feature.value, isPermitted: feature.checked }))
}