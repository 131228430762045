import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";


const WiderPrediction = () => {
    const [userPreferences, setUserPreferences] = useUserPreferencesState();
    const [widerState, setWiderState] = useState(false);


    useEffect(() => {
        setUserPreferences({ ...userPreferences, widerPrediction: widerState })
    }, [widerState])


    const onClickButton = () => {
        setWiderState((preve) => !preve)
    }


    return <Button
        title="Broad Prediction"
        onClick={onClickButton}
        variant={widerState ? "primary" : "secondary"}>
        <i className="fa-solid fa-arrows-left-right-to-line"></i>
    </Button>



}

export default WiderPrediction;
