import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import GeoJsonDataDrawer from "./GeoJsonDataDrawer";
import {
  toggleProperty,
  reDrawStyle,
  clearSites,
  removeSitesThatArntInTheNewData,
  getNewPoint,
  handleNewData,
  getOnlyDuplocatesSites,
  getOnlyDisabledSites,
  getSitesThatWasOnlyMoved,
  getSitesAsGeoJson,
  getStyle,
  getAllAncestors
} from "./utils"
import { useUserPreferencesState } from "../../../Hooks/useUserPreferences";
import { Button as bsButton } from "react-bootstrap";
import uniqid from "uniqid";
import { usePrevious } from "../../../Hooks/common";
import FileManager from "../../Common/FileManager";
import { useActiveSite } from "./hooks";

const Button = styled(bsButton)`
margin: 0px 5px ;
`;
const Div = styled.div`
position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
top: 50px;
text-align: center;
background-color: #595763;
padding: 5px;
width: fit-content;
`;

const IN_EDIT_MODE = "inEditMode";
const DISABLED = "disabled";
const LOCATION = "location";

function CellularSites({ data, map, onAplly, projectName }) {
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const cellularSitesLayer = useMemo(() => new window.google.maps.Data(), [])
  const { setActiveSite, activeSite, clearActiveSite, toggledDisableOfActiveSite } = useActiveSite(cellularSitesLayer, userPreferences);
  const previousCurrentActiveSiteId = usePrevious(activeSite);
  const sitesFileManagerRef = useRef(null);
  const thereIsAnActiveSite = Boolean(activeSite);

  const handleClickOutSideOfSiteIcon = (event) => {
    const mapElement = event.target.querySelector("#map-container");
    const { ancestors, idList } = getAllAncestors(event)
    const mapContainerInAncestors = idList.includes("map-container");
    if (!mapContainerInAncestors) return;
    const sitePlenningEditorInAncestors = idList.includes("site-plenning-editor");
    if (mapElement || mapContainerInAncestors || sitePlenningEditorInAncestors) return;
    handleAplly();
  }

  useEffect(() => {
    const cleanUp = () => onAplly([]);
    return cleanUp;
  }, [])

  useEffect(() => {
    if (data.length === 0) return clearSites(cellularSitesLayer);
    const currentSitesIds = {};
    cellularSitesLayer.forEach((feature) => {
      currentSitesIds[feature.getId()] = true;
    });
    const currentActiveSiteInNewData = previousCurrentActiveSiteId && previousCurrentActiveSiteId.id in currentSitesIds;
    if (!currentActiveSiteInNewData) setActiveSite(null);
    removeSitesThatArntInTheNewData(cellularSitesLayer, data);
    const newSitesList = handleNewData(cellularSitesLayer, data);

    for (const site of newSitesList) {
      cellularSitesLayer.add(site);
    }
  }, [data])

  useEffect(() => { // TODO: need to refactor this useEffect !
    document.addEventListener("click", handleClickOutSideOfSiteIcon, true);

    window.google.maps.event.addListener(map, "click", function (event) {
      if (!activeSite) return;
      handleAplly();
    });

    cellularSitesLayer.addListener('mousedown', function (event) {
      const newActiveSite = event.feature;
      if (!newActiveSite) return;
      if (newActiveSite.getId() === activeSite?.id) return;
      const currentAntennaId = {
        id: newActiveSite.getId().split("-copy-")[0],
        location: { type: "Point", coordinates: [newActiveSite.getGeometry().get().lng(), newActiveSite.getGeometry().get().lat()] }
      }
      setUserPreferences({ ...userPreferences, currentAntennaId });
      toggleProperty(newActiveSite, IN_EDIT_MODE);
      const currentActiveSite = cellularSitesLayer.getFeatureById(activeSite?.id);
      if (currentActiveSite) toggleProperty(currentActiveSite, IN_EDIT_MODE);
      const site = {
        id: newActiveSite.getId(),
        disabled: newActiveSite.getProperty(DISABLED),
        prevLocation: newActiveSite.getProperty(LOCATION)
      }
      setActiveSite(site);
      reDrawStyle(cellularSitesLayer, getStyle);
    });

    cellularSitesLayer.addListener('mouseup', function (event) {
      if (!userPreferences.currentAntennaId) return;
      const newActiveSite = event.feature;
      const currentAntennaId = {
        id: userPreferences.currentAntennaId.id,
        location: { type: "Point", coordinates: [newActiveSite.getGeometry().get().lng(), newActiveSite.getGeometry().get().lat()] }
      }
      setUserPreferences({ ...userPreferences, currentAntennaId });
    })

    return () => {
      window.google.maps.event.clearListeners(cellularSitesLayer, 'mouseup');
      window.google.maps.event.clearListeners(cellularSitesLayer, 'mousedown');
      window.google.maps.event.clearListeners(map, 'click');
      document.removeEventListener("click", handleClickOutSideOfSiteIcon);
    }

  }, [activeSite, userPreferences])

  const handleReset = () => {
    cellularSitesLayer.forEach((site) => {
      site.setProperty(DISABLED, false);
      const location = site.getProperty(LOCATION);
      site.setGeometry(new window.google.maps.Data.Point({ lat: location.lat, lng: location.lng }));
    });
    handleAplly();
  }

  const handleAplly = () => {
    const onlyDisables = getOnlyDisabledSites(cellularSitesLayer);
    const onlyMoved = getSitesThatWasOnlyMoved(cellularSitesLayer);
    const onlyDuplocates = getOnlyDuplocatesSites(cellularSitesLayer);
    console.log({ onlyDuplocates, onlyDisables, onlyMoved })
    setUserPreferences({ ...userPreferences, currentAntennaId: null });

    onAplly([...onlyDuplocates, ...onlyDisables, ...onlyMoved].sort(({ action }) => action === "add" ? 1 : -1));
    clearActiveSite();
  }

  const handleDuplocateSite = () => {
    if (!activeSite) return;
    const STEP = 0.002;
    const site = cellularSitesLayer.getFeatureById(activeSite.id);
    const location = { lng: site.getGeometry().get().lng() + STEP, lat: site.getGeometry().get().lat() + STEP };
    const id = `${activeSite.id}-copy-${uniqid()}`
    cellularSitesLayer.add(getNewPoint({ location, id }));
    // need to think about apdting the server of the duplication
  }

  function handleUplodeSites(sites) {
    const parsedSites = JSON.parse(sites);

    parsedSites.features.forEach(site => {
      site.url = '/assets/tower-cell.png';
      site.inEditMode = false;
    });

    clearSites(cellularSitesLayer);
    clearActiveSite();
    cellularSitesLayer.addGeoJson(parsedSites);
    handleAplly();
  }

  return <>
    <GeoJsonDataDrawer dataLayer={cellularSitesLayer} map={map} getStyle={getStyle} />;
    {thereIsAnActiveSite &&
      <Div id="site-plenning-editor">
        <h3 style={{ color: "white", marginBottom: "5px" }}>Sites Edit Mode</h3>
        <div style={{ marginBottom: "0px", display: "flex", justifyContent: "space-between", margin: "5px 25px 10px" }}>
          <Button onClick={() => { sitesFileManagerRef.current.downloadFile() }} disabled={!thereIsAnActiveSite} variant={"secondary"}>
            <i class="fa-solid fa-download"></i>
          </Button>
          <Button onClick={() => { sitesFileManagerRef.current.uploadFile() }} disabled={!thereIsAnActiveSite} variant={"secondary"}>
            <i class="fa-solid fa-upload"></i>
          </Button>
          <Button style={{ color: "red" }} onClick={handleReset} disabled={!thereIsAnActiveSite} variant={"secondary"}>
            <i class="fa-solid fa-x"></i>
          </Button>
          <Button
            style={{ whiteSpace: "nowrap" }}
            onClick={toggledDisableOfActiveSite}
            disabled={!thereIsAnActiveSite}
            variant={"secondary"}>{!thereIsAnActiveSite || !activeSite.disabled ? "Disable Site" : "Enable Site"}
          </Button>
          <Button style={{ whiteSpace: "nowrap" }} onClick={handleDuplocateSite} disabled={!thereIsAnActiveSite} variant={"secondary"}>Duplicate Site</Button>
          <Button style={{ color: "green" }} onClick={handleAplly} disabled={!thereIsAnActiveSite} variant={"secondary"}>
            <i class="fa-solid fa-check"></i>
          </Button>
        </div>
        <FileManager
          ref={sitesFileManagerRef}
          getFileName={() => `Sites planning - ${projectName} - ${new Date()}.json`}
          getData={() => getSitesAsGeoJson(cellularSitesLayer)}
          onFileChange={handleUplodeSites}
          accept=".json" />
      </Div>
    }
  </>
}

export default CellularSites;