import React, { forwardRef } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LabelList,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { extractData } from './utils';

function BarsChart({ width, height, xaxisKey, yaxisKey, domain, yaxisLable, xaxisLable, colors, data, name }, ref) {
  let dataStruct = [];
  return (
    <div id={name} ref={(element) => ref.current.add(element)} style={{ padding: 5 }}>
      <ResponsiveContainer width={width} height={height} style={{ background: "gray", padding: 5 }}>
        <BarChart
          data={extractData(data, dataStruct) || []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <XAxis dataKey={xaxisKey} style={{
            fontSize: '0.6rem'
          }}>
            <Label value={xaxisLable} offset={3} position="right" fontSize={9} />

            <Label value={"signal level"} offset={-2} position="insideBottom" fontSize={13} fill={'#d1cc48'} />

          </XAxis>
          <YAxis
            type="number"
            domain={domain}
          >
            <Label value="%" offset={-25} position="left" fontSize={14} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey={yaxisKey} legendType="none" >
            <LabelList dataKey={yaxisLable} position="top" />
            {
              [...colors.keys()].map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % ((colors.length) + 1)]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>

    </div>

  );
}
export default forwardRef(BarsChart);

