import React, { useCallback, useEffect, useState, forwardRef } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import { Container } from "../StyledComponents/cardCollapseContainer";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './CardCollapseWithDatePicker.css'


const CloseButton = styled.button`
background-color: transparent;
transition: 0.18s;
border: none;
font-size: 20px;
color: gray;
margin-top: 3px;
display: ${({ disabled }) => disabled ? "none" : "default"};
`;

const StylessButton = styled.button`
  &&,
  &&:active {
    /* margin-right: auto; */
    //width: 33%;
    white-space: nowrap;
    //background: none;
    color: ${({ active }) => (active ? "#f5cb42" : "inherit")};
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  &&:active {
    color: #f5cb42;
  }
  &&:disabled {
    cursor: inherit;
    &&:active {
      color: inherit;
    }
    .list-group-item {
      background-color: #e7eaed;
    }
  }
  flex-grow: 4;
`;

const FlexedDiv = styled.div`
  display: flex;
  gap: 0px 4px;
  flex-wrap: wrap;
  background-color: #ebe8e1;
  justify-content: center;
`;

const StyledDatePicker = styled.div`
margin-top: 5px;
  display: flex;
  background-color: #ebe8e1;
  justify-content: center;
  white-space: nowrap;
  align-items: baseline;
  margin-left: 5px;
  .react-datepicker__input-container {
    display: flex;
     justify-content: center; 
     input[type=text] {
      padding: 5px;
      width: 90%;
       color: ${({ active }) => (active ? "#f5cb42" : "inherit")};
       margin-bottom: 0px;
     }
     &&&.date-label p,p{
       color: ${({ active }) => (active ? "#ffff" : "inherit")};
       margin-left: 5px;
     }
  }
`;

const Title = styled.div`
  white-space: nowrap;
  margin: 0px;
`;

const StyledIcon = styled.div`
  padding: 0px 10px;
  font-size: 20px;
`;

const StyledCard = styled(Card)``;

function CardCollapseWithDatePicker({ title, values, isIcon, defaultOpen = false, onChange, minDate, maxDate, active, monthString }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [arrowUp, setArrowUp] = useState(isOpen);
  const getLastMonth = () =>  new Date( new Date().getFullYear(), new Date().getMonth() -1);
  const [date, setDate] = useState(new Date(monthString || getLastMonth()));
  // const [date, setDate] = useState("");
  
  useEffect(() => {
    monthString && setDate(new Date(monthString))
  }, [monthString]);

  useEffect(() => {
 if(!active) setDate(null)
  }, [active]);

  async function handleChange(date) {
    setDate(date)
    onChange(date)
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      setArrowUp(isOpen);
    }, 400);
    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen]);

  const onClickResetDate = ()=>{
    setDate(null);
    onChange(null)

  }

  return (
    <Container>
      <StyledCard className={"map-layer-card smart-city"}>
        <Card.Header className={"card-header-sf"} onClick={() => setIsOpen(!isOpen)}>
          <div className={"title-layer"}>
            <Title>{title}</Title>
          </div>
          <i className={`fa fa-angle-${!arrowUp ? "down" : "up"}`} aria-hidden="true" />
        </Card.Header>
          <Collapse in={isOpen} className={"card-body-collapse"}>
            <FlexedDiv className={"card-body-content"}>
              {values.map(({ value, label, active, onClick, iconLabel, disabled }) => (
                <StylessButton disabled={disabled} value={value} active={active} onClick={() => onClick(value)}>
                  <ListGroup.Item>
                    {isIcon ? (
                      <StyledIcon>
                        <i className={`fa fa-${iconLabel}`} aria-hidden="true"></i>
                      </StyledIcon>
                    ) : (
                      label
                    )}
                  </ListGroup.Item>
                </StylessButton>
              ))
              }
              <StyledDatePicker active={active}>
                <div ><p className="date-label">Full month:</p></div>
                <DatePicker
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  selected={date}//? date : null
                  onChange={(date) => handleChange(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                  />
                  <CloseButton disabled={!date} onClick={onClickResetDate} >
                     &times;
                  </CloseButton>
              </StyledDatePicker>
             </FlexedDiv>
          </Collapse>
      </StyledCard>
    </Container>
  );
}

export default CardCollapseWithDatePicker;
