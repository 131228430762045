import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProject as setReduxProject, resetProject } from "../store/actionCreators/general";
import { setAsyncProject } from "../store/actionCreators/general";
import Api from "../controller/ApiManager/index";
import { useChartsStatisticsActions, usePrevious } from "./common";
import { useUserPreferencesState } from "./useUserPreferences";
import EventEmitter from "../controller/EventEmitter";
import { events, featuresPermissions, mapLayerTypes, modals } from "../controller/Constants";
import { DEFAULT_PROJECT_PARAMS } from "../store/defaultValues/general";
import { DEFAULT_USER_PREFERENCES } from "../store/defaultValues/map";
import { setProjects } from "../store/actionCreators/map";
import { SET_CURRENT_DIALOG } from "../store/actionTypes";


export function useIsFeaturePermitted() {
  const project = useSelector((state) => state.general.project);
  return useCallback((featureName) => {
    const name = featureName === "heatMap" ? "greenCityMap" : featureName;
    const permission = project.config.dataAccess?.featuresPermissions?.find(feature => feature.name === name);
    return Boolean(permission && permission.isPermitted);
  }, [project])
}
function useProject() {
  const project = useSelector((state) => state.general.project);
  return project;
}

export function useResetProjectDefault() {
  const dispatch = useDispatch();
  const resetProject1 = useCallback(() => dispatch(resetProject()), [dispatch]);
  return resetProject1;
}

export function useSetProject() {
  const dispatch = useDispatch();
  const setProject = useCallback((project) => dispatch(setReduxProject(project)), [dispatch]);
  return setProject;
}

export function useSetAndUpdateProject() {
  const dispatch = useDispatch();
  const setProject = useCallback(async (project) => dispatch(await setAsyncProject(project)), [dispatch]);
  return setProject;
}

export function useSetProjects() {
  const dispatch = useDispatch();
  const setProjectsReturn = useCallback((projects) => dispatch(setProjects(projects)), [dispatch]);
  return setProjectsReturn;
}

function useProjectByCurrentMapLayer(setFetching, setError) {
  const [project, setProject] = useProjectState();
  const previousProject = usePrevious(project);
  const { projectId } = useParams();

  useEffect(() => {
    if (previousProject?.defaultMapLayer === project.defaultMapLayer || !previousProject?._id || previousProject?._id !== project._id) {
      return;
    }
    setFetching(true);
    console.log("getProject 4");
    Api.Project.getProject(projectId, { binsPopulated: true }, { mock: false })
      .then((projectRes) => {
        // call to the server with loader
        console.log("before setProject 3");
        setProject(projectRes);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [project.defaultMapLayer]);
}

function useFetchProjectById(setError, setFetched, fetchProject) {
  const { projectId } = useParams();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setError(null);
    setFetched(false);
    fetchProject().then(() => ({}));
  }, [projectId, user.isAuthenticated]); // כניסה חדשה או פרויקט אחר.
}

function openDialog(dispatch, dialogName) {
  dispatch({
    type: SET_CURRENT_DIALOG,
    dialog: dialogName,
  });
}

export function useFetchProject() {
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [project, setProject] = useProjectState();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();

  async function fetchProject() {
    let projectRes;
    try {
      if (!user.isAuthenticated || !projectId) {
        return;
      }
      setFetching(true);
      projectRes = await Api.Project.getProject(projectId); // call to the server with loader
      const config = projectRes?.config;
      console.log({ "projectRes?.config": projectRes?.config })
      const hasPermissionsObject = projectRes.config.dataAccess?.featuresPermissions?.length > 0;
      const hasSomePermission = projectRes.config.dataAccess?.featuresPermissions.some(feature => feature.isPermitted);
      if (!hasPermissionsObject || !hasSomePermission) openDialog(dispatch, modals.NO_PERMISSSINS_TO_COVERAGE_MAPS);
      const projectPermissions = projectRes.config.dataAccess?.featuresPermissions
      const cellularMapIsPermitted = projectPermissions.find(({ name, isPermitted }) => name === featuresPermissions.CELLULAR_MAP && isPermitted);
      const mapLayerName = cellularMapIsPermitted ? featuresPermissions.CELLULAR_MAP : projectPermissions.find(({ name, isPermitted }) => isPermitted)?.name
      const mapper = {
        cellularMap: "cellular",
        greenCityMap: "greenCity",
        iotMap: "iot"
      }
      const mapLayer = mapper[mapLayerName] || "";
      const providersIds = config?.dataAccess?.availableProviders || []
      const providersArray = await Api.Provider.getProviders(providersIds)
      const providersArrayWithoutNull = providersArray.filter(elements => elements !== null)
      projectRes.config.dataAccess.availableProviders = providersArrayWithoutNull
      if (!projectRes) return;
      const defaultUserPreferencesProvider = []
      setUserPreferences({ ...DEFAULT_USER_PREFERENCES, currentMapLayer: mapLayer, providers: defaultUserPreferencesProvider });
      setProject({ ...projectRes });
      setFetched(true);
      setError(null);
    } catch (err) {
      console.error(err);
      setError(err);
      return;
    } finally {
      setFetching(false);
      setFetched(true);
    }
    return projectRes;
  }

  EventEmitter.subscribe(events.UPDATE_PROJECT, fetchProject); // look inside

  useFetchProjectById(setError, setFetched, fetchProject);

  useProjectByCurrentMapLayer(setFetching, setError);

  return { fetching, error, fetched };
}


function getDateRangePatch(relativeDateRange) {
  const getYear = (date) => { return date.split("-")[0] }
  const getMonth = (date) => { return date.split("-")[1] }
  const dayInMS = 1000 * 60 * 60 * 24;
  let dateRange;

  switch (relativeDateRange.dateType) {
    case "month":
      dateRange = {
        from: new Date(Date.now() - 30 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0)
      }
      break;
    case "week":
      dateRange = {
        from: new Date(Date.now() - 7 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0)
      }
      break;
    case "day":
      dateRange = {
        from: new Date(Date.now() - 1 * dayInMS).setUTCHours(0, 0, 0, 0),
        to: new Date(Date.now() - 0 * dayInMS).setUTCHours(0, 0, 0, 0)
      }
      break;
    case "date":
      const selectedMonth = getMonth(relativeDateRange.date)
      const selectedYear = getYear(relativeDateRange.date)
      dateRange = {
        from: new Date(selectedYear, selectedMonth, 1).setUTCHours(0, 0, 0, 0),
        to: new Date(selectedYear, selectedMonth + 1, 1).setUTCHours(0, 0, 0, 0),
      }
      break;

    default:

      dateRange = {
        from: String(new Date().setUTCHours(0, 0, 0, 0)),
        to: String(new Date().setUTCHours(0, 0, 0, 0)),
      }
      break;
  }
  return {
    from: Number(dateRange.from),
    to: Number(dateRange.to),
  }
}

export function useProjectStatistics() {
  const [fetching, setFetching] = useState(false);//localy
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(null);
  const project = useProject();
  const { setChartsStatistics } = useChartsStatisticsActions();
  const [userPreferences] = useUserPreferencesState();
  const stopPending = useRef(false);
  const counter = useRef(0);

  let newUserPreferences = {
    ...userPreferences, createdAt: project.createdAt, signalType: "rsrp"
  }

  useEffect(() => {
    async function startMakingReport() {
      await Api.Project.getStatistics(project._id, {
        requestPreferences:
          newUserPreferences, algorithm: "static-clustering", polygonSource: "request-preferences", windowViewPolygon: { type: "Polygon", coordinates: project.polygon }
      });
    }
    startMakingReport()
    setFetched(false);
    const intervalId = setInterval(() => {
      counter.current++;
      setFetching(true);
      if (stopPending.current) clearInterval(intervalId);
      Api.Project.gatherStatistics(project._id)
        .then((statisticsRes) => {
          setChartsStatistics({
            data: statisticsRes.statistics,
            status: statisticsRes.status,
          })
          if (statisticsRes.status === "ready") {
            stopPending.current = true;
            setFetched(true);
          }

        })
        .catch((err) => {
          console.error(err);
          setError(err);
          // setFetched(true);
        })
        .finally(() => {
          if (counter.current === 30) {
            setFetched(true);
            stopPending.current = true;
          }
          setFetching(false);

        });
    }, 6000); // Poll every 6 seconds

    return () => {
      clearInterval(intervalId);
    }
  }, []);
  return { fetching, fetched, error };
}

export function useProjectStatisticsWithUseQuery() {
  const [fetching, setFetching] = useState(false);//localy
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(null);
  const project = useProject();
  const { setChartsStatistics } = useChartsStatisticsActions();
  const [userPreferences] = useUserPreferencesState();
  const stopPending = useRef(false);
  const counter = useRef(0);
  let newUserPreferences = {
    ...userPreferences, createdAt: project.createdAt, signalType: "rsrp"
  }
  // const getQueryPolling = useQueryPolling();
  useEffect(() => {
    async function startMakingReport() {
      await Api.Project.getStatistics(project._id, {
        requestPreferences:
          newUserPreferences, algorithm: "static-clustering", polygonSource: "request-preferences", windowViewPolygon: { type: "Polygon", coordinates: project.polygon }
      });
    }
    startMakingReport()
    // getQueryPolling({ pollingName: "report", pollingId: project._id, onComplete: () => console.log("on complete polling") })
  }, []);
  return { fetching, fetched, error };
}

export function useProjectParams() {
  const project = useProject();
  const { mapLayersParams } = project;
  const [userPreferences, setUserPreferences] = useUserPreferencesState();
  const isBestServer = userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER;
  const desiredMapLayer = isBestServer ? mapLayerTypes.CELLULAR : userPreferences.currentMapLayer;
  const layerParams = mapLayersParams.find((layerParams) => layerParams.mapLayer === desiredMapLayer);
  if (!layerParams) return DEFAULT_PROJECT_PARAMS;

  return layerParams.projectParams;
}

export default useProject;

export function useProjectState() {
  return [useProject(), useSetProject()];
}

export function useProjectActions() {
  return {
    project: useProject(),
    setProject: useSetProject(),
    setAndUpdateProject: useSetAndUpdateProject(),
  };
}
