
export const isPolygonEmpty = (polygon) => {
    return polygon && polygon.length === 0;
};

export const getPath = (polygon) => {
    if(!polygon) return [];
    let path = [];
    polygon.getPath().forEach(point => path.push({ lat: point.lat(), lng: point.lng() }));
    return path;
};
