import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Constants from "../../../../controller/Constants";
import { useUserGroups } from "../../../../Hooks/common";
import { getCurrentAreaOfDifference } from "../../MapManegarIsFinalUtils";

let addListenersOnPolygonClick = function (polygon, history) {
  window.google.maps.event.addListener(polygon, 'click', function (event) {
    history.push(`/projects/${polygon.projectId}/coverage-map`);
    window.google.maps.event.trigger(window.mainMap, 'click', event); // propagate the click event on a polygon to the map object
  });
}
let addListenersOnPolygonMouseover = function (polygon, history) {
  window.google.maps.event.addListener(polygon, 'mouseover', function (event) {
    this.map.getDiv().setAttribute('title', `Select this polygon`);
  });
}
let addListenersOnPolygonMouseout = function (polygon, history) {
  window.google.maps.event.addListener(polygon, 'mouseout', function (event) {
    this.map.getDiv().removeAttribute('title');
  });
}
const clearListenersOnPolygonMouseover = (polygon) => window.google.maps.event.clearListeners(polygon, 'mouseover');

const clearListenersOnPolygonMouseout = (polygon) => window.google.maps.event.clearListeners(polygon, 'mouseout');


function ProjectPolygon({ polygonPath, visible, projectId, currentProject, currentProjectPolygon }) {
  const rectangleRef = useRef(null);
  const history = useHistory();
  const userGroups = useUserGroups();
  const isAdmin = userGroups?.includes(Constants.userGroups.ADMIN);

  const polygonSetRef = useCallback((polygonForDraw, projectId) => {
    const rectangle = new window.google.maps.Polygon({
      paths: polygonForDraw,
      fillColor: "rgba(6, 249, 249,1)",
      strokeColor: "rgba(136, 135, 135,1)",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillOpacity: 0,
      editable: false,
      draggable: false,
      projectId: projectId,
    });
    rectangleRef.current = rectangle;
    rectangleRef.current.setMap(window.mainMap);
    if (!isAdmin) addListenersOnPolygonClick(rectangle, history);

  }, [isAdmin])
  const checkDifferenceOfPolygons = async () => {
    console.log(polygonPath)
    console.log(currentProjectPolygon)
    const areaOfIntrest = await getCurrentAreaOfDifference(polygonPath, currentProjectPolygon, 1);
    // if (areaOfIntrest.length > 0) {
    console.log(areaOfIntrest)
    return areaOfIntrest
  }


  useEffect(() => {
    const handleAffect = async () => {
      let polygonForDraw
      if (currentProject !== projectId) {
        const areaOfIntrest = await checkDifferenceOfPolygons();
        polygonForDraw = areaOfIntrest
      }
      else
        polygonForDraw = polygonPath

      polygonSetRef(polygonForDraw, projectId)
    }

    handleAffect();
  }, [polygonSetRef])


  useEffect(() => {
    const checkDifferenceOfPolygons = async () => {
      const areaOfIntrest = await getCurrentAreaOfDifference(polygonPath, currentProjectPolygon, 1);
      if (!rectangleRef?.current) return
      rectangleRef?.current?.setPath(areaOfIntrest);
      rectangleRef?.current?.setOptions({
        strokeColor: 'rgba(136, 135, 135,1)', strokeOpacity: 0.3,
        strokeWeight: 3, fillColor: '#D3D3D3	', fillOpacity: 0.35
      });
    }
    const cleanUp = () => {
      clearListenersOnPolygonMouseover(rectangleRef.current);
      clearListenersOnPolygonMouseout(rectangleRef.current);
    }

    let polygonForDraw
    if (!rectangleRef?.current) return
    if (currentProject !== projectId) {
      checkDifferenceOfPolygons();
      addListenersOnPolygonMouseover(rectangleRef.current);
      addListenersOnPolygonMouseout(rectangleRef.current);
    }
    else {
      polygonForDraw = polygonPath
      if (!rectangleRef?.current) return
      rectangleRef?.current?.setPath(polygonPath);
      rectangleRef?.current?.setOptions({
        fillColor: "rgba(6, 249, 249,1)",
        strokeColor: "rgba(136, 135, 135,1)",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillOpacity: 0,
      }
      );
    }
    return cleanUp
  }, [polygonPath, currentProject])

  useEffect(() => {
    rectangleRef?.current?.setOptions({ visible });
  }, [visible])

  return (
    null
  );
}

ProjectPolygon = React.memo(ProjectPolygon)
export default ProjectPolygon;