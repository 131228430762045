import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../controller/ApiManager";
import {dateRangeTypes, DAY_IN_MILLISECONDS} from "../controller/Constants";
import {addToast, setCurrentDialog, setFetchLoader as setFetchLoaderCreator, openDialog,  setShowChartsByPolygon } from "../store/actionCreators/general";

import {
  setPlacements,
  pushToPlacements,
  setPolygonPath,
  setTechnologiesCoverage,
  setPredictionState as setPredictionStateCreator,
  setUserPreferences,
  setDateRange,
  setArieCoverageReliability,
  setArieCoverageReliabilitySelectedErea,
  toggleUserPolygonDisplayed,
  setPlacementsForCharts,
  setChartsStatistics,
  setHeatMapPraimeryRating,
  setApplicationLayer,
  setReportDocumentId,

} from "../store/actionCreators/map";
import { setUser, logoutUser } from "../store/actionCreators/user";
import { getCognitoId } from "./useAuthentication";
import useProject from "./useProject";
import { decodeJwtToken } from "../controller/common";
import uniqid from "uniqid";

export function useSetCurrentDialog() {
  const dispatch = useDispatch();
  return useCallback((dialogName) => {
    dispatch(setCurrentDialog(dialogName));
  });
}

export function useAddToast() {
  const dispatch = useDispatch();
  const addToastRedux = useCallback((toastMessage) => dispatch(addToast(toastMessage)), [dispatch]);
  return addToastRedux;
}

export function useUserGroups() {
  // where is useUser?
  const user = useSelector((state) => state.user.user);
  return user.groups;
}

export function usePredictionState() {
  // is there predictionState?
  const predictionState = useSelector((state) => state.map.predictionState);
  const dispatch = useDispatch();
  const setPredictionState = useCallback(
    (predictionState) => {
      dispatch(setPredictionStateCreator(predictionState));
    },
    [dispatch]
  );
  return [predictionState, setPredictionState];
}

export function useFetchLoader() {
  const isFetchLoader = useSelector((state) => state.general.isFetchLoader);
  const dispatch = useDispatch();
  const setFetchLoader = useCallback(
    (isFetchLoader) => {
      dispatch(setFetchLoaderCreator(isFetchLoader));
    },
    [dispatch]
  );
  return [isFetchLoader, setFetchLoader];
}

export function useSetFetchLoader() {
  return useFetchLoader()[1];
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function usePolygonPath() {
  return useSelector((state) => state.map.polygonPath);
}

export function usePolygonPathState() {
  const dispatch = useDispatch();
  const polygonPath = usePolygonPath();
  const setPolygonPathCB = useCallback(
    (path) => {
      dispatch(setPolygonPath(path));
    },
    [dispatch]
  );
  return [polygonPath, setPolygonPathCB];
}

export function useAlertFirstRender(message) {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      window.alert(message);
    }
  });
}

export function useSetTechnologiesCoverage() {
  const dispatch = useDispatch();
  return useCallback((technologiesCoverage) => dispatch(setTechnologiesCoverage(technologiesCoverage)), [dispatch]);
}

export function useTechnologiesCoverage() {
  return useSelector((state) => state.map.technologiesCoverage);
}

export function useSetUserRedux() {
  const dispatch = useDispatch();
  const setUserRedux = useCallback((user) => dispatch(setUser(user)), [dispatch]);
  return setUserRedux;
}

export function useLogoutUser() {
  const dispatch = useDispatch();
  const useLogoutUser = useCallback((user) => dispatch(logoutUser(user)), [dispatch]);
  return useLogoutUser;
}

export function useSetUserToRedux() {
  const setUserRedux = useSetUserRedux();
  return useCallback(
    (idToken, refreshToken, accessToken) => {
      const decodedToken = decodeJwtToken(idToken);
      const { email, "cognito:groups": groups, "custom:isTermsChecked": checkedTerms, } = decodedToken;
      const isTermsChecked = checkedTerms === "true";
      setUserRedux({
        user: { email, groups, isTermsChecked },
        idToken,
        refreshToken, //Refresh Token is uses at the first login to save in local storge
        accessToken
      });
      return { isTermsChecked, email }
    },
    [setUserRedux]
  );
}

export function useUser() {
  const user = useSelector((state) => state.user);
  return user;
}

export function useOpenDialogs() {
  const dispatch = useDispatch();
  const openDialogs = useCallback((dialog) => dispatch(openDialog(dialog)), [dispatch]);
  return openDialogs;
}

export function useAverageSignal() {
  const averageSignal = useSelector((state) => state.map.averageSignal);
  return averageSignal;
}

function usePlacements() {
  const placements = useSelector((state) => state.map.placements);
  return placements;
}

function useSetPlacements() {
  const dispatch = useDispatch();
  return useCallback((placements) => dispatch(setPlacements(placements)), [dispatch]);
}

function usePlacementsForCharts() { 
  const placements = useSelector((state) => state.map.placementsForCharts);
  return placements;
}

function useSetPlacementsForCharts() {
  const dispatch = useDispatch();
  return useCallback((placements) => dispatch(setPlacementsForCharts(placements)), [dispatch]);
}

function useChartsStatistics() { 
  const placements = useSelector((state) => state.map.chartsStatistics);
  return placements;
}

function useSetChartsStatistics() {
  const dispatch = useDispatch();
  return useCallback((chartsStatistics) => dispatch(setChartsStatistics(chartsStatistics)), [dispatch]);
}

function useReportDocumentId() { 
  const reportDocumentId = useSelector((state) => state.map.reportDocumentId);
  return reportDocumentId;
}

function useSetReportDocumentId() {
  const dispatch = useDispatch();
  return useCallback((reportDocumentId) => dispatch(setReportDocumentId(reportDocumentId)), [dispatch]);
}

function useShowChartsByPolgon() { 
  const placements = useSelector((state) => state.general.showChartsByPolygon);
  return placements;
}

function useSetShowChartsByPolgon() {
  const dispatch = useDispatch();
  return useCallback((show) => dispatch(setShowChartsByPolygon(show)), [dispatch]);
}

function useArieCoverageReliability() {
  const ArieCoverageReliability = useSelector((state) => state.map.ArieCoverageReliability);
  return ArieCoverageReliability;
}

function useSetArieCoverageReliability() {
  const dispatch = useDispatch();
  return useCallback((ArieCoverageReliability) => dispatch(setArieCoverageReliability(ArieCoverageReliability)), [dispatch]);
}
function useUserPolygonDisplayed() {
  const userPolygonDisplayed = useSelector((state) => state.map.userPolygonDisplayed);
  return userPolygonDisplayed;
}

function useSetUserPolygonDisplayed() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleUserPolygonDisplayed()), [dispatch]);
}

export function useUserPolygonDisplayedState() {
  return [useUserPolygonDisplayed(), useSetUserPolygonDisplayed()]
}
export function useArieCoverageReliabilityState() {
  return [useArieCoverageReliability(), useSetArieCoverageReliability()]
}
function useArieCoverageReliabilitySelectedErea() {
  const ArieCoverageReliabilitySelectedErea = useSelector((state) => state.map.ArieCoverageReliabilitySelectedErea);
  return ArieCoverageReliabilitySelectedErea;
}

function useSetArieCoverageReliabilitySelectedErea() {
  const dispatch = useDispatch();
  return useCallback((ArieCoverageReliabilitySelectedErea) => dispatch(setArieCoverageReliabilitySelectedErea(ArieCoverageReliabilitySelectedErea)), [dispatch]);
}

export function useArieCoverageReliabilitySelectedEreaState() {
  return [useArieCoverageReliabilitySelectedErea(), useSetArieCoverageReliabilitySelectedErea()]
}


function useHeatMapPraimeryRating() {
  const heatMapPraimeryRating = useSelector((state) => state.map.heatMapPraimeryRating);
  return heatMapPraimeryRating;
}

function useSetHeatMapPraimeryRating() {
  const dispatch = useDispatch();
  return useCallback((heatMapPraimeryRating) => dispatch(setHeatMapPraimeryRating(heatMapPraimeryRating)), [dispatch]);
}
export function useHeatMapPraimeryRatingState() {
  return [useHeatMapPraimeryRating(), useSetHeatMapPraimeryRating()]
}

function useSetApplicationLayer() {
  const dispatch = useDispatch();
  return useCallback((applicationLayer) => dispatch(setApplicationLayer(applicationLayer)), [dispatch]);
}
function useApplicationLayer() {
  const applicationLayer = useSelector((state) => state.map.applicationLayer);
  return applicationLayer;
}
export function useApplicationLayerState() {
  return [useApplicationLayer(), useSetApplicationLayer()]
}

function useClearPlacements() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(setPlacements([])), [dispatch]);
}

function usePushToPlacements() {
  const dispatch = useDispatch();
  return useCallback((placements) => dispatch(pushToPlacements(placements)), [dispatch]);
}

export function usePlacementsActions() {
  return {
    placements: usePlacements(),
    clearPlacements: useClearPlacements(),
    pushToPlacements: usePushToPlacements(),
    setPlacements: useSetPlacements(),
  };
}

export function usePlacementsForChartsActions() {
  return {
    placementsForCharts: usePlacementsForCharts(),
    setPlacementsForCharts: useSetPlacementsForCharts(),
  };
}
export function useChartsStatisticsActions() {
  return {
    chartsStatistics: useChartsStatistics(),
    setChartsStatistics: useSetChartsStatistics(),
  };
}

export function useReportDocumentIdActions() {
  return {
    reportDocumentId: useReportDocumentId(),
    setReportDocumentId: useSetReportDocumentId(),
  };
}

export function useShowChartsByPolygonActions() {
  return {
    ShowChartsByPolygon: useShowChartsByPolgon(),
    setShowChartsByPolgon: useSetShowChartsByPolgon(),
  };
}

export function useId() {
  const id = useRef(null);
  useEffect(() => {
    id.current = uniqid();
  }, []);
  return id.current;
}

export function useEffectExceptFirst(cb, deps) {
  const first = useRef(true);
  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    return cb();
  }, deps);
}


// export const useEffectOnce = (callback, deps) => {
//   const hasBeenActivated = useRef(false);
//   const depsExist = (deps) => deps.every(dep => dep);
  
//   useEffect(() => {
//     if (!depsExist() || hasBeenActivated.current) {
//       hasBeenActivated.current = true;
//       return;
//     }
//     return cb();
//   }, deps);

// }

