import React, { useEffect } from "react";
import { clearGeoJsonData, clearGeoJsonDataStyle, drawGeoJsonData, initDataLayer, initDataLayerStyle, setGeoJsonDataStyle } from "./utils";

function GeoJsonDataDrawer({ dataLayer, data, map, getStyle }) {
    useEffect(() => {
        initDataLayer(dataLayer, map)
        initDataLayerStyle(dataLayer, getStyle)
        return () => {
            if (!map) return;
            clearGeoJsonData(dataLayer);
            clearGeoJsonDataStyle(dataLayer);// TODO: do we need it here?

        }
    }, [])

    useEffect(() => {
        if (!map) return;
        clearGeoJsonData(dataLayer);
        drawGeoJsonData(dataLayer, data);
        // setGeoJsonDataStyle(dataLayer,getStyle);
    }, [data]);

    return null;
}
GeoJsonDataDrawer = React.memo(GeoJsonDataDrawer);
export default GeoJsonDataDrawer;
// TODO: docs =>
    // import {convertDataToGeoJson} from "./utils"
    // const LayerComp = (data) => {
    //     const geoJson = useMemo(() => convertDataToGeoJson(data),[data]) // styleType
    //     return <GeoJsonDataDrawer data={geoJson} styleConfig={""}/>
    // }
    // const mapper = {
    //     styleType: () => {}// return obj
    // }