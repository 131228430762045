import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/Button"
import CloseButton from "react-bootstrap/CloseButton"
import styled from "styled-components";
import { useEffect, useState } from "react";
import Api from "../../../controller/ApiManager";
import useProject from "../../../Hooks/useProject";

const ModalContent = styled.div`
   width: 40vw;
`;

function DeleteSensors({ onClose: closeDialog }) {
  const [sensorsInfo, setSensorsInfo] = useState([]);
  const projectId = useProject()._id;

  async function deleteSensorsFile(index) {
    Api.Sensors.deleteSensorsInfo(projectId, sensorsInfo[index]._id)
      .then((ans) => {
        const poppedObject = sensorsInfo.splice(index, 1)[0];
        setSensorsInfo([...sensorsInfo] || []);
      })
      .catch(console.error);
  }

  useEffect(() => {
    Api.Sensors.getSensorsInfo(projectId)
      .then((sensorsInfo) => {
        setSensorsInfo(sensorsInfo || []);
      })
      .catch(console.error);
  }, []);

  function formatDate(date) {
    if (!date)
      return
    const currentDate = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return currentDate.toLocaleString('he-IL', options);
  }

  return (
    <ModalContent style={{ width: "310px", maxHeight: "800px", overflowX: 'hidden' }}>
      <Button closeButton variant="null" onClick={closeDialog} style={{ marginLeft: '270px', marginBottom: '30px' }}>
        <CloseButton />
      </Button>
      <Modal.Header >
        <div>
          {sensorsInfo.map((item, index) => (
            <Card key={index} className="mb-3">
              <Card.Body>
                <Card.Title>File name: {item.fileName}</Card.Title>
              </Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item style={{ display: "flex", justifyContent: "center" }}>Created at: {formatDate(item.createdAt)}</ListGroup.Item>
                <ListGroup.Item style={{ display: "flex", justifyContent: "center" }}>Number of sensors: {item.count}</ListGroup.Item>
              </ListGroup>
              <Card.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="danger" onClick={() => deleteSensorsFile(index)}>
                  Delete
                </Button>
              </Card.Footer>
            </Card>
          ))}
        </div>
      </Modal.Header>
    </ModalContent>
  );
}

export default DeleteSensors;