import { useEffect } from "react";
import { useFetchProject, useSetProjects } from "./useProject";
import Api from "../controller/ApiManager/index";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import EventEmitter from "../controller/EventEmitter";
import { useAuth, useSubscribeToSetNewTockensAfterRefreshTokenEvent } from "./useAuthentication";
import { openDialog } from "../store/actionCreators/general";
import { modals } from "../controller/Constants";

window.EventEmitter = EventEmitter;

function useFetchProjects() { //* refactor *// what are the use cases to fech all projects? on user change?? and wher it shuld run??
  const user = useSelector((state) => state.user);
  const setProjects = useSetProjects();
  useEffect(() => {
    if (user.isAuthenticated) {
      Api.Project.getProjects()
        .then((projects) => {
          // call to the server with loader
          setProjects(projects || []);
        })
        .catch(console.error);
    }
  }, [user]);
}


function useProjectsDialog() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.map.projects);
  const isInSomeProject = useRouteMatch("/projects/:projectId");
  const params = useParams();
  const history = useHistory();
  const { projectId } = params;
  useEffect(() => {

    const hasProjects = Boolean(projects.length);
    const isProjectExistInProjects = projectId ? hasProjects && projects.map((project) => project._id).includes(projectId) : true; // :false ?
    const validUrlProject = isInSomeProject && isProjectExistInProjects
    if (user.isAuthenticated && !hasProjects) return dispatch(openDialog(modals.NO_PROJECTS_AVAILABLE));
    if (user.isAuthenticated && !(validUrlProject) && hasProjects) {
      if (user.user.groups[0] === "admin") {
        dispatch(openDialog(modals.PROJECT_LIST))
      } else {
        history.push(`/projects/${projects[0]._id}/coverage-map`);
      }
    }
  }, [projects]);
}

function useInitApp() {
  useFetchProjects();
  useProjectsDialog();
  useSubscribeToSetNewTockensAfterRefreshTokenEvent();
  const { fetching: projectFetching, fetched: projectFetched, error: projectError } = useFetchProject();
  const { error: userError, fetched: userFetched, fetching: userFetching } = useAuth();
  const requireLogin = Boolean(userError && userError.message);
  return {
    fetching: projectFetching || userFetching,
    projectFetched,
    userFetched,
    error: userError || projectError,
    requireLogin,
  };
}

export default useInitApp;
