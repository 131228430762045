export function extractData(barData, dataStruct) {
  if (barData === "problem with bins list")
    return []
  if (barData !== undefined && barData !== "problem with bins list") {
    if (barData["distribution"] !== undefined)
      dataStruct = barData.distribution;
    else (dataStruct = barData)
    return dataStruct
  }
  // if (barData !== undefined && barData.length>0) {
  //   const body=JSON.parse(barData[0].statisticsResult);
  // return dataStruct=body.statisticsResult;;
  // }
}