import Constants from "../Constants";
import { fetchAuth, handleError, navigateResponse } from "./helper";

export const SitePlanning = class {
    static async getAntennaCoverage(projectId, userPreferences, mapZoom, antennaId, antennaLocation, currentAntennaOriginalLocation, widerPrediction) {
        const resolution = Math.max(13 * Math.pow(2, (16 - mapZoom)), 10)
        try {
            const currentAntennaOriginalLocationMongoFomat = {
                "type": "Point",
                "coordinates": [
                    currentAntennaOriginalLocation.lng, currentAntennaOriginalLocation.lat]
            }
            let url = new URL(`${Constants.REST_URL}get-antenna-coverage/${projectId}`)
            url.searchParams.append('antennaIds', JSON.stringify([antennaId]));
            url.searchParams.append('binSize', JSON.stringify(resolution));
            url.searchParams.append('broadPrediction', JSON.stringify(widerPrediction));
            url.searchParams.append('location', JSON.stringify(antennaLocation));
            url.searchParams.append('originalLocation', JSON.stringify(currentAntennaOriginalLocationMongoFomat));

            const anttenaRes = await fetchAuth(url, { method: "GET" }).then(navigateResponse);
            console.log("providers:", userPreferences.providers)
            console.log("anttenaRes:", anttenaRes)
            const updatedBins = anttenaRes.binsPlacements.map(bin => {
                const location = { lng: bin[0], lat: bin[1] };
                const signal = bin[2];
                return { clusteredBins: [{ location, signal }], location, signal }
            });
            return { resolution, binsPlacements: updatedBins };
        } catch (error) {
            handleError(error);
        }
    }


    static async getAntennas(projectId, userPreferences,) {
        try {
            let url = new URL(`${Constants.REST_URL}projects/${projectId}/get-antennas`)
            url.searchParams.append('projectId', projectId);
            url.searchParams.append('providers', JSON.stringify(userPreferences.providers));
            url.searchParams.append('tech', JSON.stringify((userPreferences.technologies)));
            url.searchParams.append('tech', userPreferences.technologies);
            url.searchParams.append('bands', JSON.stringify((userPreferences.bands)));

            const anttenaRes = await fetchAuth(url, { method: "GET" }).then(navigateResponse);
            return anttenaRes.antenna;
        } catch (error) {
            handleError(error);
        }
    }
};




