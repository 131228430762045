import React from "react";
import { Div} from "./NavbarStyles";
import {  useHeatMapPraimeryRatingState, useApplicationLayerState, useTechnologiesCoverage} from "./../Hooks/common";
import StarsBarInfo from "./Common/StarsBarInfo";
import RatingList,{ getRatingInfo, getStarsRatingForPercentageAboveThreshold } from "./Common/RatingList";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import {useUserPreferencesState} from "../Hooks/useUserPreferences";
import {mapLayerTypes, mapLayerValues, applicationCoverage} from "../controller/Constants";

function NavbarRating() {
    const [heatMapPraimeryRating,] = useHeatMapPraimeryRatingState();
    const [applicationLayer ,setApplicationLayer] = useApplicationLayerState()
    const [userPreferences, ] = useUserPreferencesState();
    const isHeatMapLayer = userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP;

    const technologiesCoverage = useTechnologiesCoverage();

    const selectDefaultRating = (appName)=>{
        const technologyCoverage = technologiesCoverage.filter((app)=>app.name===appName)
        const percentageApplication = technologyCoverage.length > 0 ? technologyCoverage[0].value: 0 
        return Number(percentageApplication)
    }
    
    const onClickNavbar = (title)=>{
        setApplicationLayer(title)
    }

    const systemPraimeryRating = isHeatMapLayer? heatMapPraimeryRating: getRatingInfo(applicationCoverage, selectDefaultRating(applicationLayer)).starRate;
    return (
        <Div>
            {"Rate"}
            &nbsp; &nbsp;
            <StarsBarInfo starRate={systemPraimeryRating} showPercentage={false} />
            {!(userPreferences.currentMapLayer === mapLayerTypes.HEAT_MAP) && (

                <div className="primary-nav has-mega-menu">
                    <ul className="navigation">
                        <NavbarDropdown title={""}>{<RatingList onClick={onClickNavbar} isSelected={applicationLayer} showIcons={true} dataArray={technologiesCoverage} dataValueToListItemdata={applicationCoverage}/>}</NavbarDropdown>
                    </ul>
                </div>
            )}
        </Div>
    );
}

export default NavbarRating;