import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import {openDialog} from "../../store/actionCreators/general";
import Constants from "../../controller/Constants";
import {modals} from "../../controller/Constants";

const ModalContent = styled.div`
  width: 20vw;
`;
const SubmitButton = styled(Button)`
  &.btn[type="submit"] {
    margin-top: 20px !important;
  }
`;
const P = styled.h3`
  font-weight: normal;
  color: #000;
`;

function ReducePolygonSizeAlert({onBack, addToast, setProject, openDialog}) {
  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{"Alert" + " !"}</h2>
          {typeof onBack === "function" && <BackButton className="btn btn-outline-light btn-rounded" onClick={onBack} />}
        </div>
      </Modal.Header>
      <Modal.Body>
        <P>The size of the polygon is too large, please reduce it and try again</P>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(() => ({}), {openDialog})(ReducePolygonSizeAlert);
