import styled from 'styled-components';


const Div = styled.div`
  position: absolute;
  display:flex
  top: 10px;
  left: 10px;
  max-height: 37px;
  text-align: center;
`;

export default function ActionLayer({ children }) {
    return (
        <Div>
            {children}
        </Div>

    );
}
