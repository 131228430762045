import { handleError } from "../../controller/ApiManager/helper";
import { ecnoRange, mapLayerValues, rsrqRange, snrRange } from "../../controller/Constants";
import { algorithmsTypes, between, colorsLabelsInfo, mapLayerTypes, HEATMAP_AGGREGATION_FACTOR } from "../../controller/Constants";
import { isExponentialFormat, getDivisionAndkeepExponentialNotation } from "../../controller/common";

export const EMPTY_PLACEMENT = {
  info: {
    binResolution: 20,
    algorithm: algorithmsTypes.STATIC_CLUSTERING
  },
  bins: [],
}
export const EMPTY_SENSORS_PLACEMENT = {
  resolution: 50,
  bins: [],
}

const calcResolution = (area) => {
  const MINIMUM_RESOLUTIOM = 10;
  const MAX_NUMBER_OF_BINS = 25000;
  const resolution = Math.floor(Math.sqrt(Math.floor(area / MAX_NUMBER_OF_BINS)));
  return resolution < MINIMUM_RESOLUTIOM ? MINIMUM_RESOLUTIOM : resolution;

}
export const getResolution = (windowViewPolygon) => {
  const path = windowViewPolygon.coordinates;
  const area = window.google.maps.geometry.spherical.computeArea(path)
  return calcResolution(area);
}

export function getSignalsRangesForHeatMap(bins) { // can be refactored to get ranges as args
  const total = bins.length;

  let countMinus70toMinus80 = 0;
  let countMinus60toMinus90 = 0;
  let countMinus50toMinus100 = 0;
  let countMinus40toMinus110 = 0;
  let countAboveMinus110 = 0;

  for (const bin of bins) {

    if (bin.signal <= -70 && bin.signal >= -80) countMinus70toMinus80++;
    if (bin.signal <= -60 && bin.signal >= -90) countMinus60toMinus90++;
    if (bin.signal <= -50 && bin.signal >= -100) countMinus50toMinus100++;
    if (bin.signal <= -40 && bin.signal >= -110) countMinus40toMinus110++;
    if (bin.signal <= 0 && bin.signal >= -110) countAboveMinus110++;

  }

  const percentMinus70toMinus80 = (countMinus70toMinus80 / total) * 100;
  const percentMinus60toMinus90 = (countMinus60toMinus90 / total) * 100;
  const percentMinus50toMinus100 = (countMinus50toMinus100 / total) * 100;
  const percentMinus40toMinus110 = (countMinus40toMinus110 / total) * 100;
  const percentAboveMinus110 = (countAboveMinus110 / total) * 100;

  // order dos matter!
  if (percentMinus70toMinus80 >= 90) {
    return 5;
  } else if (percentMinus60toMinus90 >= 90) {
    return 4;
  } else if (percentMinus50toMinus100 >= 90) {
    return 3;
  } else if (percentMinus40toMinus110 >= 90) {
    return 2;
  } else if (percentAboveMinus110 >= 90) {
    return 1;
  }
}

export const isUserPreferencesValid = (userPreferences) => {
  return userPreferences.providers.length > 0 && userPreferences.technologies.length > 0 &&
    (userPreferences.relativeDateRange.dateType || userPreferences.relativeDateRange.dateType === "date") &&
    (userPreferences.currentMapLayer === mapLayerValues.CELLULAR ? !!userPreferences.signalsType : true);

}

export const isDeepEqual = (stateA, stateB) => {
  return JSON.stringify(stateA) === JSON.stringify(stateB);
}

export const bestServerToCellularMapLayer = (userPreferences) => {
  if (userPreferences.currentMapLayer === mapLayerTypes.BEST_SERVER) {
    userPreferences.currentMapLayer = mapLayerTypes.CELLULAR;
  }
  return userPreferences;
}

export const getWindowViewPolygon = (mapBounds) => { // mapBounsToPolygon || getPolygonFromMapBounds => mapBounsToPolygon === getWindowViewPolygon ?
  if (!mapBounds) throw Error("Ther is no mapBounds!");

  let SW = mapBounds.getSouthWest();
  let NE = mapBounds.getNorthEast();
  let a1 = SW.lat();
  let a2 = SW.lng();
  let b1 = NE.lat();
  let b2 = NE.lng();
  const coordinates = [{ lat: a1, lng: a2 }, { lat: a1, lng: b2 }, { lat: b1, lng: b2 }, { lat: b1, lng: a2 }]
  return { type: "Polygon", coordinates }
}

const mapBounsToPolygonPath = (mapBounds) => {
  if (!mapBounds) return [];
  console.log({ mapBounds })

  let SW = mapBounds.getSouthWest();
  let NE = mapBounds.getNorthEast();
  let a1 = SW.lat();
  let a2 = SW.lng();
  let b1 = NE.lat();
  let b2 = NE.lng();
  const coordinates = [{ lat: a1, lng: a2 }, { lat: a1, lng: b2 }, { lat: b1, lng: b2 }, { lat: b1, lng: a2 }];

  return coordinates;
}

const applicationRangeIndexBySignal = (signal, range) => {
  const levelInfo = range.findIndex((val, index) => {
    if (index === 0) {
      return between(signal, Infinity, val)
    }
    if (index === range.length - 1) {
      return between(signal, val, -Infinity)
    }
    return between(signal, range[index - 1], val)
  })
  return levelInfo
}

export const getColor = (signal, currentMapLayer, technology, signalsType, range) => { // get colors for signal
  if (signal === undefined || signal === null) return "#CDCDCD"
  const indexInRange = applicationRangeIndexBySignal(signal, range)
  if (currentMapLayer === mapLayerTypes.BEST_SERVER)
    return colorsLabelsInfo[currentMapLayer][technology][indexInRange].color
  if (currentMapLayer === mapLayerTypes.CELLULAR) {
    return colorsLabelsInfo[currentMapLayer][technology][signalsType][indexInRange].color
  }
  const levelInfo = colorsLabelsInfo[currentMapLayer][technology].find(({ levels }) => between(signal, levels.max, levels.min));
  if (!levelInfo) throw Error("The signal doesn't match any range of colors");
  return levelInfo.color;
}
//maybe unite with getRange in mapmanegerfinal
export const getRange = (project, userPreferences, applicationLayer) => {
  if (userPreferences?.signalsType?.[0] === "rsrq") return rsrqRange;
  if (userPreferences?.signalsType?.[0] === "snr") return snrRange;
  if (userPreferences?.signalsType?.[0] === "ecno") return ecnoRange;

  const { config } = project
  const applicationList = config?.dataAccess?.applications
  const selectedApplication = applicationList?.find(({ name }) => (name === applicationLayer))
  const range = selectedApplication?.thresholds[userPreferences.technologies[0]]?.ranges
  return range
}

const getIntersection = async ({ polygons }) => {
  // try + catch
  const modulejsts = await import('./jstsAreaOperations.mjs'); //////////////////.<<<<<<<<<<<<<
  return modulejsts.getIntersection({ polygons })
}

const getDifference = async ({ polygons }) => {
  // try + catch
  const modulejsts = await import('./jstsAreaOperations.mjs'); //////////////////.<<<<<<<<<<<<<
  return modulejsts.getDifference({ polygons })
}

export const getCurrentAreaOfIntrest = async (mapBounds, projectPolygon, arg) => {

  const windowViewPolygon = mapBounsToPolygonPath(mapBounds);
  const pathB = projectPolygon
  if (pathB.length === 0 || windowViewPolygon.length === 0) return ////////////////////////////// return what????
  const fainalInter = await getIntersection({ polygons: [windowViewPolygon, pathB] })
  console.log({ fainalInter })

  const fainalIntersection = fainalInter.map(coord => ({ lat: coord[0], lng: coord[1] }))

  return fainalIntersection

} // useCallback
///
// bin.getDisplayeLabel
///


export const getCurrentAreaOfDifference = async (mapBounds, projectPolygon, arg) => {
  console.log({ arg })

  const pathB = projectPolygon
  if (pathB.length === 0 || mapBounds.length === 0) return ////////////////////////////// return what????
  const fainalInter = await getDifference({ polygons: [mapBounds, pathB] })
  console.log({ fainalInter })

  const fainalIntersection = fainalInter.map(coord => ({ lat: coord[0], lng: coord[1] }))
  console.log({ fainalIntersection })

  return fainalIntersection

}

/////////////////////////////////////////////////////////////////////////////////////
// transform.singleBinValue
// transform.binsListValues
// Bin.getLabel(mapLayer)
// transformSignal
// Bin.getPraimerySignal
// transformLabel


/////////////////////////////////////////////////////////////////////////////////////

export const applyPraimerySignal = ({ bins, mapLayer }) => {
  const signalTransformers = {
    heatmap: ({ signal }) => Math.pow(10, signal / 10) / 1000
  }
  const binTransformers = {
    heatmap: (bin) => ({
      ...bin,
      signal: bin.clusteredBins.map(signalTransformers[mapLayer]).sum() * HEATMAP_AGGREGATION_FACTOR
    })
  }

  const transformBin = (binTransformers[mapLayer] || (bin => bin));
  return bins.map(transformBin);
} // useCallback + useMemo!!!!!!!!

export const applyDisplayedLabel = ({ bins, mapLayer }) => {

  // const trimAfterDecPoint = ({ exponential, digits = 2 }) => {

  //   const [prefix, sefix] = String(exponential).split(".");
  //   console.log({ prefix, sefix })

  //   const [powPrefix, powSefix] = sefix.split("e");
  //   console.log({ powPrefix, powSefix })
  //   const fainal = prefix + "." + powPrefix.slice(0, digits) + "e" + powSefix
  //   console.log({ fainal })
  //   return fainal;
  // }

  const getHeatmapLabel = (signal) => {
    let label = "";
    console.log({ signal })
    label = isExponentialFormat(signal) ? signal : signal.toExponential(2);
    console.log({ label })
    const exponential = isExponentialFormat(signal) ? signal : label;
    label = getDivisionAndkeepExponentialNotation({ exponential, dividedBy: 350, digitsAfterDecPoint: 2 })
    // label = trimAfterDecPoint({exponential, digits:2})
    label = `${label} %`;
    return label;
  }
  const labelTransformers = {
    heatmap: (bin) => ({
      ...bin,
      label: getHeatmapLabel(bin.signal)
    })
  }
  const transformBin = (labelTransformers[mapLayer] || (bin => ({ ...bin, label: Number(bin.signal).toFixed(2) })));
  return bins.map(transformBin);
} // useCallback + useMemo!!!!!!!!

export const parsePlacementResponse = (placement) => {
  const { binResolution, algorithm, bins } = placement;
  const parsedPlacement = { info: { binResolution, algorithm }, bins };
  return parsedPlacement;
}

export const groupByLocation = (accmulator, placement) => {
  placement.bins.forEach((binOrigin) => {
    const accCopy = [...accmulator]; // shallow copy
    const decoratedBin = { ...binOrigin, provider: placement.provider, technology: placement.technology };
    const binIndex = accCopy.findIndex((accmulatedBin) => isSameLocation(accmulatedBin.location, binOrigin.location));

    if (binIndex !== -1) {
      const primaryBin = accCopy.splice(binIndex, 1)[0];
      primaryBin.clusteredBins.push(decoratedBin);
      const chosenBin = choosePrimaryBin(primaryBin); // updatePraimery
      primaryBin.signal = chosenBin.signal;
      primaryBin.provider = chosenBin.provider;
      primaryBin.technology = chosenBin.technology;
    } else {
      accmulator.push({
        ...decoratedBin,
        clusteredBins: [decoratedBin]
      });
    }
  });
  return accmulator;
}

const isSameLocation = (point1, point2) => {
  return (Number(point1.lat) === Number(point2.lat) && Number(point1.lng) === Number(point2.lng))
}

const choosePrimaryBin = (bin) => {  // must be changed!
  function reduceByHighestTechnology(accmulator, placement) {
    // decler the func inside or not? // hwo needs to be a copy????
    if (accmulator.length === 0) {
      accmulator.push(placement);
      return accmulator;
    }
    const placementTechNumber = Number(placement.technology[0]);
    const accmulatorTechNumber = Number(accmulator[0].technology[0]);
    if (placementTechNumber === accmulatorTechNumber) {
      accmulator.push(placement);
    } else if (placementTechNumber > accmulatorTechNumber) {
      accmulator = [placement];
    }
    return accmulator;
  }

  function getHighestTechnologys(clusteredBins) {
    // decler the func inside or not?
    return clusteredBins.reduce(reduceByHighestTechnology, []);
  }
  function getHighestSignal(clusteredBins) {
    // decler the func inside or not? // if 2 bins have same signal??
    const sortedClusteredBins = clusteredBins.sort((a, b) => (a.signal > b.signal ? 1 : b.signal > a.signal ? -1 : 0));
    //const sortedClusteredBins2 = Math.max(...clusteredBins.map((clsbin) => clsbin.signal)); // optionaly ?
    return sortedClusteredBins.slice(-1)[0]; // highest Signal // must be changed!
  }

  const chosenBinsList = getHighestTechnologys(bin.clusteredBins);
  if (chosenBinsList.length === 1) return chosenBinsList[0];
  const chosenBin = getHighestSignal(chosenBinsList);
  return chosenBin;
}

export const handleSitePlaningMapLayerPatch = (userPreferences) => {
  if (userPreferences.currentMapLayer === mapLayerTypes.SITE_PLANING) {
    userPreferences.currentMapLayer = mapLayerTypes.CELLULAR;
  }
}


//   mapLayerCoverageStarsRating
//   <StarsBarInfo title={"Voice Call"} starRate={calcTechnologyCoverage(technologiesCoverage["Voice call"]["forStars"])?.starRate } />

//   pers = binabove()