import { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Api from "../../controller/ApiManager";
import { useChartsStatisticsActions, useReportDocumentIdActions } from "../../Hooks/common";


const PollingCharts = ({ createdAt, pollingName, projectId, pollingId, onComplete }) => {
    const validateStatus = status => status === "ready" || status === "error" ||  status.startsWith("failed");
    const dispatch = useDispatch();
    const POLL_INTERVAL = 2000;
    const MAX_ATTEMPTS = 25;
    const { setChartsStatistics } = useChartsStatisticsActions();
    const { setReportDocumentId } = useReportDocumentIdActions();
    const queryClient = useQueryClient();
    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            queryClient.cancelQueries('myQueryKey');
        }
    }, [queryClient])

    const { data, status, error } = useQuery('myQueryKey',
        async () => {
            const result = await Api.Project.gatherStatistics(projectId, pollingId);
            // console.log("useQuery reault:", result.status)
            // console.log("is validateStatus:", validateStatus(result.status))
            // console.log("status === Ready", result.status === "ready")
            setReportDocumentId({
                status: result.status,
                documentId: pollingId
            })
            if (!validateStatus(result.status)) {
                throw new Error('Report info status is not ready');
            }
            //maybe more correct way to separate polling here from redux to
            if (result.status === "ready") {
                setChartsStatistics({
                    data: result.statistics,
                    status: result.status,
                    documentId: pollingId
                })
            }
            onComplete();
            return result;
        },
        {
            refetchInterval: POLL_INTERVAL,
            retry: MAX_ATTEMPTS,
            refetchOnWindowFocus: false
        }
    );
    if (status === 'loading') {
        return <div style={{ justifyContent: "center", display: "flex", fontSize: '20px' }}>We are preparing your report, it may take a few minutes . . .      </div>;
    }
    if (status === 'error') {
        return <div>Error: {error.message}</div>;
    }
    // return <div> new report: {data.Status}</div>;
};


export default PollingCharts;

