import Api from "../controller/ApiManager/index";


// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
  constructor(options) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.projectId = options.projectId
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5
    this.file = options.file
    this.fileName = options.fileName || "file"
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.numberOfParts = 0
    this.partIndex = 0
    this.uploadedParts = []
    this.uploadId = null
    this.sensorInfoId = null
    this.fileKey = null
    this.onProgressFn = () => { }
    this.onErrorFn = () => { }
    this.onCompleteFn = () => { }
    this.onHavingSensorIDFn = () => { }
  }

  start() {
    this.initialize()
  }

  async initialize() {
    try {
      // adding the the file extension (if present) to fileName
      // let fileName = this.fileName
      // const ext = this.file.name.split(".").pop()
      this.fileName = this.file.name.split(".")[0];
      // if (ext) {
      //   fileName += `.${ext}`
      // }

      const { SensorInfoId: sensorInfoId } = await Api.Sensors.createS3Multipart(this.projectId, this.fileName);


      this.sensorInfoId = sensorInfoId;
      this.onHavingSensorIDFn(sensorInfoId);
      // this.fileKey = Key;
      this.numberOfParts = Math.ceil(this.file.size / this.chunkSize)
      this.sendNext()
    } catch (error) {
      await this.complete(error)
    }
  }

  sendNext() {
    if (this.numberOfParts === this.uploadedParts.length) {
      this.complete();
      return;
    }

    if (this.file && this.numberOfParts > this.partIndex) {
      const sentSize = this.partIndex * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)
      this.partIndex++;

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, this.partIndex, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch((error) => {
          this.partIndex--;
          this.complete(error)
        })
    }
  }

  async complete(error) {
    if (error && !this.aborted) {
      this.onErrorFn(error)
      return
    }

    if (error) {
      this.onErrorFn(error)
      return
    }

    if (this.sensorInfoId) {
      try {
        const sortedEtags = this.uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);
        console.log("eTagsList:", JSON.stringify({ "eTagsList": sortedEtags }))
        const completeRes = await Api.Sensors.completeS3multiPart(this.sensorInfoId, sortedEtags);
        console.log(completeRes);
        this.onCompleteFn();
      } catch (error) {
        this.onErrorFn(error)
      }
    }
  }



  sendChunk(chunk, part, sendChunkStarted) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"))
            return
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  handleProgress(part, event) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  upload(chunk, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.sensorInfoId) {

        sendChunkStarted()

        const options = {
          method: 'PUT',
          body: chunk
        };
        // Upload the part


        try {
          Api.Sensors.uploadS3part(this.sensorInfoId, part, options).then(res => {
            const { ETag } = res;
            if (ETag) {
              const uploadedPart = {
                PartNumber: part,
                ETag: ETag.replaceAll('"', ""),
              }
              this.uploadedParts.push(uploadedPart)
              resolve(200)
            }
          });
        } catch (error) {
          reject(error);
        }
      }
    })
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  onHavingSensorID(onHavingSensorID) {
    this.onHavingSensorIDFn = onHavingSensorID
    return this
  }
  onError(onError) {
    this.onErrorFn = onError
    return this
  }

  onComplete(onComplete) {
    this.onCompleteFn = onComplete
    return this
  }

  abort() {
    this.aborted = true
  }
}
