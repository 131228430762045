import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import { isLight } from "../../controller/common";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import { colorsLabelsInfo, ecnoRange, mapLayerValues, rsrqRange, snrRange } from "../../controller/Constants";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useProject from "../../Hooks/useProject";
import { useApplicationLayerState } from "../../Hooks/common";


// import "./ColorMapping.css";
const ColoredListItem = styled(ListGroup.Item)`
  color: ${(props) => (isLight(props.backcolor) ? "black" : "white")};
  font-weight: bold;
  //text-align: center;
  //text-shadow: 0 0 2px rgba(0,0,0,1), 1px 1px 0 rgba(0,0,0,0.7), -1px -1px 0 rgba(0,0,0,0.2) ;
  background-color: ${(props) => (props.backcolor ? props.backcolor + " !important" : "red")};
  &.list-group-item {
    && {
      border-radius: 0;
    }
    padding: 2px 4px;
    font-size: 12px;
  }
`;

const StyledModal = styled(Modal.Dialog)`
  &.modal-dialog {
    @media (min-width: 576px) {
      margin: 0;
    }
  }
  .modal-content {
    border-radius: 0;
    border: none; //${({ theme }) => theme.palette.primary.main};
    box-shadow: ${({ theme }) => theme.shadows.map.control};
    .list-group-colors {
      border-radius: 0;
      min-width: 100px;
    }
  }
  .modal-header {
    padding: 7px 16px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
    border-radius: 0;
    .h4.modal-title {
      font-size: 15px;
    }
  }
`;
const ControlContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin: 0;
`;


function displayformat(levels, userPreferences) {// isert to component
  let minContainInfinity = false;
  let maxContainInfinity = false;
  let unit = "dBm"

  if (!isFinite(levels.min)) minContainInfinity = true;
  if (!isFinite(levels.max)) maxContainInfinity = true;
  if ((userPreferences?.signalsType?.[0] === "rsrq" || userPreferences?.signalsType?.[0] === "snr" ||
    userPreferences?.signalsType?.[0] === "ecno")) unit = "dB";

  if (minContainInfinity)
    return `Signal < ${levels.max}${unit}`
  else if (maxContainInfinity)
    return `${levels.min}${unit} <= Signal`
  else
    return `${levels.min}${unit} <= Signal< ${levels.max}${unit}`

}

function updateLevelsBySelectedApplication(colorsLabel, project, applicationLayer, userPreferences) {
  let inputArray;
  if (userPreferences?.signalsType?.[0] === "rsrq") inputArray = rsrqRange;
  else if (userPreferences?.signalsType?.[0] === "snr") inputArray = snrRange;
  else if (userPreferences?.signalsType?.[0] === "ecno") inputArray = ecnoRange;

  else {
    const { config } = project
    const applicationList = config?.dataAccess?.applications
    const selectedApplication = applicationList?.find(({ name }) => (name === applicationLayer))
    const range = selectedApplication?.thresholds[userPreferences.technologies[0]]?.ranges
    inputArray = range
  }

  const ranges = [];
  for (let i = 0; i < inputArray.length; i++) {
    if (i === 0) {
      ranges.push({ min: inputArray[i], max: Infinity });
    }
    else {
      if (i === inputArray.length - 1) ranges.push({ min: -Infinity, max: inputArray[inputArray.length - 1] });
      else ranges.push({ min: inputArray[i], max: inputArray[i - 1] });
    }
  }
  // ranges.pop() 
  // ranges.push({ min: -Infinity, max: inputArray[inputArray.length - 1]});

  const updatedArray = colorsLabel.map((obj, index) => {
    const { min, max } = ranges[index];
    return {
      ...obj,
      levels: { min, max },
    };
  });
  return updatedArray;
}


function ColorMapping() {
  const [userPreferences] = useUserPreferencesState();
  const project = useProject()
  const [applicationLayer] = useApplicationLayerState();

  try {
    // const {mapLayer, channelBW, rssiThreshold: minRssi} = projectParams;
    return (
      <ControlContainer>
        <StyledModal>
          <Modal.Header>
            <Modal.Title>{"Flycomm Scale"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup className={"list-group-colors"}>
              {userPreferences && userPreferences.currentMapLayer === mapLayerValues.CELLULAR ?
                updateLevelsBySelectedApplication(colorsLabelsInfo[userPreferences.currentMapLayer][userPreferences.technologies[0]][userPreferences.signalsType[0]], project, applicationLayer, userPreferences).map(({ color, label, levels }) => (
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip style={{ position: "fixed", whiteSpace: "nowrap" }}
                    >{displayformat(levels, userPreferences)}</Tooltip>}
                  >
                    <ColoredListItem key={`${label}`} backcolor={color}>
                      {label}
                    </ColoredListItem>
                  </OverlayTrigger>
                ))
                :
                colorsLabelsInfo[userPreferences.currentMapLayer][userPreferences.technologies[0]].map(({ color, label, levels }) => (
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip style={{ position: "fixed" }}
                    >{displayformat(levels)}</Tooltip>}
                  >
                    <ColoredListItem key={`${label}`} backcolor={color} onMouseOver>
                      {label}
                    </ColoredListItem>

                  </OverlayTrigger>


                ))}
            </ListGroup>
          </Modal.Body>
        </StyledModal>
      </ControlContainer>
    );
  } catch (e) {
    console.error(e);
    return (
      <ControlContainer>
        <div>there is an error here</div>
      </ControlContainer>
    );
  }
}

export default ColorMapping;
