
import { Col, Row, Container } from "react-bootstrap"
import { chartsTypes } from "./chartsDashboard";
import { TECHNOLOGYS } from '../../controller/Constants'
import { forwardRef } from "react";
import ApplicationQualityTable from "./tables/ApplicationQualityTable";

function ApplicationQuality({data, date, providers }, ref) {
    return (<>
        <Container fluid style={{ textAlign: "center", width: "100%" }} >
            <Row >
                <Col> <ApplicationQualityTable providers={providers} data={data} name={`${chartsTypes.APPLICATION_QUALITY}-${TECHNOLOGYS._4G}-all`} date={date} ref={ref} /> </Col>
            </Row>
            <Row >
            </Row>
        </Container>
    </>
    );
}
export default forwardRef(ApplicationQuality);

