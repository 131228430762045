import React from "react";
import styled from "styled-components";
import { StyledBadge } from "../NavbarStyles";
import StarsBar from "./StarsBar";

const Wrapper = styled.div`
  display: flex;
`;

function StarsBarInfo({ showPercentage, showBorder, label, color, starRate, percentage }) {
  return (
    <Wrapper>
      {showPercentage &&
        <StyledBadge showBorder={showBorder} padding variant="none" color={color || "#ffff"}>
          {label ? `${label}` : ""}
        </StyledBadge>}
      <StarsBar starRate={starRate} />
      {showPercentage && `${percentage} %`}
    </Wrapper>
  );
}

export default StarsBarInfo;
