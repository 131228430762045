
import { Col, Row, Container } from "react-bootstrap"
import LinechartComponent from "./linecharts/line.rechart";
import LinechartComponentMedian from "./linechartsForMedian/line.rechart";
import { chartsTypes } from "./chartsDashboard";
import { PROVIDERS, TECHNOLOGYS } from '../../controller/Constants'
import { forwardRef } from "react";

function CoverageReliabilityTendency({ data, providers, monthsSequence }, ref) {

    return (<>{data &&
        <Container fluid style={{ textAlign: "center", width: "100%" }} >
            <Row >
                {providers.map((item, index) => (
                    <Col md={12} lg={6} xl={4} > <h4> {providers[index]}</h4> <LinechartComponent data={data.coverageReliabilityTendency.filter(placement => placement[providers[index]]).map((placement) => ({ data: placement[providers[index]] }))} name={`${chartsTypes.COVERAGE_RELIABILITY_TENDENCY}-${[providers[index]]}-${TECHNOLOGYS._4G}`} ref={ref} monthsSequence={monthsSequence} /> </Col>
                ))}
            </Row>
            <Row >
                <Col md={{ span: 4, offset: 3 }} lg={6} xl={3}>  <h4> Coverage Reliability Tendency </h4><LinechartComponentMedian providers={providers} data={data.coverageReliabilityTendencyAllProviders} name={`${chartsTypes.COVERAGE_RELIABILITY_TENDENCY}-${TECHNOLOGYS._4G}-all`} ref={ref} monthsSequence={monthsSequence} /> </Col>
            </Row>
        </Container>}
    </>
    );
}
export default forwardRef(CoverageReliabilityTendency);

