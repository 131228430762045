import {
  ADD_PROJECT,
  MAP_LOADED,
  RESET_PROJECTS,
  SET_COVERAGE,
  SET_DISPLAYED_ANTENNAS,
  SET_HIGHLIGHT_OPACITY,
  SET_LINES,
  SET_LOCATION_STATS,
  SET_MAP_STATE,
  SET_MAP_TYPE,
  SET_MAP_ZOOM,
  SET_PREDICTION_STATE,
  SET_PROJECTS,
  SET_POLYGON_PATH,
  SET_TOTAL_COST,
  UPDATE_DISPLAYED_ANTENNAS,
  SET_USER_ENVIRONMENT,
  SET_TIME_OF_DAY,
  SET_AVERAGE_SIGNAL,
  SET_TECHNOLOGIES_COVERAGE,
  SET_USER_PREFERENCES,
  SET_PLACEMENTS,
  PUSH_TO_PLACEMENTS,
  ARIE_COVERAGE_RELIABILITY,
  ARIE_COVERAGE_RELIABILITY_SELECTED_EREA,
  TOGGLE_USER_POLYGON_DISPLAYED,
  SET_INDOOR_FLOOR_ENV,
  SET_PLACEMENTS_FOR_CHARTS,
  SET_CHARTS_STATISTICS,
  SET_HEAT_MAP_PRAIMERY_RATING,
  SET_APPLICATION_LAYER,
  REPORT_DOCUMENT_ID,

} from "../actionTypes";
import Constants, {userEnvironments, timeOfDay, defaultTechnologiesCoverage, applicationsTypes} from "../../controller/Constants";
import {DEFAULT_LOCATION_STATS, DEFAULT_USER_PREFERENCES} from "../defaultValues/map";
import {updateDisplayedAntennas} from "../actions/map";

export const DEFAULT_STATE = {
  mapType: Constants.mapTypes.BEST_SERVER,
  mapState: Constants.mapStates.SITES_COVERAGE,
  lines: false,
  coverage: 0,
  averageSignal: 0,
  placements: [],
  placementsForCharts: [],
  chartsStatistics: {
    data:[] ,
    status:'',
  },
  reportDocumentId: "",
  userPreferences: DEFAULT_USER_PREFERENCES,
  totalCost: 0,
  loaded: false,
  projects: [],
  currentProject: {}, //populated sites.
  displayedAntennas: [],
  mapZoom: 10,
  highlightOpacity: 0,
  predictionState: false,
  technologiesCoverage: defaultTechnologiesCoverage,
  userEnvironment: userEnvironments.CAR,
  timeOfDay: timeOfDay.AFTERNOON,
  polygonPath: [" "],
  locationStats: DEFAULT_LOCATION_STATS,
  ArieCoverageReliability:0,
  ArieCoverageReliabilitySelectedErea:0,
  userPolygonDisplayed: false,
  indoorFloorEnv: 0,
  heatMapPraimeryRating:0,
  applicationLayer: applicationsTypes.VOICE_CALL,
  
};

const mapReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_LINES:
      return {...state, lines: action.linesFlag};
    case SET_COVERAGE:
      return {...state, coverage: Number(action.coverage).toFixed(2)};
    case SET_TOTAL_COST:
      return {...state, totalCost: Number(action.totalCost).toFixed(0)};
    case MAP_LOADED:
      return {...state, loaded: action.loaded != false};
    case SET_POLYGON_PATH:
      return {...state, polygonPath: action.polygonPath};
    case SET_PROJECTS:
      return {...state, projects: action.projects};
    case RESET_PROJECTS:
      return {...state, projects: []};
    case SET_MAP_TYPE:
      return {...state, mapType: action.mapType};
    case SET_MAP_STATE:
      return {...state, mapState: action.mapState};
    case SET_MAP_ZOOM:
      return {...state, mapZoom: action.mapZoom};
    case ADD_PROJECT:
      return {...state, projects: [...state.projects, action.project]};
    case SET_DISPLAYED_ANTENNAS:
      return {...state, displayedAntennas: action.displayedAntennas};
    case UPDATE_DISPLAYED_ANTENNAS:
      return {...state, displayedAntennas: updateDisplayedAntennas(state, action.displayedAntennas)};
    case SET_LOCATION_STATS:
      return {...state, locationStats: action.locationStats};
    case SET_HIGHLIGHT_OPACITY:
      return {...state, highlightOpacity: action.highlightOpacity};
    case SET_PREDICTION_STATE:
      return {...state, predictionState: action.predictionState};
    case SET_USER_ENVIRONMENT:
      return {...state, userEnvironment: action.userEnvironment};
    case SET_TIME_OF_DAY:
      return {...state, timeOfDay: action.timeOfDay};
    case SET_TECHNOLOGIES_COVERAGE:
      return {...state, technologiesCoverage: action.technologiesCoverage};
    case SET_AVERAGE_SIGNAL:
      return {...state, averageSignal: action.averageSignal};
    case SET_USER_PREFERENCES:
      return {...state, userPreferences: action.userPreferences};
    case SET_PLACEMENTS:
      return {...state, placements: action.placements};
    case SET_PLACEMENTS_FOR_CHARTS:
        return {...state, placementsForCharts: action.placements};  
    case SET_CHARTS_STATISTICS:
      return { ...state, chartsStatistics: action.chartsStatistics };
    case REPORT_DOCUMENT_ID:
      return { ...state, reportDocumentId: action.reportDocumentId };
    case PUSH_TO_PLACEMENTS:
      return {...state, placements: [...state.placements, ...action.placements]};
    case ARIE_COVERAGE_RELIABILITY:
      return {...state, ArieCoverageReliability: action.ArieCoverageReliability};
    case ARIE_COVERAGE_RELIABILITY_SELECTED_EREA:
      return {...state, ArieCoverageReliabilitySelectedErea: action.ArieCoverageReliabilitySelectedErea};
    case TOGGLE_USER_POLYGON_DISPLAYED:  
       return {...state, userPolygonDisplayed: !state.userPolygonDisplayed};
    case SET_INDOOR_FLOOR_ENV:
       return {...state, indoorFloorEnv: action.indoorFloorEnv};
    case SET_HEAT_MAP_PRAIMERY_RATING:
       return {...state, heatMapPraimeryRating: action.heatMapPraimeryRating};
    case SET_APPLICATION_LAYER:
      return {...state, applicationLayer: action.applicationLayer};
    default:
      return state;
  }
};

export default mapReducer;
