import { useEffect, useState } from "react";
import Api from "../controller/ApiManager";

export function useFetchCities() {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await Api.City.getCities()
        setCities(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCities()
      return ()=>setCities([]);

  }, []);
  return cities;

}