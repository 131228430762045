import React from "react";
import DropdownItem from "./..//Common/Dropdown/DropdownItem";
import StarsBarInfo from "./../Common/StarsBarInfo";
import { between } from "../../controller/Constants";

export function getRatingInfo(dataValueToListItemdata, value) {
    const coverage = dataValueToListItemdata.find(({ range }) => between(value, range.max, range.min))
    return {
        label: coverage ? coverage.label : '',
        color: coverage ? coverage.color : '',
        starRate: coverage ? coverage.starRate : 0,
    };
}


function RatingList({ dataArray, showIcons, dataValueToListItemdata, onClick, isSelected }) {

    return (
        dataArray.map((data) => {
            const { label, color, starRate } = getRatingInfo(dataValueToListItemdata, data.value)
            return (
                <DropdownItem onClick={onClick} value={data.name} isSelected={isSelected === data.name} title={data.label} icon={showIcons ? <i className={"fa fa-cog nav-icon"} /> : ''} content={<StarsBarInfo showPercentage label={label} color={color} starRate={(starRate)} percentage={data.value} />} />
            );
        })
    );
}

export default RatingList;