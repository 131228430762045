import Constants from "../Constants";
import { errors } from "../Constants";
import { fetchAuth, handleError, navigateResponse } from "./helper";

export const Provider = class {
  static async getProviders(providersIdArray) {
    try {
      const url = new URL(`${Constants.REST_URL}providers`);
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({ providers: providersIdArray }),
      });
      const projectResult = await navigateResponse(res);
      return projectResult;
    } catch (e) {
      handleError(e);
    }
  }
  static async getAllProviders() {
    try {
      const res = await fetchAuth(`${Constants.REST_URL}providers`, {
        method: "GET",
      });
      return await navigateResponse(res);
    } catch (e) {
      handleError(e);
    }
  }


};