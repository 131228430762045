import React, { useRef, useEffect, useState, useMemo } from "react";
import { Link, useHistory, useLocation, matchPath } from "react-router-dom";
import logo1 from "../images/logo/logo-13.png";
import logo2 from "../images/logo/logo-14.png";
import { connect } from "react-redux";
import { SET_CURRENT_DIALOG } from "../store/actionTypes";
import Constants, { mapLayerTypes, mapLayerValues, featuresPermissions } from "../controller/Constants";
import { modals } from "../controller/Constants";
import useProject, { useProjectParams, useSetProject, useIsFeaturePermitted } from "../Hooks/useProject";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import DropdownItem from "./Common/Dropdown/DropdownItem";
import { resetProject } from "../store/actionCreators/general";
import { resetProjects } from "../store/actionCreators/map";
import Api, { UserApi } from "../controller/ApiManager";
import { A, Div, NavbarHeader } from "./NavbarStyles";
import { useUser, useUserGroups, useShowChartsByPolygonActions } from "../Hooks/common";
import { useUserPreferencesState, useUserPreferencesActions } from "../Hooks/useUserPreferences";
import NavbarRating from './NavbarRating'
import { useLocationStatsState } from "../Hooks/map"
import { DEFAULT_LOCATION_STATS } from "../store/defaultValues/map"
import PfofileButton from "../components/PfofileButton";
const { REAL_TIME, HEAT_MAP, CELLULAR, BEST_SERVER, IOT, SITE_PLANING } = mapLayerTypes;

function Navbar({ dispatch, lines, projects, ...props }) {
  const project = useProject();
  const setProject = useSetProject();
  const [userPreferences, setUserPreferences] = useUserPreferencesState();

  const history = useHistory();
  const { clearUserPreferences, updateUserData } = useUserPreferencesActions();
  const projectParams = useProjectParams();
  const { isAuthenticated, user } = useUser();
  const userGroups = useUserGroups();
  const isAdmin = userGroups?.includes(Constants.userGroups.ADMIN);
  const location = useLocation();
  const isMatchingCoverageMapUrl = matchPath(location.pathname, { path: "/projects/:projectId/coverage-map" });
  const [, setLocationStats] = useLocationStatsState();
  const isFeaturePermitted = useIsFeaturePermitted();
  const { ShowChartsByPolygon, setShowChartsByPolgon } = useShowChartsByPolygonActions()
  const permissions = useMemo(() => ({
    CELLULAR_MAP_IS_PERMITTED: isFeaturePermitted(featuresPermissions.CELLULAR_MAP),
    IOT_MAP_IS_PERMITTED: isFeaturePermitted(featuresPermissions.IOT_MAP),
    GREEN_CITY_MAP_IS_PERMITTED: isFeaturePermitted(featuresPermissions.GREEN_CITY_MAP),
    SITE_PLANNING_MAP_IS_PERMITTED: isFeaturePermitted(featuresPermissions.SITE_PLANNING_MAP),
  }), [project])

  function openDialog(dialogName) {
    dispatch({
      type: SET_CURRENT_DIALOG,
      dialog: dialogName,
    });
  }

  function openAddProjectDialog(ev) {
    openDialog(modals.ADD_PROJECT);
  }
  function openAddSensors(ev) {
    openDialog(modals.ADD_SENSORS);
  }

  function deleteSensors(ev) {
    openDialog(modals.DELETE_SENSORS);
  }

  function resetBins() {
    clearUserPreferences();
  }

  function handleSetCurrentMapLayer(mapLayer) {
    let newUserPreferences = { ...userPreferences, providers: [], bestProvider: false, currentMapLayer: mapLayer, currentAntennaId: null };

    // if (userPreferences.currentMapLayer === BEST_SERVER && mapLayer !== BEST_SERVER) {
    //   newUserPreferences = { ...userPreferences, providers: [getFirstProvider()], currentMapLayer: mapLayer };
    // }

    // if (mapLayer === BEST_SERVER) {
    //   newUserPreferences = { ...userPreferences, providers: getAllProviders(), currentMapLayer: BEST_SERVER };
    // }
    setUserPreferences(newUserPreferences);
  }

  async function toggleRealTimePropOfCurrentMapLayer() {
    if (typeof projectParams.isRealTime == "undefined")
      throw Error("The field 'isRealTime' is not yet implemented for this currentMapLayer.projectParams");
    const newProjectParams = { ...projectParams, isRealTime: !projectParams.isRealTime };
    const newMapLayersParams = project.mapLayersParams.map((layerParams) => {
      if (layerParams.mapLayer === userPreferences.currentMapLayer) {
        return { ...layerParams, projectParams: newProjectParams };
      }
      return layerParams;
    });
    setProject({ ...project, mapLayersParams: newMapLayersParams });
    try {
      const updatedProjectParams = await Api.ProjectParams.editProjectParams(project._id, newProjectParams);
    } catch (err) {
      console.log("There was a problem updating ProjectParams:");
      console.log(err);
    }
  }

  function showProjects() {
    openDialog(modals.PROJECT_LIST);
  }

  function openEditProjectDialog() {
    openDialog(modals.EDIT_PROJECT);
  }

  function updateData() {
    updateUserData();
  }

  function openLoginDialog() {
    openDialog(modals.LOGIN);
  }
  const cleanReduxBeforeLogout = () => {
    setLocationStats(DEFAULT_LOCATION_STATS)
  }

  function handleReportCharts() {
    history.push(`/projects/${project._id}/charts`)
  }

  function handleShowChrtsByPolygon() {
    if (userPreferences.currentMapLayer === CELLULAR)
      setShowChartsByPolgon(!ShowChartsByPolygon)
  }

  function OpenCreateUserDialog() {
    openDialog(modals.CREATE_USER);
  }

  const onClickCoverageMap = (mapLayer) => {
    handleSetCurrentMapLayer(mapLayer);

    history.push(`/projects/${project._id}/coverage-map`);
  }
  const onClickIOT = (mapLayer) => {
    // setPredictionState(false)
    handleSetCurrentMapLayer(mapLayer);

    // history.push(`/projects/${project._id}/iot`);
  }

  return (
    <NavbarHeader id="page-header">
      <nav>
        <div className="left">
          <div className="secondary-nav">
            {!isAuthenticated ? <a onClick={openLoginDialog}>Sign In</a> : <PfofileButton />}
          </div>
          {isAuthenticated && user.isTermsChecked && (
            <>
              <div className="primary-nav has-mega-menu">
                <ul className="navigation">
                  <NavbarDropdown title={"Projects"} onClick={showProjects}>
                    {
                      isAdmin && (
                        <>
                          <DropdownItem
                            title={
                              <>
                                <i className="fa fa-sitemap nav-icon" />
                                <span>{"Add Project"}</span>
                              </>
                            }
                            onClick={openAddProjectDialog}
                          />
                          <DropdownItem
                            title={
                              <>
                                <i class="fa fa-solid fa-pencil nav-icon"></i>
                                <span>Edit Project</span>
                              </>
                            }
                            onClick={openEditProjectDialog}
                          />
                          <DropdownItem title="Create User" onClick={OpenCreateUserDialog} icon={<i className="fa fa-solid fa-user-plus nav-icon" />} />
                        </>
                      )
                    }
                    <DropdownItem title={"List of projects"} icon={<i className="fa fa-list-ul nav-icon" />}>
                      {projects.map((project) => (
                        <li key={project._id}>
                          <Link to={`/projects/${project._id}/coverage-map`} className={`add-listing`}>
                            <i className="fa fa-map-marker nav-icon" />
                            <span>
                              {project.name
                                .split(" ")
                                .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
                                .join(" ")}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </DropdownItem>
                  </NavbarDropdown>
                  <NavbarDropdown title="Controls">
                    <DropdownItem title="Settings" icon={<i className={"fa fa-cog nav-icon"} />}>
                      <DropdownItem title="update_data" onClick={updateData} />
                    </DropdownItem>
                    <DropdownItem
                      title="Reset Sites"
                      icon={<i className="fa fa-window-close-o nav-icon" />}
                      onClick={() => resetBins()}
                    />
                  </NavbarDropdown>
                </ul>
              </div>
              {true && (
                <>
                  <div className="third-nav">
                    <A
                      className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${permissions.IOT_MAP_IS_PERMITTED ? `` : `a-disabled`}`}
                      active={userPreferences.currentMapLayer === IOT}
                      onClick={() => onClickIOT(IOT)}
                    >
                      <i className="fa-solid fa-sitemap" />
                      <span>{"IoT"}</span>
                    </A>
                    <A
                      className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${permissions.CELLULAR_MAP_IS_PERMITTED ? `` : `a-disabled`}`}
                      active={isMatchingCoverageMapUrl && userPreferences.currentMapLayer === CELLULAR}
                      onClick={() => onClickCoverageMap(CELLULAR)}

                    >
                      <i className="fa fa-wifi cellular-icon" />
                      <span>{"Cellular Map"}</span>
                    </A>
                    <A
                      className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${permissions.GREEN_CITY_MAP_IS_PERMITTED ? `` : `a-disabled`}`}
                      active={isMatchingCoverageMapUrl && userPreferences.currentMapLayer === HEAT_MAP}
                      onClick={() => onClickCoverageMap(HEAT_MAP)}

                    >
                      <i className="fa fa-solid fa-leaf" />
                      <span>{"Green City Map"}</span>
                    </A>
                    <A
                      className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${permissions.SITE_PLANNING_MAP_IS_PERMITTED ? `` : `a-disabled`}`}
                      active={isMatchingCoverageMapUrl && userPreferences.currentMapLayer === SITE_PLANING}
                      onClick={() => onClickCoverageMap(SITE_PLANING)}
                    >
                      <i className="fa fa-solid fa-pen-ruler" />
                      <span>{"Sites Planning"}</span>
                    </A>
                  </div>
                </>
              )}
              {
                !(userPreferences?.signalsType?.[0] === "rsrq" || userPreferences?.signalsType?.[0] === "snr" || userPreferences?.signalsType?.[0] === "ecno" ) &&
                <NavbarRating />
              }
            </>
          )}
        </div>
        <div className="right">
          <div className="third-nav">
            <A
              className={userPreferences.currentMapLayer === CELLULAR ? "btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                : "btn btn-outline-secondary btn-small btn-rounded icon add-listing a-disabled"
              }
              onClick={() => handleShowChrtsByPolygon()}
              active={ShowChartsByPolygon && userPreferences.currentMapLayer === CELLULAR}  >
              <i class="fa-solid fa-chart-simple"></i>
              <span>{"Statistics"}</span>
            </A>
            <A
              className={`btn btn-outline-secondary btn-small btn-rounded icon add-listing ${permissions.CELLULAR_MAP_IS_PERMITTED ? `` : `a-disabled`}`}
              onClick={() => handleReportCharts()}
              active={matchPath(location.pathname, { path: "/projects/:projectId/charts" })} >
              <i class="fa-solid fa-file-invoice"></i>
              <span>{"Report"}</span>
            </A>
          </div>
        </div>
        <div className="primary-nav has-mega-menu">
          <ul className="navigation">
            <NavbarDropdown title={"Tools"} >
              <DropdownItem
                title={
                  <>
                    <i className="fa fa-map-pin nav-icon" /> <span>{"Upload IoT"}</span>
                  </>
                }
                onClick={openAddSensors}
              />
              <DropdownItem
                title={
                  <>
                    <i class="fa-regular fa-trash-can"></i> <span>{"delete sensors"}</span>
                  </>
                }
                onClick={deleteSensors}
              />
            </NavbarDropdown>
          </ul>
        </div>
        <div className="right">
          <a href="/" className="brand">
            <img className="logo-icon" src={logo1} alt="flycomm icon" />
            <img className="logo-text" src={logo2} alt="flycomm text" />
          </a>
        </div>
      </nav>
    </NavbarHeader>
  );
}

function mapStateToProps(reduxState) {
  return {
    lines: reduxState.map.lines,
    user: reduxState.user,
    projects: reduxState.map.projects,
  };
}

export default connect(mapStateToProps)(Navbar);