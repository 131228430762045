import { useState } from "react";
import { reDrawStyle, toggleProperty } from "./utils";

const IN_EDIT_MODE = "inEditMode";
const DISABLED = "disabled";

// TODO:take out userPreferences

export const useActiveSite = (dataLayer, userPreferences, baseStyleConfig) => {
    const [activeSite, setActiveSite] = useState(null);

    const clearActiveSite = () => {
        if (!activeSite) return;
        const siteFeature = dataLayer.getFeatureById(activeSite.id);
        siteFeature.setProperty(IN_EDIT_MODE, false);
        // toggleProperty(siteFeature, IN_EDIT_MODE);
        reDrawStyle(dataLayer);
        setActiveSite(null);
    }
    const toggledDisableOfActiveSite = () => {
        if (!activeSite) return;
        const siteFeature = dataLayer.getFeatureById(activeSite.id);
        toggleProperty(siteFeature, DISABLED);
        reDrawStyle(dataLayer, baseStyleConfig);
        const site = {
            id: activeSite.id,
            disabled: !activeSite.disabled
        }
        setActiveSite(site);
        userPreferences.currentAntennaId = null;
    }

    return {
        clearActiveSite,
        setActiveSite,
        toggledDisableOfActiveSite,
        activeSite
    }
}