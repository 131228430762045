import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { POLYGON_BLUE } from "../../../../controller/Constants";

const Div = styled.div`
  position: absolute;
  bottom: 70px;
  right: 50%;
  transform: translateX(50%);
  text-align: center;
`;

const ControlButton = styled(Button)`
  transition: 0s;
  background-color: #ffffff;
  background-color: ${({ isActive }) => isActive ? POLYGON_BLUE : "#ffffff"};
  // color: ${({ isActive }) => isActive ? POLYGON_BLUE : "#ffffff"};
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  i {
    transition: 0s;
    font-weight: bold;
  }
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;


function PolygonControls({ handleReset, handleDelete, inEditMode, toggleEditMode, polygonFileManagerRef }) {

  return (
    <Div>
      <ButtonGroup aria-label="Basic example">
        {inEditMode &&
          <>
            <ControlButton onClick={toggleEditMode} variant="primary" size="lg">
              {`Done`}
            </ControlButton>
            <ControlButton onClick={handleReset} variant="primary" size="lg">
              {"Reset"}
            </ControlButton>
            <ControlButton onClick={handleDelete} variant="primary" size="lg">
              {"Delete"}
            </ControlButton>
            <ControlButton onClick={() => { polygonFileManagerRef.current.downloadFile() }} variant="primary" size="lg">
              {"Save Polygon"}
            </ControlButton>
            <ControlButton onClick={() => polygonFileManagerRef.current.uploadFile()} variant="primary" size="lg">
              {"Upload Polygon"}
            </ControlButton>
          </>

        }

      </ButtonGroup>
    </Div>

  );
}


export default PolygonControls

// import React, { useCallback, useEffect, useRef } from "react";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import Button from "react-bootstrap/Button";
// import { useTranslation } from "react-i18next";
// import { setMapState } from "../../store/actionCreators/map";
// import Constants, { POLYGON_BLUE } from "../../controller/Constants";
// import { events } from "../../controller/Constants";
// import { connect } from "react-redux";
// import EventEmitter from "../../controller/EventEmitter";
// import PolygonFileManager2 from "../Common/PolygonFileManager2";

// function AreaSelectionControls({ mapState, setMapState, resetPolygon, deletePolygon, setPolygonPath, polygonPath, projectName, togglePolygonMode }) {
//     const configFileManagerRef = useRef(null);

//     function savePolygon() {
//         // todo: future upsdate... add undo changes. and function should be acutally saving the state.
//         togglePolygonMode()
//     }

//     function handleDeletePolygon() {
//         deletePolygon();
//         EventEmitter.dispatch(events.DELETE_POLYGON);
//         setMapState(Constants.mapStates.SITES_COVERAGE);
//     }

//     function handleResetPolygon() {
//         resetPolygon();
//     }

//     return (
//         <Div>
//             <ButtonGroup aria-label="Basic example">
//                 {/*<ControlButton onClick={()=>{}} variant="primary" size="lg">{t('Cancel_asp')}</ControlButton>*/}
//                 <ControlButton isActive={mapState === Constants.mapStates.AREA_SELECTION} onClick={savePolygon} variant="primary" size="lg">
//                     {t("Save_asp")}
//                 </ControlButton>
//                 <ControlButton onClick={handleResetPolygon} variant="primary" size="lg">
//                     {t("Reset_asp")}
//                 </ControlButton>
//                 <ControlButton onClick={handleDeletePolygon} variant="primary" size="lg">
//                     {t("Delete_asp")}
//                 </ControlButton>
//                 <ControlButton onClick={() => { console.log("Export_asp: : : : : " + polygonPath.length); configFileManagerRef.current.downloadFile() }} variant="primary" size="lg">
//                     {t("Export_asp")}
//                 </ControlButton>
//                 <ControlButton onClick={() => configFileManagerRef.current.uploadFile()} variant="primary" size="lg">
//                     {t("Upload_asp")}
//                 </ControlButton>
//                 <PolygonFileManager2
//                     ref={configFileManagerRef}
//                     // polygonPath={[]}
//                     polygonPath={polygonPath}
//                     setPolygonPath={setPolygonPath}
//                     projectName={projectName}
//                 />
//             </ButtonGroup>
//         </Div>
//     );

// }
// function mapStateToProps(reduxState) {
//     return {
//         mapState: reduxState.map.mapState,
//     };
// }
// export default connect(mapStateToProps, { setMapState })(AreaSelectionControls);

