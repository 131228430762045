import React ,{useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {UserApi} from "../../controller/ApiManager/index";

function Register({}){
    const [inputs,setInputs] = useState({
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        passwordConfirm:'',
    });
    const {firstName, lastName, email, password, passwordConfirm} = inputs;
    async function handleSubmit(ev) {
        ev.preventDefault();
        if(password === passwordConfirm) {
            const registerRes = await UserApi.register({email,password});
            console.log('registerRes: ', registerRes);
        }
    }

    return(<div className="modal-content">
                    <Modal.Header>
                        {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span*/}
                            {/*aria-hidden="true">&times;</span></button>*/}
                        <div className="section-title">
                            <h2>{'Sign Up'}</h2>
                        </div>
                    </Modal.Header>
                    <div className="modal-body">
                        <form className="form inputs-underline" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="first_name">First Name</label>
                                        <input type="text"
                                               className="form-control"
                                               name="first_name"
                                               placeholder="First name"
                                               value={firstName}
                                               onChange={(ev)=>setInputs({...inputs,firstName:ev.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="last_name">Last Name</label>
                                        <input type="text"
                                               className="form-control"
                                               name="last_name"
                                               placeholder="Last name"
                                               value={lastName}
                                               onChange={(ev)=>setInputs({...inputs,lastName:ev.target.value})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" name="email" id="email"
                                       placeholder="Email"
                                       value={email}
                                       onChange={(ev)=>setInputs({...inputs,email:ev.target.value})}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" className="form-control" name="password" id="password"
                                       placeholder="Password"
                                       value={password}
                                       onChange={(ev)=>setInputs({...inputs,password:ev.target.value})}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <input type="password" className="form-control" name="confirm_password"
                                       id="confirm_password" placeholder="Confirm Password"
                                       value={passwordConfirm}
                                       onChange={(ev)=>setInputs({...inputs,passwordConfirm:ev.target.value})}/>
                            </div>
                            <div className="form-group center">
                                <button type="submit" className="btn btn-primary width-100">Register Now</button>
                            </div>
                        </form>
                        <hr/>
                        <p className="center note">By clicking on “Register Now” button you are accepting the Terms & Conditions</p>
                    </div>
                </div>)
}

export default Register;