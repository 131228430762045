import Constants, { errors } from "../Constants";
import Validator from "../Validator";
import { fetchAuth, handleError, navigateResponse } from "./helper";
import { Project } from "./Project";
import { Sensors } from "./sensors";
import { SitePlanning } from "./site_planning";
import { ProjectParams } from "./ProjectParams";
import { UserPreferences } from "./UserPreferences";
import { City } from "./City";
import { Provider } from "./Provider";
import { allSamples } from "./testSampels2";
import { mockProject } from "./mockProject_old";
import {
  Cellcom3GMock,
  Cellcom4GMock,
  Pelephone4GMock,
  Pelephone3GMock,
  PHI3GMock,
  PHI4GMock,
} from "./mocks/placements";
import { listOf25KBinsConst } from "../../components/Map/mapConstants";
import { isPointInPolygon } from "../GEOoperations";
const placements = {
  success: true,
  binsPlacements: [],
};
const cellcomP = {
  binResolution: 264,
  mapLayer: "cellular",
  signalType: "rsrp",
  binReliability: 50,
  dateRange: {
    from: "2022-11-01,00:00:00.000000",
    to: "2022-11-30,23:59:59.999999",
    dateType: "date",
    date: "2022-11",
  },
  technology: "4G",
  provider: "cellcom",
  bins: [
    {
      location: {
        lat: 31.76626670987967,
        lng: 35.193657835449685,
      },
      signal: -79,
    },
    {
      location: {
        lat: 31.76626670987967,
        lng: 35.19603137501033,
      },
      signal: -78,
    },
  ],
  algorithm: "static-clustering",
};
const partnerP = {
  binResolution: 264,
  mapLayer: "cellular",
  signalType: "rssi",
  binReliability: 50,
  dateRange: {
    from: "2022-11-01,00:00:00.000000",
    to: "2022-11-30,23:59:59.999999",
    dateType: "date",
    date: "2022-11",
  },
  technology: "4G",
  provider: "partner",
  bins: [
    {
      location: {
        lat: 31.76626670987967,
        lng: 35.193657835449685,
      },
      signal: -75,
    },

    {
      location: {
        lat: 31.801869803289332,
        lng: 35.25536986402643,
      },
      signal: -75,
    },
  ],
  algorithm: "static-clustering",
};
const pelephonP = {
  binResolution: 264,
  mapLayer: "cellular",
  signalType: "rssi",
  binReliability: 50,
  dateRange: {
    from: "2022-11-01,00:00:00.000000",
    to: "2022-11-30,23:59:59.999999",
    dateType: "date",
    date: "2022-11",
  },
  technology: "4G",
  provider: "pelephone",
  bins: [
    {
      location: {
        lat: 31.76626670987967,
        lng: 35.193657835449685,
      },
      signal: -75,
    },

    {
      location: {
        lat: 31.801869803289332,
        lng: 35.25536986402643,
      },
      signal: -75,
    },
  ],
  algorithm: "static-clustering",
};

function filterByPolygon(bins, polygon) {
  if (polygon.length > 2) {
    return bins.filter((bin) => isPointInPolygon([bin.lat, bin.lng], polygon));
  }
  return bins;
}

const getColor = (signal) => {
  if (signal > -80) return "#00b14f";
  if (signal > -90) return "#94c954";
  if (signal > -105) return "#f6eb14";
  if (signal > -120) return "#fec00f";
  return "#ed2024";
};

function getDateRangePatch(relativeDateRange) {
  const getYear = (date) => {
    return date.split("-")[0];
  };
  const getMonth = (date) => {
    return date.split("-")[1];
  };
  const dayInMS = 1000 * 60 * 60 * 24;
  let dateRange;

  // finding the local time zone offset from UTC ii milliseconds
  var date = new Date();
  var timezoneOffsetInMinutes = date.getTimezoneOffset();
  var offsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000 * -1;

  switch (relativeDateRange.dateType) {
    case "month":
      dateRange = {
        from: new Date(Date.now() - 30 * dayInMS).valueOf(),
        to: Date.now().valueOf(),
      };
      break;
    case "week":
      dateRange = {
        from: new Date(Date.now() - 7 * dayInMS).valueOf(),
        to: Date.now().valueOf(),
      };
      break;
    case "day":
      dateRange = {
        from: new Date(Date.now() - 1 * dayInMS).valueOf(),
        to: Date.now().valueOf(),
      };
      break;
    case "date":
      if (!relativeDateRange.date) {
        dateRange = {
          from: 0,
          to: 0,
        };
      } else {
        const selectedMonth = getMonth(relativeDateRange.date);
        const selectedYear = getYear(relativeDateRange.date);
        dateRange = {
          from:
            new Date(selectedYear, selectedMonth - 1, 1).valueOf() +
            offsetInMilliseconds,
          to:
            new Date(selectedYear, selectedMonth, 1).valueOf() +
            offsetInMilliseconds,
        };
      }
      break;

    default:
      dateRange = {
        from: String(new Date().valueOf()),
        to: String(new Date().valueOf()),
      };
      break;
  }
  return {
    from: Number(dateRange.from),
    to: Number(dateRange.to),
  };
}

class Api {
  static Project = Project;
  static ProjectParams = ProjectParams;
  static UserPreferences = UserPreferences;
  static Sensors = Sensors;
  static SitePlanning = SitePlanning;
  static City = City;
  static Provider = Provider;

  static async getRealTimeCoverage(
    projectId,
    userPreferences,
    algorithm,
    windowViewPolygon,
    editAntennaSites
  ) {
    const navigateRealTimeCoverageResponse = async (res) => {
      let err;
      console.log("res", res);

      let responseBody = await res.json();
      if (res.ok) {
        return { responseBody, err };
      }
      console.log("Error response: ", res);
      const currentError =
        "there were problem with the server response, status: " +
        res.status +
        "\nErrorMessage: " +
        responseBody;
      console.log(currentError);
      if (Number(res.status) === 504 || responseBody.includes("MemoryError")) {
        return { responseBody: null, err: errors.PREDICTION_TOO_LARGE };
      }
      throw Error(currentError);
    };

    try {
      // let placementsCopy = JSON.parse(JSON.stringify(placements))
      // if (userPreferences.providers.includes("partner")) placementsCopy.binsPlacements.push(partnerP)
      // if (userPreferences.providers.includes("cellcom")) placementsCopy.binsPlacements.push(cellcomP)
      // if (userPreferences.providers.includes("pelephone")) placementsCopy.binsPlacements.push(pelephonP)
      // return Promise.resolve(placementsCopy);

      // ///////
      const url = new URL(
        `${Constants.REST_URL}projects/${projectId}/realtime-cluster`
      );
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({
          windowViewPolygon,
          editAntennaSites,
          requestPreferences: {
            ...userPreferences,
            dateRange: getDateRangePatch(userPreferences.relativeDateRange),
          },
          algorithm,
          polygonSource: "request-preferences",
        }),
      });

      const { responseBody, err } = await navigateRealTimeCoverageResponse(res);
      if (responseBody) {
        return { ...responseBody };
      } else if (err === errors.PREDICTION_TOO_LARGE) {
        return err;
      }
      // await navigateResponse(res);
    } catch (e) {
      handleError(e);
    }
  }

  static async uploadExternalDataFile(formDataFile, fileName) {
    try {
      const url = new URL(
        `${Constants.REST_URL}process-external-data-file/${fileName}`
      );
      const res = await fetchAuth(url, {
        method: "POST",
        body: formDataFile,
      });
      if (!res.ok) {
        console.log(res);
        throw Error(`there were problem uploading file.`);
      }
    } catch (e) {
      handleError(e);
    }
    // try {
    //   const res = {
    //     "AT&T-LTE M":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"Yes"},{lat: 31.779754, lng:35.179432, status:"Yes"}],
    //       coverageReliability: 1
    //     },
    //     "Verison-LTE M":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"No"},{lat: 31.779754, lng:35.179432, status:"Yes"}],
    //       coverageReliability: 2
    //     },
    //     "Verison-NB-IoT":{
    //       bins: [{lat: 31.777055, lng: 35.168172, status:"No"},{lat: 31.779754, lng:35.179432, status:"No"}],
    //       coverageReliability: 3
    //     },
    //   }

    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve(res);
    //     }, 3000);
    //   });
    // } catch (e) {
    //   handleError(e);
    // }
  }
  static async processExternalDataFile(file64) {
    try {
      const url = new URL(
        `${Constants.REST_URL}projects/1234567/process-external-data-file`
      );
      const res = await fetchAuth(url, {
        method: "POST",
        body: file64,
      });

      const projectResult = await navigateResponse(res);
      return projectResult;
    } catch (e) {
      handleError(e);
    }
  }

  static async getCoverage(
    projectId,
    userPreferences,
    algorithm,
    windowViewPolygon
  ) {
    try {
      const url = new URL(
        `${Constants.REST_URL}projects/${projectId}/get-coverage`
      );
      const res = await fetchAuth(url, {
        method: "POST",
        body: JSON.stringify({
          requestPreferences: userPreferences,
          algorithm,
          windowViewPolygon,
        }),
      });
      const projectResult = await navigateResponse(res);
      return projectResult;
    } catch (e) {
      handleError(e);
    }
  }

  static async getCoverage_mock(
    projectId,
    userPreferences,
    algorithm,
    windowViewPolygon
  ) {
    const list = filterByPolygon(listOf25KBinsConst, windowViewPolygon);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          list.map((bin) => ({
            color: getColor(bin.signal),
            location: { lat: bin.lat, lng: bin.lng },
          }))
        );
      }, 2000);
    });
    // try catch
  }

  static async uploadImage(image) {
    if (image.size > 1000000)
      throw Error("It is not possible to upload an image above 10 MB");
    return await fetchAuth(`${Constants.REST_URL}uploadimage/${image.name}`, {
      method: "POST",
      body: image,
    })
      .then((data) => {
        return data;
      })
      .catch(handleError);
  }
}

class UserApi {
  static login({ email, password }) {
    const emailValid = Validator.isEmail(email);
    const passwordValid = password.length >= 6;
    if (emailValid && passwordValid)
      return fetch(`${Constants.AUTH_URL}login`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
      })
        .then(navigateResponse)
        .catch(handleError);
    else if (!emailValid && !passwordValid) {
      throw Error(`invalid email and password`);
    } else if (!emailValid && passwordValid) {
      throw Error(`invalid email`);
    } else if (emailValid && !passwordValid) {
      throw Error(`invalid password`);
    }
  }

  static register({ email, password, cityId }) {
    const emailValid = Validator.isEmail(email);
    const passwordValid = password.length >= 6;
    if (emailValid && passwordValid)
      return fetch(`${Constants.AUTH_URL}register`, {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          cityId,
          secret: "flytech57",
          group: "manager",
        }),
      })
        .then(navigateResponse)
        .catch(handleError);
    else if (!emailValid && !passwordValid) {
      throw Error(`invalid email and password`);
    } else if (!emailValid && passwordValid) {
      throw Error(`invalid email`);
    } else if (emailValid && !passwordValid) {
      throw Error(`invalid password`);
    }
  }

  static refreshToken({ email, refreshToken }) {
    return fetch(Constants.AUTH_URL + "refreshToken", {
      method: "POST",
      body: JSON.stringify({ email, refreshToken }),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static logout(email) {
    return fetch(Constants.AUTH_URL + "logout", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(navigateResponse)
      .catch(handleError);
  }

  static changeAttributes(attributes) {
    return fetchAuth(Constants.AUTH_URL + "updateAttributes", {
      method: "POST",
      body: JSON.stringify(attributes),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
  static changePassword(data) {
    return fetch(Constants.AUTH_URL + "password", {
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then(navigateResponse)
      .catch(handleError);
  }
}

export default Api;
export { UserApi };
