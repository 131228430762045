import React from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { mapLoadedCreator } from "../../store/actionCreators/map";
import { connect } from 'react-redux';
import Constants from '../../controller/Constants'


const INTERVAL_ = 1000;
function predectMock(poli) {
  if (!poli) return;
  var bounds = new window.google.maps.LatLngBounds();
  var list = [];

  for (var i = 0; i < poli.length; i++) {
    bounds.extend(poli[i]);
  }
  var sw = bounds.getSouthWest();
  var ne = bounds.getNorthEast();
  for (var i = 0; i <= 50000; i++) {
    var ptLat = Math.random() * (ne.lat() - sw.lat()) + sw.lat();
    var ptLng = Math.random() * (ne.lng() - sw.lng()) + sw.lng();
    var point = new window.google.maps.LatLng(ptLat, ptLng);

    // if (google.maps.geometry.poly.containsLocation(point, poli)) {
    list.push({ location: { lat: Number(point.lat()), lng: Number(point.lng()) } });

    // }
  }

  return list;
}

class MapWrapperVnila extends React.Component {
  constructor(props) {
    super(props);
    this.state = { map: null, circles: [], boundsChangedTimeoutHandler: -1 };

    const loader = new Loader({
      apiKey: Constants.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      // version: "3.141.59",
      libraries: ["places", "geometry"]
      // ...additionalOptions,
    });

    loader.load().then(() => {
      let map = new window.google.maps.Map(document.getElementById("map-container"), {
        center: { "lat": 32.99829934638428, "lng": 34.98174634112939 },
        // center: {lat: 37.341013, lng: -122.037883}, 
        zoom: 10,
        streetViewControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          // position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
      });

      this.props.setMapLoaded()
      window.mainMap = map;




      // map.addListener("bounds_changed", () => { // with debounce !!!!!!!!!!
      //   clearTimeout(this.state.boundsChangedTimeoutHandler);

      //   let boundsChangedTimeoutHandler = window.setTimeout(() => {
      //     this.rearengeBins(); // getReleventBins // ...
      //   }, INTERVAL_);

      //   this.setState({...this.state, boundsChangedTimeoutHandler});
      // });
      map.data.addListener('mouseover', (event) => {
        if (event.feature.getProperty('display')) {
          this.props.setBinDitails(event.feature.getProperty('display'))
        }
      });
      map.data.addListener('mouseout', () => {
        this.props.setBinDitails(null)
      });


      map.addListener("bounds_changed", () => { // with NO! debounce !!!!!!!!!!
        this.rearengeBins();


        // this.setState({...this.state, boundsChangedTimeoutHandler});
      });









      // map.addListener("bounds_changed", () => { "dragstart" "dragend" "zoom_changed"
      //   clearTimeout(this.state.boundsChangedTimeoutHandler);

      //   let boundsChangedTimeoutHandler = window.setTimeout(() => {
      //     this.rearengeBins(); // getReleventBins // ...
      //   }, INTERVAL_);

      //   this.setState({...this.state, boundsChangedTimeoutHandler});
      // });

      this.setState({ ...this.state, map });

      // setTimeout(() => {
      //     console.log("setAllBinsArray2    &&&&&&&&&&&&&&&&&&&&&&&&&&&&  333333  &&&&&&&&&&&&:")
      //     let locations = predectMock([{"lat":33.147732140056654,"lng":38.5452263956505},{"lat":33.147732140056654,"lng":34.1506951456505},{"lat":29.392769542012278,"lng":34.1506951456505},{"lat":29.392769542012278,"lng":38.5452263956505}])
      //     for(let location of locations){
      //         const newCircle = new window.google.maps.Circle({
      //             strokeColor: "#FF0000",
      //             strokeOpacity: 0.8,
      //             strokeWeight: 2,
      //             fillColor: "#94c954",
      //             fillOpacity: 0.35,
      //             map,
      //             center: location.location,
      //             radius: 120,
      //         });
      //     }

      //   }, 10000);
    });
  }

  // static getDerivedStateFromProps(props, state) {}
  rearengeBins() {
    // console.log("rearengeBins")
    // this.props.setMapInfo({bounds: this.state.map.getBounds(), zoom: this.state.map.getZoom()})
    this.props.setMapBounds(this.state.map.getBounds())
    this.props.setMapZoom(this.state.map.getZoom())
    // funcFromDad({bouns: this.state.map.getBounds(), zoom: this.state.map.getZoom()}); //       funcFromDad({bouns:window.mainMap.getBounds()})
    // for circle cluster use "keyForCluster" or "KeyToClusterBy"
    // when component unmount -> clear the listners
  }
  shouldComponentUpdate() {
    return false;
  }
  componentWillUnmount() {
    // this.state.map.clearListeners(this.state.map, 'mouseover');
  }

  componentDidUpdate() {
  }
  componentWillReceiveProps() {
  }
  render() {
    return (
      <div id="map-container" style={{ width: "100%", hight: "100%" }}>
        map-container
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    setMapLoaded: () => dispatch(mapLoadedCreator()),
  };
}

export default connect(null, mapDispatchToProps)(MapWrapperVnila);