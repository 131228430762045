import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./components/Home";
import DialogGenerator from "./components/DialogGenerator";
import styled, { ThemeProvider } from "styled-components";
import AlertGenerator from "./components/AlertsGenerator";
import Application from "./controller";
import { theme } from "./configuations/theme";
import ChartsDashboard from "./components/ChartsAndGraphs/chartsDashboard";
import Settings from './components/pages/Settings'
import { QueryClient, QueryClientProvider } from 'react-query'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const application = new Application();
const queryClient = new QueryClient()

function App() {
  const getProjectId = () => {
    console.log({ "window.location.pathname": window.location.pathname })
    if (window.location.pathname === "/") return "";
    const splited = window.location.pathname.split("/projects/");
    return splited[1].replaceAll("/", "");
  }
  return (
    <Provider store={application.store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <PageWrapper className="page-wrapper-div">
              <Navbar />
              <DialogGenerator />
              <AlertGenerator />
              <Switch>
                <Route path="/projects/:projectId/charts" component={ChartsDashboard} />
                <Route path="/projects/:projectId/settings" component={Settings} />
                <Route path="/projects/:projectId/coverage-map">
                  <Home />
                </Route>
                <Route path="/projects/:projectId">
                  <Redirect to={`/projects/${getProjectId()}/coverage-map`} />
                </Route>
                <Route path="/" component={Home} />
              </Switch>
            </PageWrapper>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
export { application };
