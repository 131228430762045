// src/components/line.rechart.js

import React, { forwardRef } from "react";
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { colorsForCoveragePerformance, providersColors } from "../Constants";

function LinechartComponentMedian({ data, monthsSequence, providers, name }, ref) {

    const dataStruct = [
        // {
        //     month: monthsSequence[0].split("-")[1],
        //     year: monthsSequence[0].split("-")[0]
        //}
    ];
    
    function formatTicks(month) {
        if (month !== undefined) {
            const dataObj =data.find(obj => obj.month === month); //to do: more then 12
            return `${dataObj.month}/${dataObj.year.slice(2, 4)}` ;
        }
    }
    const providersColors = ["#E0B0FF", "#0096FF", "#48D1CC", "#d1cc48"];

    return (
        <div id={name} ref={(element) => ref.current.add(element)} style={{ minWidth: 650, padding: 5 }} >
            {data && <ResponsiveContainer width="100%" height={280} >
                <LineChart width={650} height={300} data={data || []}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    tickCount={"6"}
                    <CartesianGrid strokeDasharray="3 3" vertical horizontal={true} horizontalFill={colorsForCoveragePerformance} fillOpacity={0.3} />
                    <XAxis dataKey={"month"} tickFormatter={(month) => formatTicks(month)} >
                    </XAxis>
                    <YAxis dataKey={"dBm"} label={{ value: '%', position: 'insideLeft', textAnchor: 'middle' }} type="number" ticks={[20, 40, 60, 80, 100]}
                        domain={[0, 100]} />
                    <Tooltip />
                    <Legend iconType="line" />
                    {providers.map((item, index) => (
                        <Line type="monotone" dataKey={providers[index % 3]} strokeOpacity={1} strokeWidth={1.5} stroke={providersColors[index % 3]} />
                    ))}
                </LineChart>
            </ResponsiveContainer>
            }
        </div>
    );
}
export default forwardRef(LinechartComponentMedian);


// ticks={[0, 1, 2, 3, 4 , 5, 6, 7, 8, 9, 10, 11, 12]}
//dataKey={"month"} type="number" 