import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { chartGroup, chartType, coveragePreformanceHistogramdataStructEcno, coveragePreformanceHistogramdataStructRsrp, coveragePreformanceHistogramdataStructRsrq, coveragePreformanceHistogramdataStructSnr, coverageReliabilityThresholds, ecnoDistributionStatisticsRange, rsrpDistributionStatisticsRange, rsrqDistributionStatisticsRange, snrDistributionStatisticsRange } from "../../controller/Constants";
import { colorsForCoveragePerformance, providersColors } from "../ChartsAndGraphs/Constants";
import { useRef } from "react";
import { usePlacementsForChartsActions } from "../../Hooks/common";
import BarsChart from "../Common/barsChart/BarsChart";
import AreasChart from "../Common/areasChart/AreasChart";
import Reliability from "../Common/reliability";
import { getCoveragePreformanceHistogram, getCoveragePreformanceDistribution, getCoverageReliability } from "smart-cities-statistics"
import { Card } from "react-bootstrap"
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";

const ControlContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  margin: 0;
  z-index: 100;
  background: AliceBlue;
`;

function AnalysisCharts() {
  const printRef = useRef(new Set());
  const { placementsForCharts } = usePlacementsForChartsActions()
  const [histogramData, setHistogramData] = useState(null);
  const [distributionData, setDistributionData] = useState(null);
  const [reliabilityData, setReliabilityData] = useState(null);
  const [userPreferences] = useUserPreferencesState();
  const [xaxisLable, setXaxisLable] = useState(null);

  useEffect(() => {
    if (userPreferences?.signalsType?.[0] && (placementsForCharts.length !== 0 || placementsForCharts?.bins?.length > 0)) {
      const currentSignal = userPreferences?.signalsType?.[0];
      let coveragePreformanceHistogramdataStruct;
      let distributionStatisticsRange;
      let Reliabilitythreshold;
      switch (currentSignal) {
        case "rsrp":
          coveragePreformanceHistogramdataStruct = coveragePreformanceHistogramdataStructRsrp;
          distributionStatisticsRange = rsrpDistributionStatisticsRange;
          Reliabilitythreshold = coverageReliabilityThresholds.RSRP;
          setXaxisLable("dBm")
          break;
        case "rsrq":
          coveragePreformanceHistogramdataStruct = coveragePreformanceHistogramdataStructRsrq;
          distributionStatisticsRange = rsrqDistributionStatisticsRange;
          Reliabilitythreshold = coverageReliabilityThresholds.RSRQ;
          setXaxisLable("dB")
          break;
        case "snr":
          coveragePreformanceHistogramdataStruct = coveragePreformanceHistogramdataStructSnr;
          distributionStatisticsRange = snrDistributionStatisticsRange;
          Reliabilitythreshold = coverageReliabilityThresholds.SNR;
          setXaxisLable("dB")
          break;
        case "ecno":
          coveragePreformanceHistogramdataStruct = coveragePreformanceHistogramdataStructEcno;
          distributionStatisticsRange = ecnoDistributionStatisticsRange;
          Reliabilitythreshold = coverageReliabilityThresholds.ECNO;
          setXaxisLable("dB")
          break;
        default:
          break;
      }
      setHistogramData(getCoveragePreformanceHistogram(placementsForCharts.bins, coveragePreformanceHistogramdataStruct))
      setDistributionData(getCoveragePreformanceDistribution([{ placements: placementsForCharts.bins.filter(obj => obj.signal !== null), provider: "signal level" },], distributionStatisticsRange)) //handle bug
      setReliabilityData(getCoverageReliability([{ placements: placementsForCharts.bins, provider: "currentProvider" }], undefined, Reliabilitythreshold))
    }
  }, [placementsForCharts]);

  try {
    return (
      <ControlContainer >
        {reliabilityData &&
          <Card border="Light" style={{ padding: "0px", margin: "5px", marginTop: "5px" }}  >
            <Card.Body style={{ padding: "0px" }} >
              <Reliability width={350} height={10} yaxisKey={"multiple"} xaxisKey={""} domain={""} yaxisLable={""} xaxisLable={""} colors={""} name={`${chartGroup.DYNAMIC_POLYGON}-${chartGroup.COVERAGE_RELIABILITY}`} ref={printRef} data={reliabilityData} />
            </Card.Body>
          </Card>}
        {histogramData && <Card border="Light" style={{ padding: "0px", margin: "5px" }}  >
          <Card.Body style={{ padding: "0px" }}>
            <BarsChart width={340} height={190} yaxisKey={"percent"} xaxisKey={"category"} domain={[0, 100]} yaxisLable={"%"} xaxisLable={xaxisLable} colors={colorsForCoveragePerformance} name={`${chartGroup.DYNAMIC_POLYGON}-${chartGroup.COVERAGE_PERFORMANCE}${chartType.PERFORMANCE}`} ref={printRef} data={histogramData} />
          </Card.Body>
        </Card>
        }
        {distributionData &&
          <Card border="Light" style={{ padding: "0px", margin: "5px" }}  >
            <Card.Body style={{ padding: "0px" }}>
              <AreasChart width={340} height={200} yaxisKey={"signal level"} xaxisKey={"range"} domain={[0, 100]} yaxisLable={"%"} xaxisLable={xaxisLable} colors={providersColors} name={`${chartGroup.DYNAMIC_POLYGON}-${chartGroup.COVERAGE_PERFORMANCE}-${chartType.DISTRIBUTION}`} ref={printRef} data={distributionData} />
            </Card.Body>
          </Card>
        }
      </ControlContainer>
    );
  } catch (e) {
    console.error(e);
    return (
      <ControlContainer>
        <div>there is an error here</div>
      </ControlContainer>
    );
  }
}

export default AnalysisCharts;
