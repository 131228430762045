
import { Col, Row, Container } from "react-bootstrap"
import { chartsTypes } from "./chartsDashboard";
import { PROVIDERS, TECHNOLOGYS } from '../../controller/Constants'


import ApplicationQualityTable from "./tables/ApplicationQualityTable";

function CoverageReliabilityTableArrows({ tendency }) {
    return (<>
      {tendency <= -1
              ? <td  >
                <i  class="fa fa-arrow-down  fa-lg" style={{ color: "red"}} aria-hidden="true"></i>
              </td>
              : <>
                {tendency >= 1
                  ? <td >
                    <i class="fa fa-arrow-up  fa-lg" style={{ color: "mediumseagreen  "}} aria-hidden="true"></i>
                  </td>
                  : <i class="fa fa-arrow-right  fa-lg" style={{verticalAlign:"-75%"}} aria-hidden="true"></i>
                }
              </>
            }
    </>
    );
}
export default CoverageReliabilityTableArrows;

