export const filterControlsConfig = {
  bestserver: {
    providers: false,
    technologies: true,
    dateRange: true,
    environmentFactor: true,
    dayTimeFactor: true,
  },
  heatmap: {
    providers: false,
    technologies: false,
    dateRange: true,
    environmentFactor: true,
    dayTimeFactor: true,
  },
  realtime: {
    providers: true,
    technologies: true,
    dateRange: false,
    environmentFactor: true,
    dayTimeFactor: true,
  },
  standard: {
    providers: true,
    technologies: true,
    dateRange: true,
    environmentFactor: true,
    dayTimeFactor: true,
  },
  cellular: {
    providers: true,
    technologies: true,
    dateRange: true,
    environmentFactor: true,
    dayTimeFactor: true,
    signalsType: true,
    bands: true,
  },
  dateRangeOf24Hours: {
    providers: true,
    technologies: true,
    dateRange: true,
    environmentFactor: true,
    dayTimeFactor: true,
  },
};

// dateRangeFilterTypes = ['day-week-month']

// goodFileName = "displayConfig"
// goodNameForFilterControlsDisplay = "FilterControlsConfig"

// config = mapLayer === "cellular"? filterControlsDisplayConfig[project.mapControlsDisplayType]("standard","uri-shrir"): filterControlsDisplayConfig[mapLayer] ("bestServer","realtime","heatmap")

// admin????????

// mapLayerDisplayConfig = {
//   bestserver: {
//     providers: false,
//     technologies: true,
//      dateRange:true,
//     environmentFactor: true,
//     dayTimeFactor: true,
//   },
//   heatmap:  {
//     providers: false,
//     technologies: false,
//      dateRange:true,
//     environmentFactor: true,
//     dayTimeFactor: true,
//   },
//   realtime:  {
//     providers: true,
//     technologies: true,
//      dateRange:false,
//     environmentFactor: true,
//     dayTimeFactor: true,
//   },
//   standard: {
//     providers: true,
//     technologies: true,
//      dateRange:true,
//     environmentFactor: true,
//     dayTimeFactor: true,
//   },
//   dateRangeOf24Hours: {
//     providers: true,
//     technologies: true,
//      dateRange:true,
//     environmentFactor: true,
//     dayTimeFactor: true,
//   },
// }

// projectStandard = {
//   _id:"234",
//   filterControlsDisplayType:"standard"
// }

// projectUriShrir = {
//   _id:"234",
//   filterControlsDisplayType:"dateRangeOf24Hours"
// }

// //666666666666666666666666666666666666666666

// (heatmap) => (Controls = all), (filter = [env]); // standard heatmap
// (cellular) => (Controls = all), (filter = [prov, tech, (dateRange = standard), env, dayTime]); // standard cellular
// (cellular) => (Controls = all), (filter = [tech, env, dayTime]); // bestServer
// (cellular) => (Controls = all), (filter = [prov, tech, (dateRange = day), env, dayTime]); // Uri Shrir
// // (cellular) => (Controls = all), (filter = [prov, tech, (dateRange = day), env, dayTime]); // realtime

// cellular + bestServer; // bestServer applys only on filterControl component

// heatmap + dateRangeOf24Hours; // bestServer applys only on filterControl component  // for Uri Shrir
// cellular + dateRangeOf24Hours; // bestServer applys only on filterControl component // for Uri Shrir

// // ---

// dateRangeOf24Hours;

// datePickerOfDays; // component to create
// datePickerOfMonths; // component to create

// heatmap + dateRangeOf24Hours;
// heatmap + standard;
// heatmap + technologyOnly;
// cellular + dateRangeOf24Hours;
// cellular + standard;
// cellular + technologyOnly;
// bestserver + dateRangeOf24Hours;
// bestserver + standard;
// bestserver + technologyOnly;
// realtime + dateRangeOf24Hours;
// realtime + standard;
// realtime + technologyOnly;

// const filterControlsDisplayConfig = {
//   standard: {
//     provider: true,
//     technology: true,
//     date: true,
//     "date range": true,
//     "environment factor": true,
//     "day time factor": true,
//   },
//   technologyOnly: {
//     provider: true,
//     technology: true,
//     date: true,
//     "date range": true,
//     "environment factor": true,
//     "day time factor": true,
//   },
//   dateRangeOf24Hours: {
//     provider: true,
//     technology: true,
//     date: true,
//     "date range": true,
//     "environment factor": true,
//     "day time factor": true,
//   },
// };
// export const displayConfig = {
//   heatmap: {
//     MapLayerControls: true,
//     ColorMapping: true,
//     SelectAreaButton: true,
//     AreaSelectionControls: true,
//     HighlightOpacityButtons: true,
//     PredictionSwitch: true,
//     SearchLocation: true,
//     FilterControls: {
//       standard: {
//         provider: true,
//         technology: true,
//         date: true,
//         "date range": true,
//         "environment factor": true,
//         "day time factor": true,
//       },
//       technologyOnly: {
//         provider: true,
//         technology: true,
//         date: true,
//         "date range": true,
//         "environment factor": true,
//         "day time factor": true,
//       },
//     },
//   },
//   cellular: {
//     MapLayerControls: true,
//     ColorMapping: true,
//     SelectAreaButton: true,
//     AreaSelectionControls: true,
//     HighlightOpacityButtons: true,
//     PredictionSwitch: true,
//     SearchLocation: true,
//     FilterControls: {
//       provider: true,
//       technology: true,
//       date: true,
//       "date range": true,
//       "environment factor": true,
//       "day time factor": true,
//     },
//   },
//   realtime: {
//     MapLayerControls: true,
//     ColorMapping: true,
//     SelectAreaButton: true,
//     AreaSelectionControls: true,
//     HighlightOpacityButtons: true,
//     PredictionSwitch: true,
//     SearchLocation: true,
//     FilterControls: {
//       provider: true,
//       technology: true,
//       date: true,
//       "date range": true,
//       "environment factor": true,
//       "day time factor": true,
//     },
//   },
//   bestserver: {
//     MapLayerControls: true,
//     ColorMapping: true,
//     SelectAreaButton: true,
//     AreaSelectionControls: true,
//     HighlightOpacityButtons: true,
//     PredictionSwitch: true,
//     SearchLocation: true,
//     FilterControls: {
//       provider: true,
//       technology: true,
//       date: true,
//       "date range": true,
//       "environment factor": true,
//       "day time factor": true,
//     },
//   },
// };
