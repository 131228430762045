import Table from 'react-bootstrap/Table';
import React, { forwardRef, useEffect, useState } from "react";
import { chartsTresholds } from '../../../controller/Constants';
import styled from "styled-components";


const TableStyle = styled(Table)`
&&{
  td{
    padding: 6px;
    height: 70px;
    vertical-align: middle;

    :first-child {
      padding-left: 2px;
    }
    :last-child {
      padding-left: 2px;
    }
  }
  th{
    :first-child {
      padding-left: 2px;

    }
    :last-child {
      padding-left: 2px;
    }

  }
  thead th{
    vertical-align: top;
    border-top: 0px;
  }
  &.table{
    // background-color: red;
    margin-bottom: 0px;
    table-layout: fixed; //prevent tables from expanding beyond their parent div
    text-align: center;
  }

}


 `;


function CoveragReliabilityTableTemp({ lineData, date, name }) {
  const [tableData, setTableData] = useState();

  const dataStruct =
  {
    cellcom: {
      numberOfSamples: 0,
      samplesAboveThreshold: 0,
      CoverageReliability: 0,
      Tendency: 0,

      previousNumberOfSamples: 0,
      previousSamplesAboveThreshold: 0,
      previousCoverageReliability: 0,
      previousTendency: 0,

    },
    pelephone: {
      numberOfSamples: 0,
      samplesAboveThreshold: 0,
      CoverageReliability: 0,
      Tendency: 0,

    },
    partner: {
      numberOfSamples: 0,
      samplesAboveThreshold: 0,
      CoverageReliability: 0,
      Tendency: 0,

    }
  }


  function extractData(linData) {
    if (linData !== undefined) {
      lineData.forEach(function (item, index) {
        console.log(item.placements, index);
        let listOfAllSignals = item.placements.map(placements => placements.signal)
        let listOfAllSignalsAboveThreshhold = item.placements.filter(placements => placements.signal >= chartsTresholds.COVERAGE_RELIABILITY_4G) //4G
        switch (item.provider) {
          case "cellcom":
            if (item.date === date) {
              dataStruct.cellcom.numberOfSamples = listOfAllSignals.length;
              dataStruct.cellcom.samplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.cellcom.CoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100||0

            }
            else {
              dataStruct.cellcom.previousNumberOfSamples = listOfAllSignals.length;
              dataStruct.cellcom.previousSamplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.cellcom.previousCoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100
            }
            break;
          case "pelephone":
            if (item.date === date) {
              dataStruct.pelephone.numberOfSamples = listOfAllSignals.length;
              dataStruct.pelephone.samplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.pelephone.CoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100||0
            }
            else {
              dataStruct.pelephone.previousNumberOfSamples = listOfAllSignals.length;
              dataStruct.pelephone.previousSamplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.pelephone.previousCoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100
            }
            break;
          case "partner":
            if (item.date === date) {
              dataStruct.partner.numberOfSamples = listOfAllSignals.length;
              dataStruct.partner.samplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.partner.CoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100||0
            }
            else {
              dataStruct.partner.previousNumberOfSamples = listOfAllSignals.length;
              dataStruct.partner.previousSamplesAboveThreshold = listOfAllSignalsAboveThreshhold.length;
              dataStruct.partner.previousCoverageReliability = listOfAllSignalsAboveThreshhold.length / listOfAllSignals.length * 100
            }
          default:
        }
      });
      if(dataStruct.cellcom.CoverageReliability && dataStruct.cellcom.previousCoverageReliability){
        dataStruct.cellcom.Tendency = dataStruct.cellcom.CoverageReliability - dataStruct.cellcom.previousCoverageReliability || 0
        }
        if(dataStruct.pelephone.CoverageReliability && dataStruct.pelephone.previousCoverageReliability){
          dataStruct.pelephone.Tendency = dataStruct.pelephone.CoverageReliability - dataStruct.pelephone.previousCoverageReliability|| 0
        }
        if(dataStruct.partner.CoverageReliability && dataStruct.partner.previousCoverageReliability){
          dataStruct.partner.Tendency = dataStruct.partner.CoverageReliability - dataStruct.partner.previousCoverageReliability|| 0
        }
      setTableData(dataStruct)
    
    }
  }
  useEffect(() => {
    extractData(lineData)
  }, [lineData]);


const arrowDirection = (tendency)=> {

  if(tendency<=-1){
    return <i class="fa fa-arrow-down " aria-hidden="true" style={{color: 'red'}}></i>
  }
  else if(tendency>=1){
    return <i class="fa fa-arrow-up" aria-hidden="true" style={{color: 'mediumseagreen'}}></i>
  }
  return <i class="fa fa-arrow-right" aria-hidden="true"></i>
}

  // useEffect(() => {
  //   console.log("tableData---",  tableData)
  // }, [tableData]);

  return (
    <div id={name} style={{display:'table'}}  >

      {tableData && <TableStyle class="table table-image" hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Network</th>
            <th>Number Of Samples</th>
            <th>Samples Above Threshold</th>
            <th>Coverage Reliability</th>
            <th>Tendency</th>
            <th></th>

          </tr>
        </thead>
        <tbody  >
          <tr>
            <td>1</td>
            <td>Cellcom </td>
            <td>{tableData.cellcom.numberOfSamples}</td>
            <td>{tableData.cellcom.samplesAboveThreshold}</td>
            <td>{tableData.cellcom.CoverageReliability.toFixed(1)}%</td>
            <td>{tableData.cellcom.Tendency.toFixed(1)}%</td>
            <td >
            {arrowDirection(tableData.cellcom.Tendency)}
            </td>

          </tr>
          <tr>
            <td>2</td>
            <td>Pelephone</td>
            <td>{tableData.pelephone.numberOfSamples}</td>
            <td>{tableData.pelephone.samplesAboveThreshold}</td>
            <td>{tableData.pelephone.CoverageReliability.toFixed(1)}%</td>
            <td>{tableData.pelephone.Tendency.toFixed(1)}%</td>
            <td>
            {arrowDirection(tableData.pelephone.Tendency)}
            </td>
            {/* {
              Math.abs(tableData.pelephone.Tendency) > 1 ? (
                <td >
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </td>
              ) : (
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              )
            } */}
          </tr>
          <tr>
            <td>3</td>
            <td>Partner</td>
            <td>{tableData.partner.numberOfSamples}</td>
            <td>{tableData.partner.samplesAboveThreshold}</td>
            <td>{tableData.partner.CoverageReliability.toFixed(1)} %</td>
            <td>{tableData.partner.Tendency.toFixed(1)} %</td>
            <td>
            {arrowDirection(tableData.partner.Tendency)}
            </td>
          </tr>
        </tbody>
      </TableStyle>}
    </div>
  );
}

export default CoveragReliabilityTableTemp;