import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import './add_sensors.css';
import { setDialogSite } from "../../../store/actionCreators/general";
import { addToast } from "../../../store/actionCreators/general";
import useProject from "../../../Hooks/useProject";
import { Uploader } from "../../../utils/sensor_uploader";
import { useQuery } from 'react-query';
import Api from "../../../controller/ApiManager/index";
import { useDispatch, useSelector } from "react-redux";
import { SET_SENSOR_INFO, CLEAR_SENSOR_INFO } from "../../../store/actionTypes";



const PollingSensorInfoStatus = ({ sensorInfoId, onComplete }) => {
  const validateStatus = status => status === "Ready" || status === "Error";
  const dispatch = useDispatch();
  const POLL_INTERVAL = 2000;
  const MAX_ATTEMPTS = 25;


  const { data, status, error } = useQuery(
    ['sensorInfoStatus', sensorInfoId],
    async () => {
      const result = await Api.Sensors.checkSensorInfoStatus(sensorInfoId);
      console.log("useQuery reault:", result.Status)
      console.log("is validateStatus:", validateStatus(result.Status))
      console.log("status === Ready", result.Status === "Ready")
      if (!validateStatus(result.Status)) {
        throw new Error('Sensor info status is not ready');
      }
      dispatch({
        type: CLEAR_SENSOR_INFO,
      });
      onComplete(result.Status);
      return result;
    },
    {
      refetchInterval: POLL_INTERVAL,
      retry: MAX_ATTEMPTS,
      refetchOnWindowFocus: false
    }
  );

  if (status === 'loading') {
    return <div className="loadingSensors">Uploading and processing sensors... Please come back later</div>;
  }
  if (status === 'error') {
    return <div>Error: {error.message}</div>;
  }
  return <div>Sensor info status: {data.Status}</div>;
};

const SubmitButton = styled(Button)`
&.btn[type="submit"] {
  background: #212529;
  font-size: 16px;
  margin-top: 10px !important;
}
`;

function AddSensors({ onClose: closeDialog, addToast }) {
  const dispatch = useDispatch();
  const fileReader = new FileReader();
  const { t } = useTranslation();
  const [file, setFile] = useState(undefined);
  const sensorInfoId = useSelector((state) => state.general.currentSensorInfo);
  const [uploader, setUploader] = useState(undefined)
  const [array, setArray] = useState([]);
  const projectId = useProject()._id;



  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).replace(/\s/g, "").split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const array = csvRows.slice(0, 3).map(i => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    setArray(array);
  };

  const onComplete = function (status) {
    if (status === "Ready") addToast(`Sensors uploaded and ready to view`);
    else if(status === "Error") addToast(`Failed to process sensors file`);
    closeDialog();
  }

  const handleSubmit = async function (ev) {
    ev.preventDefault();
    if (file) {
      try {
        let percentage = undefined
        uploader.onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage
            console.log(`${percentage}%`)
          }
        }).onError((error) => {
          setFile(undefined)
          console.error(error)
        })

        uploader.onComplete((_) => {
          addToast(`Sensors file uploaded. Starting processing...`);
        });

        uploader.onError((_) => {
          addToast(`Failed to upload sensors file`);
        });

        uploader.onHavingSensorID((sInfoId) => {
          dispatch({
            type: SET_SENSOR_INFO,
            sensorInfoId: sInfoId,
          });
        });

        uploader.start()

      } catch (error) {
        console.error(error);
        closeDialog();
      }
    }
  };


  const handleOnChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFile(file);
    console.log("file", file)

    if (file) {
      const uploader = new Uploader({
        file: file,
        projectId: projectId,
      })
      setUploader(uploader)
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file.slice(0, Math.min(1024 * 512, file.size)));
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array)).map(key => {
    const skey = key.toLowerCase();
    if (skey.includes("iot")) return "NB-IOT"
    else if (skey.includes("lte")) return "LTE M"
    else return key
  });

  return <div>
    <div class="modal-header">
      <h2 className="section-title">{t("Add Sensors to Project")}</h2>
    </div>
    <div class="modal-body">
      {!sensorInfoId && <Form className="form inputs-underline" onSubmit={handleSubmit}>
        <Form.Text>Please add sensors locaion file:</Form.Text>
        <input
          className="uploadFile"
          type="file"
          id="csvFileInput"
          accept=".csv"
          onChange={handleOnChange}>
        </input>
        {array.length > 0 &&
          <div className="table-responsive card">
            <table className="table myTable">
              <thead class="thead-dark">
                <tr key={"header"}>
                  <th scope="col">#</th>
                  {headerKeys.map((key) => (
                    <th scope="col">{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {array.map((item, index) =>
                (<tr key={item.id}>
                  <th scope="row">{index + 1}</th>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
                ))}
              </tbody>
            </table>
            <p style={{ fontSize: "22px" }}>&nbsp;&nbsp;&nbsp;...</p>
          </div>
        }
        <Form.Group className="center">
          <SubmitButton type={"submit"} variant={"primary"} className="width-100" disabled={array.length == 0}>
            {t("Add Sensors")}
          </SubmitButton>
        </Form.Group>

      </Form>}
      {sensorInfoId && <PollingSensorInfoStatus sensorInfoId={sensorInfoId} onComplete={onComplete} />}

    </div>
  </div>
  //   {sensorInfoId && <FullScreenLoader />}

}

export default connect(() => ({}), { setDialogSite, addToast })(AddSensors);
