import React, { useRef, useState, useEffect, useCallback } from "react";
import { GoogleMap } from "@react-google-maps/api";
import styled from "styled-components";
import PlacesAutoComplete from "../GoogleAutoComplete";
import Button from "react-bootstrap/Button";
import PolygonWrapper from "../../MapControls/PolygonWrapper";
import { getInitialPolygonPath } from "../../../controller/common";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import PolygonFileManager2 from "../PolygonFileManager2";
import { useFetchLoader, useId } from "../../../Hooks/common";
import Alert from 'react-bootstrap/Alert';

const StyledGoogleMap = styled(GoogleMap)`
  height: 400px;
  width: 300px;
`;
const ButtonsPosition = styled.div`
  position: absolute;
  bottom: 0%;
  right: 50%;
  transform: translateX(50%);
  text-align: center;
`;
const ControlButton = styled(Button)`
  transition: 0s;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;

  i {
    transition: 0s;
    font-weight: bold;
  }
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

function handleFlatDeep(arr, boundingbox) {
  return { bBox: boundingbox, path: flatDeep(arr, Infinity) };
}

function flatDeep(arr, d = 1) {
  if (isLastLevel(arr)) return { lng: arr[0], lat: arr[1] };
  return d > 0 ? arr.reduce((acc, val) => acc.concat(!isLastLevel(val) ? flatDeep(val, d - 1) : { lng: val[0], lat: val[1] }), []) : arr.slice();
}

function isLastLevel(arr) {
  return typeof arr[0] === "number";
}

const flatenMultiPolygons = (geometries) => {
  const flatenedGeometries = geometries.map(geometry => {
    if (geometry.geojson.type === "MultiPolygon") {
      const lengths = geometry.geojson.coordinates.map(polygon => polygon.length);
      const largestPolygon = geometry.geojson.coordinates.find(polygon => polygon.length === Math.max(...lengths));
      const largestSinglePolygon = largestPolygon.find(polygon => polygon.length === Math.max(...largestPolygon.map(p => p.length)));
      const geojson = { type: "Polygon", coordinates: largestSinglePolygon }
      return { ...geometry, geojson }
    }
    return geometry
  })
  return flatenedGeometries;
}

const handleSelectPolygon = (geometries, onNoValidGeometries) => {
  const validGeometriesTypes = ["MultiPolygon", "Polygon", "Point"];
  const validGeometries = geometries.filter(geometry => validGeometriesTypes.includes(geometry.geojson.type));
  if (validGeometries.length === 0) return onNoValidGeometries();
  if (validGeometries.every((element) => element.geojson.type === "Point")) {
    return { path: validGeometries[0].geojson.coordinates, bBox: validGeometries[0].boundingbox }
  }
  const flatenedGeometries = flatenMultiPolygons(geometries);
  return getLargestPolygonByPoints(flatenedGeometries)
}

function getLargestPolygonByPoints(data) {
  const allPolygons = data.map((entry) => handleFlatDeep(entry.geojson.coordinates, entry.boundingbox));
  let max = 0;
  let maxObj = {};
  for (const polygon of allPolygons) {
    if (polygon.path.length > max) {
      maxObj = polygon;
      max = polygon.path.length;
    }
  }
  console.log({ maxObj })
  return maxObj;
}

const lat = 31.767328;
const lng = 35.201870;
const CENTER = { lat, lng }
const ZOOM = 11

function PolygonInput({ projectName, visibleButtons, onPolygonPathChanged, polygonPath }) {
  const [, setLoading] = useFetchLoader();
  const [inEditMode, setInEditMode] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const autoCompleteRef = useRef(null);
  const currentMap = useRef(null);
  const configFileManagerRef = useRef(null);
  const { saveVisible, resetVisible, deleteVisible, uploadVisible, downloadVisible } = visibleButtons;
  const polygonId = useId();
  const mapOptions = {
    clickableIcons: false,
    disableDefaultUI: true,
  };
  const isPolygonEmpty = polygonPath.length === 0;

  const centerMapAccordingToPolygon = useCallback(() => {
    if (!currentMap.current) return;
    const mapBounds = new window.google.maps.LatLngBounds();

    polygonPath.forEach((point) => mapBounds.extend(point));
    currentMap.current.fitBounds(mapBounds, { bottom: 200, right: 200, left: 200, top: 100 });
  }, [polygonPath])

  useEffect(() => {
    // Reset the polygon only in 'addProject' dialog by passing 'polygonPath' prop as empty arry.
    isMapLoaded && isPolygonEmpty && resetPolyonBasedOnMap()
  }, [isMapLoaded]);

  async function onPlaceChanged() {
    try {
      setLoading(true);
      const place = autoCompleteRef.current.getPlace();
      const response = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${place.formatted_address}&polygon_geojson=1&format=json`);
      const responsePolygon = await response.json();
      const { path, bBox } = handleSelectPolygon(responsePolygon, () => setShowAlert(true));
      const bBoxFormatted = [
        { lat: Number(bBox[0]), lng: Number(bBox[3]) },
        { lat: Number(bBox[1]), lng: Number(bBox[2]) },
      ];
      const mapBounds = new window.google.maps.LatLngBounds();
      bBoxFormatted.forEach((point) => mapBounds.extend(point));
      onPolygonPathChanged(path);
      setTimeout(() => {
        currentMap.current.fitBounds(mapBounds);
      }, 0);
    } catch (err) {
      console.log(err); // if fech has error what to do?
    } finally {
      setLoading(false);
    }
  }

  async function resetPolyonBasedOnMap() {
    const map = currentMap.current;
    let mapCenter = map.getCenter();
    if (!mapCenter) {
      map.setCenter(CENTER);
      map.setZoom(ZOOM);
    }
    const bounds = map.getBounds();
    const zoom = map.getZoom();
    if (!map.getProjection())
      return;
    const sizeProp = 100;
    onPolygonPathChanged(getInitialPolygonPath(mapCenter, bounds, zoom, map, sizeProp));
  }

  async function handleEditMode() {
    if (inEditMode) onPolygonPathChanged(polygonPath);
    setInEditMode(!inEditMode);
  }

  return (
    <StyledGoogleMap
      id={"PolygonInput-map-" + polygonId}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      zoom={ZOOM}
      center={CENTER}
      options={mapOptions}
      onLoad={(map) => {
        currentMap.current = map;
        setIsMapLoaded(true);
        centerMapAccordingToPolygon();
      }}
    // onProjectionChanged={resetPolyonBasedOnMap}
    >
      <PlacesAutoComplete
        defaultValue={""}
        onPlaceChanged={onPlaceChanged}
        ref={autoCompleteRef}
        placeholder={"Search for an address"}
        inputStyle={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          position: "absolute",
          left: 0,
          border: "1px solid red",
          backgroundColor: "#ffff",
        }}
      />
      {showAlert &&
        <div style={{ display: `flex`, justifyContent: 'center' }} >
          <Alert style={{ width: `240px`, border: '1px solid #664d03' }} variant={"warning"} onClose={() => setShowAlert(false)} dismissible>
            Could not find a valid polygon for the requested location
          </Alert>
        </div>
      }
      <PolygonWrapper inEditMode={inEditMode} polygonPath={polygonPath} setPolygonPath={onPolygonPathChanged} />
      {polygonPath && (
        <ButtonsPosition>
          <ButtonGroup>
            {saveVisible && (
              <ControlButton onClick={handleEditMode} variant="primary">
                {inEditMode ? "Done" : "Edit"}
              </ControlButton>
            )}
            {resetVisible && (
              <ControlButton onClick={resetPolyonBasedOnMap} variant="primary">
                {"Reset"}
              </ControlButton>
            )}
            {deleteVisible && (
              <ControlButton
                onClick={() => {
                  throw Error("Button not implemented");
                }}
                variant="primary"
              >
                {"Delete"}
              </ControlButton>
            )}
            {uploadVisible && (
              <ControlButton onClick={() => configFileManagerRef.current.downloadFile()} variant="primary">
                {"Save Polygon"}
              </ControlButton>
            )}
            {downloadVisible && (
              <ControlButton onClick={() => configFileManagerRef.current.uploadFile()} variant="primary">
                {"Upload Polygon"}
              </ControlButton>
            )}
            <PolygonFileManager2
              ref={configFileManagerRef}
              polygonPath={polygonPath}
              setPolygonPath={onPolygonPathChanged}
              projectName={projectName}
              centerMapAccordingToPolygon ={centerMapAccordingToPolygon}
            />
          </ButtonGroup>
        </ButtonsPosition>
      )}
    </StyledGoogleMap>
  );
}

export default PolygonInput;
