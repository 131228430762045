import React, { forwardRef } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  LabelList,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { colorsForCoveragePerformance } from '../Constants';


function BarRechartComponent({ data, name }, ref) {

  //for bars colors
  const dataStruct = [
    {
      category: " >-80",
      percent: 0,
    },
    {
      category: "-80 -90",
      percent: 0,
    },
    {
      category: "-90 -105",
      percent: 0,
    },
    {
      category: "-105 -120",
      percent: 0,
    },
    {
      category: "< -120",
      percent: 0,
    },
  ];

  function extractData(barData) {
    if (barData !== undefined && barData.data !== "problem with bins list") {
      if (barData.bestServer)
        return data.bestServer
      return data.data
    }
  }
  function formatAxsis(m) {
    switch (m) {
      case "-80 -90":
        return "-80 : -90"
      case "-90 -105":
        return "-90 : -105"
      case "-105 -120":
        return "-105 : -120"
      default:
        return m
    }
  }
  const getXValue = data => {
    return parseInt(data.percent)
  };
  return (
    <div id={name} ref={(element) => ref.current.add(element)} style={{ padding: 5 }}>
      {data &&
        <ResponsiveContainer width="100%" height={280}>
          <BarChart
            data={extractData(data) || []}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <XAxis tickFormatter={(m) => formatAxsis(m)}
              dataKey="category" style={{
                fontSize: '0.6rem'
              }}>
              <Label value="dbm" offset={3} position="right" fontSize={14} />
            </XAxis>
            <YAxis
              type="number"
              domain={[0, 100]}
            >
              <Label value="%" offset={-25} position="left" fontSize={14} />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar dataKey={getXValue} fill="#82ca9d" legendType="none" name={"%"}>
              <LabelList dataKey="percent" position="top" />
              {
                dataStruct.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colorsForCoveragePerformance[index % 6]} />
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
    </div>
  );
}
export default forwardRef(BarRechartComponent);

