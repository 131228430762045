import Table from 'react-bootstrap/Table';
import React, { forwardRef, useEffect, useState } from "react";
import CoverageReliabilityTableArrows from '../CoverageReliabilityTableArrows';

function CoveragReliabilityTable({data, providers, name }, ref) {
  
  const [tableData, setTableData] = useState();
  useEffect(() => {
    if (data !== undefined) {
      setTableData(data[0]?.coverageReliability)
      // if (data[0]?.coverageReliability)
      //   setProviders(Object.keys(data[0]?.coverageReliability))
    }
  }, [data]);

  return (
    <div id={name} ref={(element) => ref.current.add(element)}   >
      {providers && tableData && <Table class="table table-image"  hover style={{marginRight:"auto", marginLeft: "auto", width: "60%"}} >
        <thead>
          <tr>
            <th></th>
            <th>Network</th>
            <th>Number Of Samples</th>
            <th>Samples Above Threshold</th>
            <th>Samples Above Threshold (%)</th>
            <th>Tendency</th>
          </tr>
        </thead>
        <tbody  >
         {providers.map((item, index) => (
               <tr>
               <td>{index+1}</td>
               <td>{item} </td>
               <td>{tableData[item].numberOfSamples}</td>
               <td>{tableData[item].samplesAboveThreshold}</td>
               <td>{tableData[item].CoverageReliability} %</td>
               <td>{tableData[item].Tendency} %</td>
               <CoverageReliabilityTableArrows tendency={tableData[item].Tendency}/>
             </tr> ))}
        </tbody>
      </Table>}
    </div>
  );
}

export default forwardRef(CoveragReliabilityTable);
