import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const StyledItem = styled.div`
    &.item.item-row > a {
        padding: 0 0 0 180px;
        margin:0;
    }
`;

const ImageConatainer = styled.div`
  background-color:${({ theme }) => theme.palette.primary.main};
  img{
    display: block;
    height: auto;
    position: absolute;
    width: 100%;
    margin: auto;
    top: 50%;
    transform: translateY(-56%);
   }
`;

function ProjectRow({ project, image }) {
    return <StyledItem className="item item-row">
        <Link to={`/projects/${project._id}/coverage-map`}>
            <ImageConatainer className="image bg-transfer">
                {/*<figure>Average Price: $8 - $30</figure>*/}
                <img src={image || '/assets/img/logo/logo-21.png'} alt={project.name} />
            </ImageConatainer>
            <div className="description">
                <h3>{project.name}</h3>
                {/* <h4>{`polygon : ${/*todo: get the adress of the center of the polygon with some custom hook/*}`}</h4> */}
                <div className="label label-default">{'Cellular Site'}</div>
            </div>
        </Link>
        <div className="controls-more">
            <ul>
                <li><a href="#">Soon...</a></li>
                <li><a href="#" className="quick-detail">Quick detail</a></li>
            </ul>
        </div>
    </StyledItem>
}

export default ProjectRow