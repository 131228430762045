import React, { useState } from "react";
import { Form, FormControl, ListGroup } from "react-bootstrap";
import ChipList from "../chips/ChipList";
import { useAllProviders } from "../../Hooks/useAllProviders";
import { providerFields } from "../../controller/Constants";

export const getProviderDisplayName  = (provider) => {
    return provider[providerFields.NAME]
}
const providerKyesToFilter = [providerFields.NAME, providerFields.COUNTRY,providerFields.MCC_MNC]

const FormGroupProvider = ({ setSelectedProviders: setSelectedProviders, selectedProviders: selectedProviders }) => {
    const [searchQuery, setSearchQuery] = useState("")
    const allProviders = useAllProviders()

    const handleSearchChange = (ev) => {
        const lowerCaseSerch = ev.target.value.toLowerCase()
        setSearchQuery(lowerCaseSerch);
    }

    const getProviderFieldsToSearchOn = ()=>{
        return providerKyesToFilter;
    }

    const onClickProvider = (ev) => {
        const selectedProviderId = ev.target.value
        const isProviderSelected = selectedProviders.some((provider) => provider[providerFields._ID] === selectedProviderId)
        if (!isProviderSelected) {
            const foundProvider = allProviders.find((provider) => provider[providerFields._ID] === selectedProviderId)
            setSelectedProviders([...selectedProviders, foundProvider])
        }
    }

    const mccMncToString = (mccMncArray) => {
        const mccMncStrings = mccMncArray.map(([mcc, mnc]) => `${mcc} - ${mnc}`);
        return `( ${mccMncStrings.join(', \xa0')} )`; 
    }

    return (
        <Form.Group>
            <Form.Label 
                htmlFor="Search_Provider" column={false}>{"Providers"}
            </Form.Label>
            <FormControl
                type="text"
                placeholder="Search provider . . ."
                onChange={handleSearchChange}
                value={searchQuery}
            />
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <ListGroup>
                    {
                        allProviders.filter((provider)=> getProviderFieldsToSearchOn().some(field => provider[field]?.toString()?.toLowerCase()?.includes(searchQuery))).map((provider, index) => {
                            return <ListGroup.Item type="button" onClick={onClickProvider} action={true} key={provider[providerFields._ID]} value={provider[providerFields._ID]}>{`${provider[providerFields.NAME]} ${mccMncToString(provider[providerFields.MCC_MNC])} ${provider[providerFields.COUNTRY]?' - '+provider[providerFields.COUNTRY]:''}`}</ListGroup.Item >
                        })
                    }
                </ListGroup>

            </div>
            <ChipList data={selectedProviders} setData={setSelectedProviders} valueInChipsToDisplay={getProviderDisplayName } />
        </Form.Group>

    )


}

export default FormGroupProvider;