import {mapLayerValues} from "../../controller/Constants";

export const DEFAULT_PROJECT_PARAMS = {
  mapLayer: "",
  project: "",
  devParams: {
    samplesReliability: 0,
    binResolution: 0,
    binReliability: 0, //percentile on the old version
    polarizationLoss: 0,
    systemGain: 0,
    minCoverage: 0,
  },
  technology: "",
  frequencyBand: 0,
  channelBW: 0,
  rssiThreshold: 0,
  downUpLinkRatio: 0,
  antennaPolarization: "",
};

export const DEFAULT_PROJECT = {
  _id: "",
  sites: [],
  name: "",
  polygon:[],
  // currentMapLayer: 'default-layer',//mapLayerValues[0]
  defaultMapLayer: "default-layer", //mapLayerValues[0] ????????????????????????????????????????????
  mapLayersParams: [
    {
      mapLayer: "default-layer",
      projectParams: DEFAULT_PROJECT_PARAMS,
    },
  ],
  config:{
    dataAccess:{
      availableProviders:[],
      featuresPermissions:[]
    }
  }
};
