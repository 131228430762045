
import React, { forwardRef } from "react";
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { chartsTresholds } from "../../../controller/Constants";

function LinechartComponent({ data, monthsSequence, name }, ref) {
    const dataStruct = [];
    function formatTicks(month) {
        if (month !== undefined && data[0]) {
            const dataObj = data[0].data.find(obj => obj.month === month);
            return `${dataObj.month}/${dataObj.year.slice(2, 4)}`;
        }
    }
    function extract() {
        if (data[0])
            return data[0].data
        else return false

    }
    return (
        <div id={name} ref={(element) => ref.current.add(element)} style={{ padding: 5 }}>
            {data && <ResponsiveContainer width="100%" height={280} >
                <LineChart width={580} height={300} data={extract(data) || []}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={"month"} tickFormatter={(month) => formatTicks(month)}
                        fontSize={10} >
                    </XAxis>
                    <YAxis dataKey={chartsTresholds.PERCENT_OF_SIGNALS_ABOVE_TRESHOLD_LABEL} label={{ value: '%', position: 'insideLeft', textAnchor: 'middle' }} type="number" domain={[0, 100]} />
                    <Tooltip />
                    <Line name={"%"} type="monotone" dataKey={chartsTresholds.PERCENT_OF_SIGNALS_ABOVE_TRESHOLD_LABEL} stroke="#0095FF" />
                </LineChart>
            </ResponsiveContainer>
            }
        </div>
    );
}
export default forwardRef(LinechartComponent);
