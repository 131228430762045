import urls from "../configuations/endpoints";

const Constants = {
  TRANSLATION_URL: urls.TRANSLATION_URL,
  AUTH_URL: urls.AUTH_URL,
  REST_URL: urls.REST_URL,
  //^^^^^^^^^prototypes for code completions^^^^^^^^^^
  //^^^^^^^^^will be override by urls^^^^^^^^^^
  ...urls,
  GOOGLE_MAPS_API_KEY: "AIzaSyDwaf7lCE4td-ieAI3BV2cfmJrJS_CHzV4",
  EMAIL_URL: "https://aut4pawf7k.execute-api.eu-west-1.amazonaws.com/dev/",
  DEFAULT_PROJECT_SITES_IMAGE: "/assets/img/antenna.png",
  DEFAULT_STATIC_ANTENNA_MARKER: "/assets/img/antenna.png",
  SITE_IMAGE_PLACEHOLDER: "https://via.placeholder.com/150/000000/FFFFFF",
  JWT_TOKEN_ID: "token_id",
  JWT_REFRESH_TOKEN: "refresh_token",
  JWT_ACCESS_TOKEN: "access_token",
  JWT_EMAIL: "user_email",
  LOGIN_SUFFIX_URL: "login",
  REGISTER_SUFFIX_URL: "register",
  REFRESH_TOKEN_SUFFIX_URL: "refreshToken",
  EARTH_RADIUS: 6372.797,
  KM_TO_LAT_LNG: (1 / (40075.017 / 360)),
  STAGE: process.env.REACT_APP_STAGE,
  STAGE_MODE: process.env.REACT_APP_STAGE_MODE,
  modals: {
    REGISTER: "REGISTER",
    LOGIN: "LOGIN",
    PARAMETERS: "PARAMETERS",
    ADD_PROJECT: "ADD_PROJECT",
    TABLE: "TABLE",
    PROJECT_LIST: "PROJECT_LIST",
    EDIT_PROJECT: "EDIT_PROJECT",
    EDIT_SITE: "EDIT_SITE",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    CLUSTER: "CLUSTER",
    CLUSTER_ALERT: "CLUSTER_ALERT",
    DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
    TECH_CONVERSION: "TECH_CONVERSION",
    TRY_AGAIN_GET_COVERAGE: "TRY_AGAIN_GET_COVERAGE",
    TERMS: "TERMS",
    CREATE_USER: 'CREATE_USER',
    NO_PERMISSSINS_TO_COVERAGE_MAPS: "NO_PERMISSSINS_TO_COVERAGE_MAP",
  },
  mapTypes: {
    BEST_SERVER: "BEST_SERVER",
    C2I: "C2I",
  },
  mapStates: {
    SITES_COVERAGE: "SITES_COVERAGE",
    AREA_SELECTION: "AREA_SELECTION",
  },
  events: {
    UPDATE_PROJECT: "UPDATE_PROJECT",
    SET_CENTER: "SET_CENTER",
    CHOSE_2_SITES: "CHOSE_2_SITES",
    REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
    DELETE_POLYGON: "DELETE_POLYGON",
    RESET_POLYGON: "RESET_POLYGON",
    CENTER_CHANGED: "CENTER_CHANGED",
    REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
    SET_LOCATION_STATS: "SET_LOCATION_STATS",
  },
  localStorage: {
    REFRESH_TOKEN: "refreshToken",
    ID_TOKEN: "smartCityIdToken",
    ACCESS_TOKEN: "accessToken",
  },
  errors: {
    NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
    FUNCTION_DEPRECATED: "function is deprecated!",
    TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
    UNAUTHORIZED: "user not authorized",
    NO_PROJECT_ID: "no providerId provided!",
    NO_BINS: "No BinsPlacements Field in Sector.",
  },
  technologies: ["3G-UMTS-FDD", "4G-LTE-FDD", "5G-TDD"],
  frequencyBands: [3600, 2600, 2100, 1800],
  channelBWs: [20, 40, 60, 100],
  polarizationTypes: ["Vertical", "Horizontal", "Circular", "Dual Slant 45deg"],

  mapLayers: {
    RSSI_COVERAGE: "RSSI - Coverage Optimized",
    RSSI_CAPACITY: "RSSI - Capacity Optimized",
    MCS_COVERAGE: "MCS - Coverage Optimized",
    MCS_CAPACITY: "MCS - Capacity Optimized",
    BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
    BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
    RSRP_COVERAGE: "RSRP - Coverage Optimized",
    RSRP_CAPACITY: "RSRP - Capacity Optimized",
    RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
    RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
  },
  userGroups: {
    MANAGER: "manager",
    ADMIN: "admin",
  },
  antennaConfigs: ["Model-A", "Model-B", "Steered"],
  siteModes: { predesign: "Predesign", postdesign: "Postdesign" },
  smartCityKFactor: 112000,
  smartCityShowSiteIcon: false,
  showStaticAntennas: false,
};
export default Constants;

export const HEATMAP_FACTOR = 112000;
export const HEATMAP_AGGREGATION_FACTOR = 112000;

export const modals = {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  PARAMETERS: "PARAMETERS",
  ADD_PROJECT: "ADD_PROJECT",
  ADD_SENSORS: "ADD_SENSORS",
  TABLE: "TABLE",
  PROJECT_LIST: "PROJECT_LIST",
  EDIT_PROJECT: "EDIT_PROJECT",
  EDIT_SITE: "EDIT_SITE",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  CLUSTER: "CLUSTER",
  CLUSTER_ALERT: "CLUSTER_ALERT",
  DISPLAY_COVERAGE: "DISPLAY_COVERAGE",
  TECH_CONVERSION: "TECH_CONVERSION",
  TRY_AGAIN_GET_COVERAGE: "TRY_AGAIN_GET_COVERAGE",
  TERMS: "TERMS",
  CHOOSE_POLYGON_ALERT: "CHOOSE_POLYGON_ALERT",
  CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON: "CHOOCE_POLYGON_INSIDE_PROJECT_POLYGON",
  REDUCE_POLYGON_SIZE_ALERT: "REDUCE_POLYGON_SIZE_ALERT",
  CREATE_USER: "CREATE_USER",
  NO_PERMISSSINS_TO_COVERAGE_MAPS: "NO_PERMISSSIN_TO_COVERAGE_MAP",
  DELETE_SENSORS: "DELETE_SENSORS",
  NO_PROJECTS_AVAILABLE: "NO_PROJECTS_AVAILABLE",
};
export const smartCityConstants = {
  SIGNAL_ALARM_THRESHOLD: 10,
};

export const POLYGON_BLUE = "rgba(70,130,180,0.9)";

export const events = {
  UPDATE_PROJECT: "UPDATE_PROJECT",
  SET_CENTER: "SET_CENTER",
  CHOSE_2_SITES: "CHOSE_2_SITES",
  REFRESH_USER_TOKEN: "REFRESH_USER_TOKEN",
  DELETE_POLYGON: "DELETE_POLYGON",
  RESET_POLYGON: "RESET_POLYGON",
  CENTER_CHANGED: "CENTER_CHANGED",
  REARRANGE_ALL_LAYERS: "REARRANGE_ALL_LAYERS",
  SET_LOCATION_STATS: "SET_LOCATION_STATS",
  REPOSITION_CENTER: "reposition_center",
  SET_TOKENS_AFTER_REFRESH_TOKEN: "SET_TOKENS_AFTER_REFRESH_TOKEN",
};
export const errors = {
  PREDICTION_TOO_LARGE: "prediction too large",
  NO_1X1_MODEL: "sector is missing vertical(default) antenna model",
  FUNCTION_DEPRECATED: "function is deprecated!",
  TRY_AGAIN: "refresh token has dispatched, please try again you last operation.",
  UNAUTHORIZED: "user not authorized",
  NO_PROJECT_ID: "no providerId provided!",
  NO_BINS: "No BinsPlacements Field in Sector.",
};

export const mapLayers = {
  RSSI_COVERAGE: "RSSI - Coverage Optimized",
  RSSI_CAPACITY: "RSSI - Capacity Optimized",
  MCS_COVERAGE: "MCS - Coverage Optimized",
  MCS_CAPACITY: "MCS - Capacity Optimized",
  BIT_RATE_COVERAGE: "Bit Rate - Coverage Optimized",
  BIT_RATE_CAPACITY: "Bit Rate - Capacity Optimized",
  RSRP_COVERAGE: "RSRP - Coverage Optimized",
  RSRP_CAPACITY: "RSRP - Capacity Optimized",
  RSRQ_COVERAGE: "RSRQ - Coverage Optimized",
  RSRQ_CAPACITY: "RSRQ - Capacity Optimized",
};

export const colors = {
  // wxtract to "theam"?
  OLIVE_GREEN: "#94c954",
  GREEN: "#00b14f",
  RED: "#ed2024",
  YELLOW: "#f6eb14",
  ORANGE: "#fec00f",
  AZURE: "#03b1fc",
  PURPLE: "#a742f5",
  WHITE: "#ffff",
  GREY: "#CDCDCD",

};


const { OLIVE_GREEN, GREEN, RED, YELLOW, ORANGE, AZURE, PURPLE, WHITE, GREY } = colors;

export const rsrqRange = [-8, -13, -16, -18, -18];

export const snrRange = [15, 10, 5, 0, 0];

export const ecnoRange = [-6, -10, -15, -20, -20];

export const colorsLabelsInfo = { // many duplacations !!!!!!!!
  heatmap: {
    "3G": [
      {
        levels: { min: 112, max: Infinity },
        label: "Very High EM",
        color: RED,
      },
      {
        levels: { min: 0.112, max: 112 },
        label: "High EM",
        color: ORANGE,
      },
      {
        levels: { min: 0.00112, max: 0.112 },
        label: "Medium EM",
        color: YELLOW,
      },
      {
        levels: { min: 0.0000112, max: 0.00112 },
        label: "Moderate EM",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: 0.00000112, max: 0.0000112 },
        label: "Low EM",
        color: GREEN,
      },
      {
        levels: { min: -Infinity, max: 0.00000112 },
        label: "Very Low EM",
        color: WHITE,
      },
    ],
    "4G": [
      {
        levels: { min: 112, max: Infinity },
        label: "Very High EM",
        color: RED,
      },
      {
        levels: { min: 0.112, max: 112 },
        label: "High EM",
        color: ORANGE,
      },
      {
        levels: { min: 0.00112, max: 0.112 },
        label: "Medium EM",
        color: YELLOW,
      },
      {
        levels: { min: 0.0000112, max: 0.00112 },
        label: "Moderate EM",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: 0.00000112, max: 0.0000112 },
        label: "Low EM",
        color: GREEN,
      },
      {
        levels: { min: -Infinity, max: 0.00000112 },
        label: "Very Low EM",
        color: WHITE,
      },
    ],
    "5G": [
      {
        levels: { min: 112, max: Infinity },
        label: "Very High EM",
        color: RED,
      },
      {
        levels: { min: 0.112, max: 112 },
        label: "High EM",
        color: ORANGE,
      },
      {
        levels: { min: 0.00112, max: 0.112 },
        label: "Medium EM",
        color: YELLOW,
      },
      {
        levels: { min: 0.0000112, max: 0.00112 },
        label: "Moderate EM",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: 0.00000112, max: 0.0000112 },
        label: "Low EM",
        color: GREEN,
      },
      {
        levels: { min: -Infinity, max: 0.00000112 },
        label: "Very Low EM",
        color: WHITE,
      },
    ]
  },
  sitePlaning: {
    "3G": [
      {
        levels: { min: -72, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -82, max: -72 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -92, max: -82 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -98, max: -92 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -98 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "4G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "5G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ]
  },
  report: {
    "3G": [
      {
        levels: { min: -74, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -84, max: -74 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -94, max: -84 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -100, max: -94 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -100 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "4G": [
      {
        levels: { min: -86, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -98, max: -86 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -110, max: -98 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -116, max: -110 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -116 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "5G": [
      {
        levels: { min: -86, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -98, max: -86 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -110, max: -98 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -116, max: -110 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -116 },
        label: "No Coverage",
        color: RED,
      },
    ]
  },
  bestserver: {
    "3G": [
      {
        levels: { min: -72, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -82, max: -72 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -92, max: -82 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -98, max: -92 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -98 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "4G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "5G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ]
  },
  iot: {
    "3G": [
      {
        levels: { min: -72, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -82, max: -72 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -92, max: -82 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -98, max: -92 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -98 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "4G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ],
    "5G": [
      {
        levels: { min: -85, max: Infinity },
        label: "High Coverage",
        color: GREEN,
      },
      {
        levels: { min: -97, max: -85 },
        label: "Good Coverage",
        color: OLIVE_GREEN,
      },
      {
        levels: { min: -109, max: -97 },
        label: "Moderate. Coverage",
        color: YELLOW,
      },
      {
        levels: { min: -115, max: -109 },
        label: "Low Coverage",
        color: ORANGE,
      },
      {
        levels: { min: -Infinity, max: -115 },
        label: "No Coverage",
        color: RED,
      },
    ]
  },
  cellular: {
    "3G": {
      "rsrp": [
        {
          levels: { min: -74, max: Infinity },
          label: "High Coverage",
          color: GREEN,
        },
        {
          levels: { min: -84, max: -74 },
          label: "Good Coverage",
          color: OLIVE_GREEN,
        },
        {
          levels: { min: -94, max: -84 },
          label: "Moderate. Coverage",
          color: YELLOW,
        },
        {
          levels: { min: -110, max: -94 },
          label: "Low Coverage",
          color: ORANGE,
        },
        {
          levels: { min: -Infinity, max: -110 },
          label: "No Coverage",
          color: RED,
        },
      ],
      "rsrq": [
        {
          levels: { min: -72, max: Infinity },
          label: "High Coverage",
          color: GREEN,
        },
        {
          levels: { min: -82, max: -72 },
          label: "Good Coverage",
          color: OLIVE_GREEN,
        },
        {
          levels: { min: -92, max: -82 },
          label: "Moderate. Coverage",
          color: YELLOW,
        },
        {
          levels: { min: -98, max: -92 },
          label: "Low Coverage",
          color: ORANGE,
        },
        {
          levels: { min: -Infinity, max: -98 },
          label: "No Coverage",
          color: RED,
        },
      ],
      "snr": [
        {
          levels: { min: -72, max: Infinity },
          label: "High Coverage",
          color: GREEN,
        },
        {
          levels: { min: -82, max: -72 },
          label: "Good Coverage",
          color: OLIVE_GREEN,
        },
        {
          levels: { min: -92, max: -82 },
          label: "Moderate. Coverage",
          color: YELLOW,
        },
        {
          levels: { min: -98, max: -92 },
          label: "Low Coverage",
          color: ORANGE,
        },
        {
          levels: { min: -Infinity, max: -98 },
          label: "No Coverage",
          color: RED,
        },
      ],
      "ecno": [
        {
          levels: { min: -6, max: Infinity },
          label: "High Coverage",
          color: GREEN,
        },
        {
          levels: { min: -10, max: -6 },
          label: "Good Coverage",
          color: OLIVE_GREEN,
        },
        {
          levels: { min: -15, max: -10 },
          label: "Moderate. Coverage",
          color: YELLOW,
        },
        {
          levels: { min: -20, max: -15 },
          label: "Low Coverage",
          color: ORANGE,
        },
        {
          levels: { min: -Infinity, max: -20 },
          label: "No Coverage",
          color: RED,
        },
      ],
    },
    "4G": {
      "rsrp":
        [
          {
            levels: { min: -86, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: -98, max: -86 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: -110, max: -98 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: -120, max: -110 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: -120 },
            label: "No Coverage",
            color: RED,
          },
        ],
      "rsrq":
        [
          {
            levels: { min: -8, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: -13, max: -8 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: -16, max: -13 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: -18, max: -16 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: -18 },
            label: "No Coverage",
            color: RED,
          },
        ],
      "snr":
        [
          {
            levels: { min: 15, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: 10, max: 15 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: 5, max: 10 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: 0, max: 5 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: 0 },
            label: "No Coverage",
            color: RED,
          },
        ],
    },
    "5G": {
      "rsrp":
        [
          {
            levels: { min: -86, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: -98, max: -86 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: -110, max: -98 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: -120, max: -110 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: -120 },
            label: "No Coverage",
            color: RED,
          },
        ],
      "rsrq":
        [
          {
            levels: { min: -8, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: -13, max: -8 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: -16, max: -13 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: -18, max: -16 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: -18 },
            label: "No Coverage",
            color: RED,
          },
        ],
      "snr":
        [
          {
            levels: { min: 15, max: Infinity },
            label: "High Coverage",
            color: GREEN,
          },
          {
            levels: { min: 10, max: 15 },
            label: "Good Coverage",
            color: OLIVE_GREEN,
          },
          {
            levels: { min: 5, max: 10 },
            label: "Moderate. Coverage",
            color: YELLOW,
          },
          {
            levels: { min: 0, max: 5 },
            label: "Low Coverage",
            color: ORANGE,
          },
          {
            levels: { min: -Infinity, max: 0 },
            label: "No Coverage",
            color: RED,
          },
        ],
    },
  }
};

export const applicationCoverage = [
  { range: { min: 0, max: 0 }, label: 'No Coverage', color: '#ed2024', starRate: 0 },
  { range: { min: 0, max: 20 }, label: 'Poor Coverage', color: '#ed2024', starRate: 1 },
  { range: { min: 20, max: 40 }, label: 'Low Coverage', color: '#fec00f', starRate: 2 },
  { range: { min: 40, max: 60 }, label: 'Moderate Coverage', color: '#f6eb14', starRate: 3 },
  { range: { min: 60, max: 80 }, label: 'Good Coverage', color: '#94c954', starRate: 4 },
  { range: { min: 80, max: 100 }, label: 'High Coverage', color: '#00b14f', starRate: 5 },
]

export const applicationsTypes = {
  VOICE_CALL: 'Voice call',
}


export const PROVIDERS = {

  CELLCOM: "cellcom",
  PELEPHONE: "pelephone",
  PARTNER: "partner",
}; // smart city

export const PROVIDERS_LIST = Object.values(PROVIDERS);

export const providerFields = {
  _ID: '_id',
  COUNTRY: "country",
  MCC_MNC: 'mcc_mnc',
  PLMNS: 'plmns',
  NAME: "name",
}

export const TECHNOLOGYS = {
  _3G: "3G",
  _4G: "4G",
  _5G: "5G",
};

export const SIGNALS = {
  RSRP: "rsrp",
  RSRQ: "rsrq",
  SNR: "snr",
  ECNO: "ecno",
};

export const TECHNOLOGYS_BANDS = {
  "3G": ["850", "900", "2100"],
  "4G": ["700", "900", "1800", "2100", "2300", "2500", "2600"],
  "5G": ["700", "2300", "2500", "2600", "3500", "3700"],
}

export const chartsTypes = {
  'COVERAGE_RELIABILITY': 'coverageReliability',
  'COVERAGE_PERFORMANCE': 'coveragePerformance',
  'APPLICATION_QUALITY': 'applicationQuality',
  'COVERAGE_RELIABILITY_TENDENCY': 'coverageReliabilityTendency',
}

export const chartGroup = {
  COVERAGE_RELIABILITY: 'coverageReliability',
  COVERAGE_PERFORMANCE: 'coveragePerformance',
  APPLICATION_QUALITY: 'applicationQuality',
  COVERAGE_RELIABILITY_TENDENCY: 'coverageReliabilityTendency',
  DYNAMIC_POLYGON: 'dynamicPolygon',
}
export const chartType = {
  PERFORMANCE: 'performance',
  DISTRIBUTION: 'distribution',
  BEST_SERVER: 'bestServer',
}
export const TECHNOLOGYS_LIST = Object.values(TECHNOLOGYS);

export const SIGNALS_LIST = Object.values(SIGNALS);

export const dateRangeTypes = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
  DATE: "date"
};

export const dateRangeValues = Object.values(dateRangeTypes);

export const environmentFactor = {
  // smart city - CHANGE NAMES!
  MEN: 6,
  CAR: 0,
  HOME: 0,
};

export const REAL_TIME_INTERVAL = 2000; // in milisec
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const userEnvironments = {
  // smart city - CHANGE NAMES!
  MEN: "Men",
  CAR: "Car",
  HOME: "Home",
};

export const timeOfDay = {
  // smart city
  MORNING: "Morning",
  AFTERNOON: "Afternoon",
  EVENING: "Evening",
};

// export const defaultTechnologiesCoverage = {
//   Email: 0,
//   General: 0,
//   "Voice call": 0,
//   "Email with attachment": 0,
//   "Web Browsing - Webinar": 0,
//   "Video Streaming HD - Zoom": 0,
//   "Video Streaming FHD - Youtube": 0,
//   "Video Streaming 4K - Youtube": 0,
//   "Autonomous Vehicles": 0,
//   "Autonomous Drones": 0,
// };
export const defaultTechnologiesCoverage = []
export const techUsageCategoriesThresholds = {
  "4G": {
    "Email & Text message": { forStars: -116, forColor: -116 },
    "Voice call": { forStars: -115, forColor: -115 },
    "Audio app": { forStars: -113, forColor: -113 },
    "Download files": { forStars: -110, forColor: -110 },
    "Web browsing": { forStars: -109, forColor: -109 },
    "Video sharing": { forStars: -107, forColor: -107 },
    "Streaming app": { forStars: -102, forColor: -102 },
    "Gaming": { forStars: -98, forColor: -98 },
    "Autonomous Vehicles": { forStars: -93, forColor: -93 },
  },
  "5G": {
    "Email & Text message": { forStars: -116, forColor: -116 },
    "Voice call": { forStars: -115, forColor: -115 },
    "Audio app": { forStars: -113, forColor: -113 },
    "Download files": { forStars: -110, forColor: -110 },
    "Web browsing": { forStars: -109, forColor: -109 },
    "Video sharing": { forStars: -107, forColor: -107 },
    "Streaming app": { forStars: -102, forColor: -102 },
    "Gaming": { forStars: -98, forColor: -98 },
    "Autonomous Vehicles": { forStars: -93, forColor: -93 },
  },
  "3G": {
    "Email & Text message": { forStars: -100, forColor: -100 },//-100,
    "Voice call": { forStars: -98, forColor: -98 },//-98,
    "Audio app": { forStars: -95, forColor: -95 },//-95,
    "Download files": { forStars: -92, forColor: -92 },//-92,
    "Web browsing": { forStars: -89, forColor: -89 },//-89,
    "Video sharing": { forStars: -85, forColor: -85 },//-85,
    "Streaming app": { forStars: -79, forColor: -79 },//-79,
    "Gaming": { forStars: 0, forColor: 0 },//0,
  },
};

export const polygonSchemaTypes = {
  LAT_LNG: "latLng",
  MONGO: "mongo"
}

export const chartsTresholds = {
  COVERAGE_RELIABILITY_4G: -97,
  COVERAGE_ERLIABILITY_TENDENCY_4G: -97,
  PERCENT_OF_SIGNALS_ABOVE_TRESHOLD_LABEL: "percent",
}

export const rsrpDistributionStatisticsRange = {
  from: -30,
  to: -140,
}

export const rsrqDistributionStatisticsRange = {
  from: -3,
  to: -30,
}

export const snrDistributionStatisticsRange = {
  from: -15,
  to: 25,
}

export const ecnoDistributionStatisticsRange = {
  from: 0,
  to: -25,
}

//fix name
export const coveragePreformanceHistogramdataStructRsrp = [
  {
    category: " > -80",
    percent: 0,
  },
  {
    category: "-80 -90",
    percent: 0,
  },
  {
    category: "-90 -105",
    percent: 0,
  },
  {
    category: "-105 -120",
    percent: 0,
  },
  {
    category: "< -120",
    percent: 0,
  },
];

export const coveragePreformanceHistogramdataStructRsrq = [
  {
    category: " > -8",
    percent: 0,
  },
  {
    category: "-8 -13",
    percent: 0,
  },
  {
    category: "-13 -16",
    percent: 0,
  },
  {
    category: "-16 -18",
    percent: 0,
  },
  {
    category: "< -18",
    percent: 0,
  },
];

export const coveragePreformanceHistogramdataStructSnr = [
  {
    category: " > 15",
    percent: 0,
  },
  {
    category: "15 10",
    percent: 0,
  },
  {
    category: "10 5",
    percent: 0,
  },
  {
    category: "5 0",
    percent: 0,
  },
  {
    category: "< 0",
    percent: 0,
  },
];

export const coveragePreformanceHistogramdataStructEcno = [
  {
    category: " > -6",
    percent: 0,
  },
  {
    category: "-6 -10",
    percent: 0,
  },
  {
    category: "-10 -15",
    percent: 0,
  },
  {
    category: "-15 -20",
    percent: 0,
  },
  {
    category: "< -20",
    percent: 0,
  },
];


export const coverageReliabilityThresholds =
{
  RSRP: -115,
  RSRQ: -18,
  SNR: 1,
  ECNO: -17,
}

export function floorsAlgo(floor) {
  if (floor <= 10) {
    return 15.4 - 0.08 * Math.pow(floor, 2) + 0.035 * floor;
  }
  return 5.4 - 0.042 * Math.pow(floor, 2) + 0.45 * floor + 0.00075 * Math.pow(floor, 3);
}

export const timeOfDayFactor = {
  MORNING: 0,
  AFTERNOON: 0,
  EVENING: 0,
};
export const availabiltyStatuses = {
  CREATED: "created",
  PENDING: "pending",
  READY: "ready",
};
export const mapLayerValues = {
  CELLULAR: "cellular",
  HEAT_MAP: "heatmap",
  REAL_TIME: "realtime",
  BEST_SERVER: "bestserver",
  IOT: "iot",

};
export const featuresPermissions = {
  IOT_MAP: "iotMap",
  GREEN_CITY_MAP: "greenCityMap",
  CELLULAR_MAP: "cellularMap",
  INDOOR_COVERAGE: "indoorCoverage",
  SITE_PLANNING_MAP: "sitePlanningMap",
  SIGNALS_TYPES: "signalsTypes",
  PREDICTION: "prediction",
  BANDS: "bands",
};

export const signalToMapLayerMapper = {
  CELLULAR: "cellular",
  HEAT_MAP: "heatmap",
  IOT: "iot",
  BEST_SERVER: "bestserver",
};

export const mapControlsDisplayType = {
  STANDARD: "standard",
  DATE_RANGE_OF_24_HOURS: "dateRangeOf24Hours",
};

export const algorithmsTypes = {
  KMEANS: "kmeans",
  STATIC_CLUSTERING: "static-clustering",
};

export const mapLayerTypes = {
  CELLULAR: "cellular",
  HEAT_MAP: "heatmap",
  BEST_SERVER: "bestserver",
  IOT: "iot",
  REPORT: "report",
  STATISTICS: "statistics",
  SITE_PLANING: "sitePlaning"

};

export const mapLayerTypesList = Object.values(mapLayerTypes);

export function between(x, max, min) {
  // Should be refactored ^^^^^^
  return x >= min && x <= max;
}
export const smartCity = {
  getColor: (mapLayer, rssi) => {
    if (rssi > this.labelsColorsInfo[mapLayer].maxSignal) return "#fc03ec";
    if (rssi < this.labelsColorsInfo[mapLayer].minSignal) return "#ffffff";
    const color = this.labelsColorsInfo[mapLayer].find(({ range }) => between(rssi, range.max, range.min)).color;
    return color;
  },
  // getMaxSignal: (mapLayer) => Math.max(this.labelsColorsInfo[mapLayer].values.map(value => value.range.max)),
  // getMinSignal: (mapLayer) => Math.min(this.labelsColorsInfo[mapLayer].values.map(value => value.range.min)),
  labelsColorsInfo: {
    signalMapLayer: [],
    EMMapLayer: {
      // maxSignal:  smartCity.getMaxSignal("EMMapLayer"),
      // minSignal: smartCity.getMinSignal("EMMapLayer"),
      values: [
        {
          rssiValue: -20,
          label: " Very High EM City",
          color: "#9e062a", //4287f5 blue//
          range: {
            max: 0.01,
            min: 0.0004,
          },
        },
        {
          rssiValue: -60,
          label: " High EM City",
          color: "#ff0800",
          range: {
            max: 4e-6,
            min: 4e-7,
          },
        },
        {
          rssiValue: -75,
          label: " Med. EM City",
          color: "#ffc019",
          range: {
            max: 4e-7,
            min: 4e-8,
          },
        },
        {
          rssiValue: -90,
          label: " Moderate EM City",
          color: "#21ea00", //4287f5 blue//
          range: {
            max: 4e-8,
            min: 4e-9,
          },
        },
        {
          rssiValue: -105,
          label: " Low EM City",
          color: "#b3f266",
          range: {
            max: 4e-9,
            min: 4e-10,
          },
        },
        {
          rssiValue: -120,
          label: " Ultra Low EM City",
          color: "#ffff",
          range: {
            max: 4e-10,
            min: 4e-11,
          },
        },
      ],
    },
  },
};

export const TECHNOLOGIES_CALCULATION_RADIUS = 0.2;

export const feturesPremitionsDefault = [
  {
    name: "cellularMap",
    isPermitted: true,
    label: "Cellular Map"

  },
  {
    name: "greenCityMap",
    isPermitted: false,
    label: "Green City Map"
  },
  {
    name: "iotMap",
    isPermitted: false,
    label: "IoT Map"
  },
  {
    name: "indoorCoverage",
    isPermitted: false,
    label: "Indoor Coverage"
  },
  {
    name: "sitePlanningMap",
    isPermitted: false,
    label: "Site Planning"
  },
  {
    name: "signalsTypes",
    isPermitted: false,
    label: "Signal Types"
  },
  {
    name: "prediction",
    isPermitted: false,
    label: "Prediction"
  },
  {
    name: "bands",
    isPermitted: false,
    label: "Bands"
  }
]
