import React, { useEffect } from "react";
import {
  clearGeoJsonData,
  clearGeoJsonDataStyle,
  clearListeners,
  drawGeoJsonData,
  initDataLayer,
  initDataLayerStyle,
  initListeners,
  setGeoJsonDataStyle,
} from "./utils";

function GeoJsonDataDrawer({
  dataLayer,
  data,
  map,
  styleConfig,
  listenersConfig,
}) {
  useEffect(() => {
    initDataLayer(dataLayer, map);
    initDataLayerStyle(dataLayer, styleConfig);
    initListeners(dataLayer, listenersConfig);
    return () => {
      if (!map) return;
      clearGeoJsonData(dataLayer);
      clearGeoJsonDataStyle(dataLayer); // TODO: do we need it here?
      clearListeners(dataLayer, listenersConfig);
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    clearGeoJsonData(dataLayer);
    drawGeoJsonData(dataLayer, data);
    // setGeoJsonDataStyle(dataLayer,map,styleConfig);
  }, [data]);

  return null;
}

GeoJsonDataDrawer = React.memo(GeoJsonDataDrawer);
export default GeoJsonDataDrawer;
