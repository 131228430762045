import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { UserApi } from '../../controller/ApiManager';
import styled from "styled-components"
import Constants from "../../controller/Constants";
import {useAddToast} from "../../Hooks/common";

const FormStyle = styled(Form)`
 input[type="password"].border-valid{
    box-shadow: 0px 0px 0px 1.7px #dc3545, inset 1px 0 5px transparent;
}
.label-alert.danger{
    color: #dc3545;
}
`;

const templatePassword = {
    oldPassword: { name: 'oldPassword', value: '' },
    newPasswoed: { name: 'newPasswoed', value: '' },
    reNewPasswoed: { name: 'reNewPasswoed', value: '' }
}

const RequestParameters = {
    AccessToken: '',
    PreviousPassword: '',
    ProposedPassword: ''
}

const VerifyPasswordMatch = (passOne, passTwo) => {
    return passOne === passTwo;
}

function PasswordSubPage() {
    const [passwordData, setPasswordData] = useState(templatePassword);
    const [validated, setValidated] = useState(false);
    const addToast = useAddToast();

    const handleSubmit = async (ev) => {
        try {
            ev.preventDefault();
            if (!VerifyPasswordMatch(passwordData.newPasswoed.value, passwordData.reNewPasswoed.value)) {
                setValidated(true);
            }
            else { setValidated(false) 
            const accessToken = localStorage.getItem(Constants.localStorage.ACCESS_TOKEN);
            RequestParameters.AccessToken = accessToken;
            RequestParameters.PreviousPassword = passwordData.oldPassword.value;
            RequestParameters.ProposedPassword = passwordData.newPasswoed.value;

            const {status, message:resMessage} = await UserApi.changePassword(RequestParameters);
            if(status===200){
                addToast({message: resMessage, error: false});
            }
            else{
                addToast({message: resMessage, error: true});
            }
        }
        } catch (e) {
            console.log({ e });
            console.error(e);
        } finally {

        }
    }

    const setFormData = (e) => {
        const { name, value } = e.target;
        setPasswordData(state => ({
            ...state,
            [name]: { name, value },
        }));
    }

    return (
        <FormStyle onSubmit={handleSubmit}>
            <h3>Change password</h3>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>Old Password</Form.Label>
                <Form.Control minlength="6" required type="password" placeholder="Password" name={templatePassword.oldPassword.name} onChange={setFormData} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>Create New Password</Form.Label>
                <Form.Control minlength="6" className={`${validated ? 'border-valid' : ''}`} required type="password" placeholder="Password" name={templatePassword.newPasswoed.name} onChange={setFormData} />
                <Form.Text className="text-muted">
                    Minimum length is 6 characters.
                </Form.Text>
            </Form.Group>
            <Form.Group style={{ marginBottom: '6px' }} controlId="formBasicPassword">
                <Form.Label>Confirm new Password</Form.Label>
                <Form.Control minlength="6" style={{ marginBottom: '6px' }} className={`${validated ? 'border-valid' : ''}`} required type="password" placeholder="Password" name={templatePassword.reNewPasswoed.name} onChange={setFormData} />
                {validated ? <span className='label-alert danger'>The passwords are not the same</span> : <span>&shy;</span>}
            </Form.Group>
            <Button variant="primary" type="submit">
                Change Password
            </Button>
        </FormStyle>
    )
}

export default PasswordSubPage;