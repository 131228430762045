import React, { useMemo } from "react";
import GeoJsonDataDrawer from "../Common/GeoJsonDataDrawer/GeoJsonDataDrawer";
import { convertDataToGeoJson } from "./CoveragOverlay/utils";

const SENSOR_BASE_STYLE = {
  fillOpacity: 1,
  strokeOpacity: 0.5,
  strokeWeight: 2,
};

function SensorsMap({ data, map, listenersConfig }) {
  const styleConfig = {
    sample: (feature) => {
      const color = feature.getProperty("color");
      return {
        ...SENSOR_BASE_STYLE,
        fillColor: color,
        strokeColor: "DeepSkyBlue",
      };
    },
  };
  const geoJson = useMemo(() => convertDataToGeoJson(data), [data]);
  const sensorLayer = useMemo(() => new window.google.maps.Data(), []);
  return (
    <GeoJsonDataDrawer
      dataLayer={sensorLayer}
      map={map}
      data={geoJson}
      styleConfig={styleConfig}
      listenersConfig={listenersConfig}
    />
  );
}

export default SensorsMap;
