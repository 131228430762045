import styled, { css }  from "styled-components";
// import ContentPagePdfWithRef1 from "./ContentPagePdfWithRef1";

const PageAFour = styled.div`
width: 21cm;
height: 29.7cm;
padding: 0cm;
margin: 0cm auto;
display: block;
background: white;

${( {firstPage} ) =>
firstPage &&
css`
background-image: url('/assets/img/items/flycommBack.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
`}

.content{
    display: block;
    overflow: hidden;
    height:  ${props => props.firstPage ? '947.5px' : '1018.5px'};
    // height: auto;
    margin: 0px 16px 16px;
}

.hader{
    height:  ${props => props.firstPage ? '115px' : '44px'} ;
}

.footer{
    display: block;
    height: 30px;
    background-image: url("/assets/img/items/ButtomBack.jpg");
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    }

`;

const Hader = styled.div`
height:  ${props => props.firstPage ? '3.042cm' : '1.16cm'} ;
padding: 0cm;
display: block;
`;
const Footer = styled.div`
height: 0.79cm;
display: block;
background-image: url("/assets/img/items/ButtomBack.jpg");
background-color: #000000;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
`;
const Content = styled.div`
height:  ${props => props.firstPage ? '25.86cm' : '27.74cm'} ;
width: 21cm;
display: block;
overflow: hidden;
padding: 0px 16px 16px;
`;
const PageAFour2 = styled.div`

width: 21cm;
height: 29.7cm;
padding: 0cm;
margin: 0cm auto;
display: block;
background: white;
${( {firstPage} ) =>
firstPage &&
css`
background-image: url('/assets/img/items/flycommBack.jpg');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
`}
`;



export default function PageTemplateRef(props) {
console.log(props)
    return (
            // <PageAFour id ={props.idd} firstPage={props.firstPage?true:false}>
            //     <div className="hader"/>
            //     <div className="content">
            //         {props.children}
            //      {/* <ContentPagePdfWithRef1 data={props.data}/> */}
            //     </div>
            //     <div className="footer"/>
            // </PageAFour>
            <PageAFour2 id ={props.idd} firstPage={props.firstPage?true:false} >
                <Hader firstPage={props.firstPage?true:false}/>
                <Content firstPage={props.firstPage?true:false}>
                {props.children}
                {/* <ContentPagePdfWithRef1 data={props.data}/> */}
                </Content>
                <Footer/>
            </PageAFour2>
    )
}