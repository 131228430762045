import React, { useEffect, useRef } from "react";
import { getCenterdPolygon } from "../../../../controller/common";

const notEditModeOptions = {
    fillColor: "rgba(100,149,237,1)",
    strokeColor: "rgba(136, 135, 135,1)",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillOpacity: 0,
    editable: false,
    draggable: false,
}
const editModeOptions = {
    fillColor: "rgba(100,149,237,1)",
    strokeColor: "rgba(70,130,180,0.9)",
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillOpacity: 0.35,
    editable: true,
    draggable: true,
}

function UserPolygon({ displayed, inEditMode, path, polygonParentRef }, ref) {
    const polygonRef = useRef(null);

    useEffect(() => {
        initPolygon();
    }, []);

    useEffect(() => {
        const toggleEditMode = () => {
            const options = inEditMode ? editModeOptions : notEditModeOptions;
            polygonRef.current.setOptions(options);
        }
        toggleEditMode();
    }, [inEditMode])

    useEffect(() => {
        polygonRef.current.setPath(path);
    }, [path]);

    useEffect(() => {
        if (displayed) {
            currentPolygonPath();
            showPolygon();
        } else {
            hidePolygon();
            polygonRef.current.setPath(path)
        }
    }, [displayed]);

    const initPolygon = () => {
        const instance = new window.google.maps.Polygon({
            fillColor: "#ffff",
            strokeColor: "#ffff",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillOpacity: 0.9,
            editable: true,
            draggable: true,
            paths: getCenterdPolygon(window.mainMap),
            visible: true,
            map: window.mainMap,
        });
        polygonRef.current = instance;
        polygonParentRef.current = instance;
    }

    const hidePolygon = () => {
        polygonRef.current.setOptions({ visible: false });
    }

    const showPolygon = () => {
        polygonRef.current.setOptions({ visible: true });
    }

    const currentPolygonPath = () => {
        let polygonPath
        if (path.length > 0) {
            polygonPath = path
        } else {
            polygonPath = getCenterdPolygon(window.mainMap)
        }
        polygonRef.current.setPath(polygonPath);
    }

    return null
}

export default UserPolygon;