import React, { useCallback, useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";

import { Container } from "../StyledComponents/cardCollapseContainer";

import styled from "styled-components";

const StylessButton = styled.button`
  &&,
  &&:active {
    /* margin-right: auto; */
    white-space: nowrap;
    //background: none;
    color: ${({ active }) => (active ? "#f5cb42" : "inherit")};
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  &&:active {
    color: #f5cb42;
  }
  &&:disabled {
    cursor: inherit;
    pointer-events: none;
    color:#c1c1c1
    .list-group-item {
      background-color: #e7eaed;
    }
  }

  flex-grow: 4;
`;

const FlexedDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  background-color: #ebe8e1;
  //justify-content: center;
`;

const Title = styled.div`
  white-space: nowrap;
  margin: 0px;
`;

const StyledIcon = styled.div`
  padding: 0px 10px;
  font-size: 20px;
`;

const StyledCard = styled(Card)``;

function CardCollapse({ title, values, isIcon, defaultOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [arrowUp, setArrowUp] = useState(isOpen);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setArrowUp(isOpen);
    }, 400);
    return () => {
      clearTimeout(timeId);
    };
  }, [isOpen]);
  return (
    <Container>
      <StyledCard className={"map-layer-card smart-city"}>
        <Card.Header className={"card-header-sf"} onClick={() => setIsOpen(!isOpen)}>
          <div className={"title-layer"}>
            <Title>{title}</Title>
          </div>
          <i className={`fa fa-angle-${!arrowUp ? "down" : "up"}`} aria-hidden="true" />
        </Card.Header>
        <Collapse in={isOpen} className={"card-body-collapse"}>
          <FlexedDiv className={"card-body-content"}>
            {values.map(({ value, label, active, onClick, iconLabel, disabled }, index) => {
              return <StylessButton disabled={ index !==0 && disabled} value={value} active={active} onClick={() => onClick(value)}>
                <ListGroup.Item>
                  {isIcon ? (
                    <StyledIcon>
                      <i className={`fa fa-${iconLabel}`} aria-hidden="true"></i>
                    </StyledIcon>
                  ) : (
                    <span>{label}</span>
                  )}
                </ListGroup.Item>
              </StylessButton>
            })}
          </FlexedDiv>
        </Collapse>
      </StyledCard>
    </Container>
  );
}

export default CardCollapse;
