import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {UserApi} from "../../controller/ApiManager/index";
import {closeDialog} from "../../store/actionCreators/general";
import {useDispatch} from "react-redux";
import {useSetUserToRedux} from '../../Hooks/common'
import Alert from "react-bootstrap/Alert";

const DEFAULT_ALERT = {
  message: "",
  error: false,
};

function Login({...props}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(DEFAULT_ALERT);
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const setUserToRedux = useSetUserToRedux();
    const closeDialogRedux = useCallback(
        () => dispatch(closeDialog()),
        [dispatch]
    );
    useEffect(() => {
        props.setFetching(fetching);
        return () => {
            props.setFetching(false)
        }
    }, [fetching]);

    async function handleSubmit(ev) {
        try {
            ev.preventDefault();
            setFetching(true);
            const result = await UserApi.login({email, password});
            setAlert({ message: "Logged in successfully" });
            setUserToRedux(result.tokens.idToken, result.tokens.refreshToken, result.tokens.accessToken);
            closeDialogRedux();
        } catch (e) {
            console.error(e);
            setAlert({ message: "Login failed. please contact customer service", error: true });
        } finally {
            setFetching(false)
        }


    }
  

  function emailChange(ev) {
    setEmail(ev.target.value);
  }

  function passwordChange(ev) {
    setPassword(ev.target.value);
  }

  return (
    <div className="modal-content">
      <Modal.Header>
        {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span*/}
        {/*aria-hidden="true">&times;</span></button>*/}
        <div className="section-title">
          <h2>
            <strong>Sign In</strong>
          </h2>
        </div>
      </Modal.Header>
      <div className="modal-body">
      {alert.message && (
          <Alert
            dismissible
            onClose={() => {
              setAlert(DEFAULT_ALERT);
            }}
            variant={alert.error ? "danger" : "success"}
          >
            {alert.message}
          </Alert>
        )}
        <form className="form inputs-underline" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input required type="email" className="form-control" name="email" placeholder="Email" onChange={emailChange} value={email} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input required type="password" className="form-control" name="password" placeholder="Password" onChange={passwordChange} value={password} />
          </div>
          <div className="form-group center">
            <button type="submit" className="btn btn-primary width-100">
              {"Log in"}
              {fetching ? <i className="fa fa-circle-o-notch fa-spin" /> : ""}
            </button>
          </div>
          <hr />
          <p className="center note">By clicking on “Log in” button you are accepting the Terms & Conditions</p>
        </form>
      </div>
    </div>
  );
}

export default Login;
