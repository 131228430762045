
import { Col, Row, Container, Card, Form, Button, Image } from "react-bootstrap"
import styled from "styled-components";
import CoveragePerformance from "./CoveragePerformance";
import CoverageReliability from "./coverageReliability"
import PowerDensity from "./powerDensity";
// import Form from "react-bootstrap/Form";
import React, { useEffect, useState, useRef } from 'react';
import { yearData, monthData } from "./mockData";
import { useChartsStatisticsActions, usePlacementsActions, useReportDocumentIdActions } from "../../Hooks/common";
import useProject, { useProjectStatistics } from "../../Hooks/useProject";
import { getInitialDateProjectCanAccessDataFrom, getMonthDiff } from "../../controller/common";
import { YAxis } from "recharts";
// import Button from 'react-bootstrap/Button';
import html2canvas from 'html2canvas';
//import PagePdf1 from "../pdfComponents/PagePdf1";
import { jsPDF } from 'jspdf';
import ReactDOMServer from "react-dom/server";
import { mapLayerTypes, PROVIDERS } from "../../controller/Constants";
import { createPdf, domElementToImageData } from '../../controller/pdfGenerator'
import ApplicationQuality from "./ApplicationQuality";
import CoverageReliabilityTendency from "./CoverageReliabilityTendency";
import PageTemplateRef from '../pdfComponents/PageTemplateRef'
import CoveragReliabilityTableTemp from './tables/CoveragReliabilityTableTemp'
import BarRechartComponentTemp from "./barcharts/bar.rechartsTemp";
import ApplicationQualityTableTemp from './tables/ApplicationQualityTableTemp'
import LinechartComponentTemp from "./linecharts/LinechartComponentTemp";
import LinechartComponentMedianTemp from "./linechartsForMedian/LinechartComponentMedianTemp";
import AreachartComponentTemp from './areacharts2/AreachartComponentTemp'
import { toPlacements as calcBestserver } from './calcBestServer'
import Loader from "../Loaders/Loader";
import { ClassicSpinner } from "react-spinners-kit";
import { getProviderDisplayName } from "../Common/AllProvidersManger";
import PollingCharts from "./PollingCharts";
import Api from "../../controller/ApiManager";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
const { REAL_TIME, HEAT_MAP, CELLULAR, BEST_SERVER, REPORT } = mapLayerTypes;


export const chartsTypes = {
    'COVERAGE_RELIABILITY': 'coverageReliability',
    'COVERAGE_PERFORMANCE': 'coveragePerformance',
    'APPLICATION_QUALITY': 'applicationQuality',
    'COVERAGE_RELIABILITY_TENDENCY': 'coverageReliabilityTendency',
    // 'POWER_DENSITY': 'powerDensity'
}

const ReactBootstrapForm = styled(Form)`
 color: red;
 `;
const ScrollDiv = styled.div`
   overflow-y:scroll;
 `;

function adaptMonthFormation(value) {// example   2022-04  to  2022-4
    if (!value)
        return
    const b = value.split("-")
    if (b[1][0] === '0') {
        b[1] = b[1].replace("0", "") //delete the zero

    }
    return b.join().replace(",", "-");
}

function previousMonth(value) {// example   2022-04  to  2022-4

    if (!value)
        return
    var previous = new Date(value)
    previous.setMonth(previous.getMonth() - 1);
    var x = previous.toISOString().substring(0, 7)
    return x

}
const Div = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`;


// const Div = styled.div`
//   width: 100%;
//   height: 100%;
//   top:0;
//   left:0;
//   z-index: 1100;
//   background-color: rgba(255,255,255,0.4);
//   .classic__Wrapper-sc-1ycp7u6-0{
//     position: absolute;
//     top: calc(50% - 25px);
//     left: calc(50% - 25px);
//   }
// `;

function ChartsDashboard() {
    const [chartsData, setCartsData] = useState();
    const [date, setDate] = useState();
    const [monthsSequence, setMonthsSequence] = useState();
    const { placements } = usePlacementsActions();
    const project = useProject();
    const printRef = useRef(new Set());
    const { chartsStatistics, setChartsStatistics } = useChartsStatisticsActions();
    const { reportDocumentId, setReportDocumentId  } = useReportDocumentIdActions();
    // const { fetching, fetched, error } = useProjectStatistics()
    const [providers, setProviders] = useState();
    const [userPreferences,] = useUserPreferencesState();
    const [reports, setReports] = useState([]);
    const [pollingId, setPollingId] = useState();
    const [makingReportClicked, setMakingReportClicked] = useState(false);
    const selectRef = useRef(null);
    const [failedMakingReport, setFailedMakingReport] = useState(false);

    useEffect(() => {
        console.log(date)
    }, [date]);

    function getMinDate(project) {
        const showDataFrom = getInitialDateProjectCanAccessDataFrom(project);
        if (new Date(showDataFrom).toString() !== 'Invalid Date')
            return new Date(showDataFrom.getFullYear(), showDataFrom.getMonth()).toISOString().slice(0, 7)
    }

    function getLastMonthDate() {
        const today = new Date()
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        return new Date(todayYear, todayMonth - 1, 31).toISOString().slice(0, 7)// one month beack
    }

    function getMonthDiff(date1, date2) {
        if (!(date1 instanceof Date && date2 instanceof Date)) throw Error(`invalid arguments: ${{ date1, date2 }}`);
        let months;
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth();
        months += date2.getMonth();
        if (months === 0)
            return 1;
        return months < 0 ? 0 : months + 1;
    }

    function createSequenceOfMonths() {
        const projectCreatedAt = new Date(project.config?.dataAccess?.startFrom || new Date(new Date().getFullYear(), new Date().getMonth() - 1))
        const numberOfMonthsFromProjectCreation = getMonthDiff(projectCreatedAt, new Date())
        if (numberOfMonthsFromProjectCreation > 0) {
            const monthsFromProjectCreation = [...Array(numberOfMonthsFromProjectCreation).keys()].map((index) => new Date(new Date(projectCreatedAt).setMonth(new Date(projectCreatedAt).getMonth() + index)))// + 1 because months starts from 0 
            const monthsAsFormatedStrings = monthsFromProjectCreation.map(month => `${month.getFullYear()}-${month.getMonth() + 1}`)
            // const monthsBackwardsCration = [...Array(12 - numberOfMonthsFromProjectCreation).keys()].map((index) => new Date(new Date(projectCreatedAt).setMonth(new Date(projectCreatedAt).getMonth() - index - 1)))// + 1 because months starts from 0
            // const monthsBackwardsCreationStrings = monthsBackwardsCration.map(month => `${month.getFullYear()}-${month.getMonth() + 1}`).reverse()
            // const allYear = monthsBackwardsCreationStrings.concat(monthsAsFormatedStrings)
            setMonthsSequence(monthsAsFormatedStrings);
        }
    }
    async function startMakingReport(pollingId) {
        setFailedMakingReport(false)
        let pollId;
        if (pollingId) pollId = pollingId
        else {
            const ans = await Api.Project.getStatistics(project._id);
            pollId = ans.documentId
        }
        setPollingId(pollId)
    }

    async function onCompletePolling() {
        setPollingId(null)
        const reports = await Api.Project.getReports(project._id);
        reports.sort((date1, date2) => new Date(date2.timeStamp) - new Date(date1.timeStamp));
        setMakingReportClicked(false);
        if (reports[0].status.startsWith("failed")) {
            setFailedMakingReport(true);
            return;
        }
        const readyReports = reports.filter(i => i.status === "ready")
        setReports(readyReports)
        if (selectRef.current) {
            selectRef.current.value = readyReports[0]._id;
        }
        setReportDocumentId({
            status:"",
            documentId:""
        })
    }
    async function cheackIfReportExist() {
        const reports = await Api.Project.getReports(project._id);
        reports.sort((date1, date2) => new Date(date2.timeStamp) - new Date(date1.timeStamp)) //  
        const readyReports = reports.filter(i => i.status === "ready")
        setReports(readyReports)

        if (reportDocumentId.status === "pending"){
            await startMakingReport(reportDocumentId.documentId)
            setMakingReportClicked(true);
        }
        else if(readyReports.length < 1) 
            await startMakingReport()

        if (readyReports.length > 0) {
            const documentId = readyReports[0]._id; //get latest report
            //setPollingId(documentId); //maybe more correct way to separate polling here to
            const result = await Api.Project.gatherStatistics(project._id, documentId);
            setChartsStatistics({
                data: result.statistics,
                status: result.status,
                documentId: documentId
            })
        }
       
    }
    useEffect(() => {
        setCartsData({ placements: placements });
        createSequenceOfMonths();
        cheackIfReportExist();
        return () => {
            setChartsStatistics({
                date: [],
            })
            setPollingId(null)
        };
    }, []);

    useEffect(() => {
        if (project.createdAt)
            setDate(new Date().toISOString().slice(0, 7))
        // if (project.config.dataAccess.availableProviders)
        //     setProviders(project.config.dataAccess.availableProviders.map((provider) =>
        //         getProviderDisplayName(provider),
        //     ))
        if(chartsStatistics.status==="ready"){ //providers from report document
        let tendencyKeys = Object.keys(chartsStatistics.data.chartsStatistics.coverageReliabilityTendencyAllProviders[0]);
        tendencyKeys = tendencyKeys.filter(i => (i !== "month") && (i !== "year"));
        setProviders(tendencyKeys)
        }
    }, [chartsStatistics]);

    const handleDownloadImage = async () => {
        const imagesData = [];
        const myArr = Array.from(printRef.current)
        for (const element of myArr) {
            if (element !== null) {
                const elementId = element.id;
                const canvas = await html2canvas(element);
                const data = canvas.toDataURL('image/jpg');
                imagesData.push({ id: elementId, data: data })
            }
        }
        return imagesData
    };

    const generateChertsPdfData = async () => {
        const imagesArry = await handleDownloadImage();
        const coverageReliabilityImages = imagesArry.filter(obj => String(obj.id).split('-')[0] === chartsTypes.COVERAGE_RELIABILITY)
        const coveragePerformanceImages = imagesArry.filter(obj => String(obj.id).split('-')[0] === chartsTypes.COVERAGE_PERFORMANCE)
        const applicationQualityImages = imagesArry.filter(obj => String(obj.id).split('-')[0] === chartsTypes.APPLICATION_QUALITY)
        const coverageReliabilitTendencyImages = imagesArry.filter(obj => String(obj.id).split('-')[0] === chartsTypes.COVERAGE_RELIABILITY_TENDENCY)

        return {
            'coverageReliability': { imageData: coverageReliabilityImages },
            'coveragePerformance': { imageData: coveragePerformanceImages },
            'applicationQuality': { imageData: applicationQualityImages },
            'coverageReliabilitTendency': { imageData: coverageReliabilitTendencyImages },

        }
    }

    const isTypeDate = (dateType) => {
        return dateType === "date"
    }
    const isSignalRsrpType = (signalType) => {
        return signalType === "rsrp"
    }

    const filterPlacementCoverageReliability = (placements) => {
        const reliabilityData = placements.filter(placement => isTypeDate(placement.dateRange.dateType) && isSignalRsrpType(placement.signalType) && (placement.dateRange.date === adaptMonthFormation(date) || placement.dateRange.date === adaptMonthFormation(previousMonth(date))))
        const lineData = reliabilityData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date, provider: placement.provider }))
        return lineData
    }

    const filterPlacementCoveragePreformence = (placements) => {
        const preformenceData = placements.filter(placement => isTypeDate(placement.dateRange.dateType) && isSignalRsrpType(placement.signalType) && (placement.dateRange.date === adaptMonthFormation(date)))
        const cellcom = preformenceData.filter(placement => placement.provider === "cellcom" && placement.technology === "4G").map(placement => placement.bins)[0]
        const pelephone = preformenceData.filter(placement => placement.provider === "pelephone" && placement.technology === "4G").map(placement => placement.bins)[0]
        const partner = preformenceData.filter(placement => placement.provider === "partner" && placement.technology === "4G").map(placement => placement.bins)[0]
        const AreachartComponent = preformenceData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, provider: placement.provider }))

        const CoveragePreformenceGraph = {
            barRechartComponent:
            {
                cellcom,
                pelephone,
                partner
            },
            AreachartComponent
        }
        return CoveragePreformenceGraph
    }
    const filterApplicationQuality = (placements) => {
        const reliabilityData = placements.filter(placement => isTypeDate(placement.dateRange.dateType) && isSignalRsrpType(placement.signalType) && (placement.dateRange.date === adaptMonthFormation(date)))
        const lineData = reliabilityData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date, provider: placement.provider }))
        return lineData;
    }

    const filterCoverageReliabilityTendency = (placements, provider) => {
        const reliabilityData = placements.filter(placement => isTypeDate(placement.dateRange.dateType) && isSignalRsrpType(placement.signalType))
        const filterByProvider4g = (provider) => { return reliabilityData.filter(placement => placement.provider === provider && placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date })) }
        // const lineDataCellcom =filterByProvider4g("cellcom")
        // const lineDataPelephone =filterByProvider4g("pelephone")
        // const lineDataPartner =filterByProvider4g("partner")
        return filterByProvider4g(provider)

    }

    const filterCoverageReliabilityTendencyMedian = (placements) => {
        const reliabilityData = placements.filter(placement => isTypeDate(placement.dateRange.dateType) && isSignalRsrpType(placement.signalType))
        const filterBy4g = reliabilityData.filter(placement => placement.technology === "4G").map((placement) => ({ placements: placement.bins, date: placement.dateRange.date, provider: placement.provider }))

        // const lineDataCellcom =filterByProvider4g("cellcom")
        // const lineDataPelephone =filterByProvider4g("pelephone")
        // const lineDataPartner =filterByProvider4g("partner")
        return filterBy4g

    }

    //chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp"  && (placement.dateRange.date === adaptMonthFormation(date) || placement.dateRange.date === adaptMonthFormation( previousMonth(date) ) ) )
    //chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp"  &&  placement.dateRange.date === adaptMonthFormation(date))
    //chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp"  && (placement.dateRange.date === adaptMonthFormation(date)))
    //chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp")
    // const onClickCreatePdf = async()=>{
    //     const pdfData = await generateChertsPdfData();
    //     const pagesPdfAsReactElementArray = []
    //     const pagePdfAsReactElement = React.createElement(PagePdf1, {firstPage:true , data: pdfData[chartsTypes.COVERAGE_RELIABILITY]}, );
    //     pagesPdfAsReactElementArray.push(pagePdfAsReactElement)
    //     const pagePdfAsReactElement2 = React.createElement(PagePdf1, {firstPage:false , data: pdfData[chartsTypes.COVERAGE_RELIABILITY]}, );
    //     pagesPdfAsReactElementArray.push(pagePdfAsReactElement2)
    //     await createPdf(pagesPdfAsReactElementArray)
    // }

    const propsCoverageReliability = (placements) => {
        const lineData = filterPlacementCoverageReliability(placements);
        const date2 = adaptMonthFormation(date);
        const name = 'insetNameToCoverage';
        const ref = printRef;
        const props = {
            lineData,
            date: date2,
            name,
            // ref
        }
        return props
    }

    //CoveragePreformence
    const propsCoveragePreformence = (placements) => {
        const graphData = filterPlacementCoveragePreformence(placements);
        const prop = {
            barRechartComponent: graphData.barRechartComponent
        }
        return prop
    }
    const propsApplicationQuality = (placements) => {
        const lineData = filterApplicationQuality(placements);
        const prop = {
            lineData,
        }
        return prop
    }
    const onClickCreatePdfWithRef = async () => {
        const pdfData = propsCoverageReliability(chartsData.placements);
        const pagesPdfAsReactElementArray = []
        const pagePdfAsReactElement = React.createElement(PageTemplateRef, { firstPage: true, data: pdfData },);
        pagesPdfAsReactElementArray.push(pagePdfAsReactElement)
        const pagePdfAsReactElement2 = React.createElement(PageTemplateRef, { firstPage: true, data: pdfData },);
        pagesPdfAsReactElementArray.push(pagePdfAsReactElement2)
        await createPdf(pagesPdfAsReactElementArray)
    }

    const onClickCreateReportNow = async () => {
        setMakingReportClicked(true)
        startMakingReport();
    }

    const handleSelectReportItem = async (event) => {
        const documentId = event.target.value
        // setPollingId(documentId)
        const result = await Api.Project.gatherStatistics(project._id, documentId);
        setChartsStatistics({
            data: result.statistics,
            status: result.status,
            documentId: documentId
        })
    }

    const validateReportProviders = () => {
        let tendencyKeys = Object.keys(chartsStatistics.data.chartsStatistics.coverageReliabilityTendencyAllProviders[0]);
        tendencyKeys = tendencyKeys.filter(i => (i !== "month") && (i !== "year"));
        return providers.length === tendencyKeys.length // to do: compare names
    }

    const onClickPdf = async () => {
        var pdf = new jsPDF("P", "mm", "a4");
        const htmlPage1 = document.getElementById("page11");
        const imagePage1 = await domElementToImageData(htmlPage1);
        pdf.addImage(imagePage1, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

        pdf.addPage();
        const htmlPage2 = document.getElementById("page22");
        const imagePage2 = await domElementToImageData(htmlPage2);
        pdf.addImage(imagePage2, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

        pdf.addPage();
        const htmlPage3 = document.getElementById("page33");
        const imagePage3 = await domElementToImageData(htmlPage3);
        pdf.addImage(imagePage3, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

        pdf.save('download.pdf');

        // const pagesPdfAsReactElementArray = []
        // const pagePdfAsReactElement = React.createElement(PageTemplateRef, {firstPage:true , data: pdfData},);
        // pagesPdfAsReactElementArray.push(pagePdfAsReactElement)
        // const pagePdfAsReactElement2 = React.createElement(PageTemplateRef, {firstPage:true , data: pdfData},);
        // pagesPdfAsReactElementArray.push(pagePdfAsReactElement2)
        // await createPdf(pagesPdfAsReactElementArray)
    }
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            handleDownloadImage().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading]);


    const dateTostring = (dateString) => {
        const date = new Date(dateString)
        const y = date.getFullYear()
        const m = (date.getMonth() + 1).toString().padStart(2, "0")
        const d = date.getDate().toString().padStart(2, "0")
        const stringYMD = (`${y}-${m}-${d}`)
        return (stringYMD)
    }
    // useEffect(() => {
    //     console.log({ fetching, fetched, error })
    // }, [fetching, fetched, error]);
    return (
        <ScrollDiv style={{ background: "#D2D6DD", overflowY: "scroll" }}>
            <Container style={{ maxWidth: "1520px" }}   >
                <Row className="justify-content-center" >
                    <div className="justify-content-center" style={{ height: "auto", width: "auto", marginBottom: 10, marginTop: 10, background: "#6A6E79", padding: "5px 20px 5px 20px" }} >
                        <h4 style={{ marginBottom: 0, textAlign: "center", color: "white" }} >{project.name}</h4>
                    </div>
                </Row>
                {/* <Row className="justify-content-center">
                    <ReactBootstrapForm.Group className=".abc" style={{ height: "30px", width: "150px", background: "#EEEFF2" }} controlId="duedate">
                        <Form.Control
                            defaultValue={new Date().toISOString().slice(0, 7)}
                            type="month"
                            name="duedate"
                            placeholder="Due date"
                            value={date}
                            onChange={(e) => setDate((e.target.value))}
                            min={getMinDate(project)}
                            max={new Date().toISOString().slice(0, 7)}
                        />
                    </ReactBootstrapForm.Group>
                </Row> */}
                <Row className="justify-content-md-center" style={{ marginBottom: 10, marginTop: 10 }}>
                    <Col xs lg="2" style={{ width: "11%" }}>
                        <div  >
                            <Button style={{ backgroundColor: "secondary", borderRadius: "5px", border: "none" }} variant="primary"
                                disabled={makingReportClicked}
                                onClick={() => onClickCreateReportNow()}>
                                Generate New Report
                            </Button>
                        </div>
                    </Col>
                    <Col xs lg="2" style={{ width: "11%" }}>
                        {(reports) &&
                            <Form.Select aria-label="Default select example" ref={selectRef} onChange={handleSelectReportItem} >
                                {reports.map((item, index) => (
                                    <option
                                        key={index} value={item._id}> {dateTostring(item.timeStamp)}  </option>
                                ))}
                            </Form.Select>}
                    </Col>
                </Row>
                <Row>
                    {(pollingId) &&
                        <PollingCharts createdAt={project.createdAt} pollingName={REPORT} projectId={project._id} pollingId={pollingId} onComplete={() => onCompletePolling()}> </PollingCharts>
                    }
                    {failedMakingReport && <div style={{ justifyContent: "center", display: "flex", color: 'red', fontSize: '20px' }}>An error occurred generating the report</div>}
                    {(!(chartsStatistics.status === "ready") && !failedMakingReport) && (
                        <Div>
                            {" "}
                            <Loader route={REPORT} />
                            {/* <ClassicSpinner size={50} color="#686769" loading={true}/>   */}
                        </Div>
                    )}
                    {/* {providers && chartsStatistics.status === "ready" && !validateReportProviders() && <div>Report providers do not compatible with project providers</div>} */}
                    {chartsStatistics.status === "ready" && chartsData && monthsSequence && providers && <Container fluid>
                        <Row style={{ marginTop: "10px" }}>
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2  > Coverage Reliability</h2></Card.Header>
                                <Card.Body>
                                    <CoverageReliability providers={providers} data={chartsStatistics.data.chartsStatistics.coverageReliability.filter(i => true)} ref={printRef} date={adaptMonthFormation(date)} />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row >
                            <Card border="info" style={{ marginTop: "20px", width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2> Coverage Performance</h2></Card.Header>
                                <Card.Body >
                                    <CoveragePerformance providers={providers} data={{
                                        coveragePerformance: chartsStatistics.data.chartsStatistics.coveragePerformance.filter(i => i.date === "latest"),
                                        coveragePerformanceBestServer: chartsStatistics.data.chartsStatistics.coveragePerformanceBestServer.filter(i => i.date === "latest"),
                                        coveragePerformanceDistribution: chartsStatistics.data.chartsStatistics.coveragePerformanceDistribution.filter(i => i.date === "latest")
                                    }}
                                        ref={printRef} />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: "20px", marginBottom: "20px", }}>
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2 > Application Quality </h2></Card.Header>
                                <Card.Body>
                                    <ApplicationQuality providers={providers} data={chartsStatistics.data.chartsStatistics.applicationQuality.filter(i => i.date === "latest")} date={adaptMonthFormation(date)} ref={printRef} />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: "20px", marginBottom: "20px", }}>
                            <Card border="info" style={{ width: "100%", borderRadius: "30px" }}  >
                                <Card.Header style={{ textAlign: "center" }}> <h2 > Coverage Reliability Tendency </h2></Card.Header>
                                <Card.Body>
                                    <CoverageReliabilityTendency providers={providers} data={{ coverageReliabilityTendency: chartsStatistics.data.chartsStatistics.coverageReliabilityTendency, coverageReliabilityTendencyAllProviders: chartsStatistics.data.chartsStatistics.coverageReliabilityTendencyAllProviders }} date={adaptMonthFormation(date)} ref={printRef} monthsSequence={monthsSequence} />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Container>

                    }
                </Row>
                {/* {chartsData && monthsSequence && <>
                    <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
                        <PageTemplateRef idd={'page11'} firstPage  >
                            <Container fluid >
                                <p style={{ fontSize: '30px', marginTop: '20px', textDecorationLine: 'underline', textAlign: 'center' }} class={"text-center font-weight-bold"}>Wireless Applications Quality Report</p>
                                <p style={{ fontSize: '17px', lineHeight: '22px', textAlign: 'center' }}>
                                    Flycomm is managing the wireless application quality in the municipal area.
                                    Communication resource is the economic growth engine for the residents and the smart city.
                                    Field measurements are being collected on a regular basis, processed and analysed.
                                </p>
                                <p style={{ fontSize: '24px', marginTop: '28px' }} class={"text-center"}  >{project.name}</p>
                                <p style={{ fontSize: '20px', marginTop: '8px' }} class={"text-center"}  >{date}</p>
                                <p style={{ fontSize: '22px', marginTop: '22px', textDecorationLine: 'underline' }} class={"text-center"}  >Coverage Reliability</p>
                                <div style={{ width: '481px', height: '286px', margin: '0 auto' }}>
                                    <CoveragReliabilityTableTemp lineData={propsCoverageReliability(chartsData.placements).lineData} date={adaptMonthFormation(date)} name={'insetNameToCoverage'} />
                                </div>
                                <p style={{ fontSize: '22px', marginTop: '60px', textDecorationLine: 'underline' }} class={"text-center"}  >Coverage Performance</p>
                                <Row>
                                    <Col style={{ maxWidth: '50%' }} >
                                        <Row>
                                            <Col>
                                                <p style={{ fontSize: '16px' }} class="text-center">{'Best server'}</p>
                                            </Col>
                                        </Row>
                                        <Row style={{ margin: 'auto 0%' }}>
                                            <BarRechartComponentTemp width={350} height={180} barData={calcBestserver(chartsData.placements.filter(placement => placement.dateRange.dateType === "date" && placement.signalType === "rsrp" && placement.dateRange.date === adaptMonthFormation(date) && placement.technology === "4G"))} />
                                        </Row>
                                    </Col>
                                    <Col style={{ maxWidth: '50%' }} >
                                        <Row>
                                            <Col>
                                                <p style={{ fontSize: '16px' }} class="text-center">{'Distribution'}</p>
                                            </Col>
                                        </Row>
                                        <Row style={{ margin: 'auto 0%' }}>
                                            <AreachartComponentTemp width={350} height={190} areaData={filterPlacementCoveragePreformence(chartsData.placements).AreachartComponent} />
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </PageTemplateRef>

                        <PageTemplateRef idd={'page22'}>
                            <Container fluid>
                                <Row>
                                    <Row style={{ margin: 'auto 33%' }}>
                                        <Col >
                                            <Row>
                                                <Col>
                                                    <p style={{ fontSize: '16px' }} class="text-center">{'Cellcom-4G'}</p>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <BarRechartComponentTemp width={350} height={200} barData={propsCoveragePreformence(chartsData.placements).barRechartComponent.cellcom} />
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: 'auto 33%' }}>
                                        <Col >
                                            <Row>
                                                <Col>
                                                    <p style={{ fontSize: '16px' }} class="text-center">{'Pelephone-4G'}</p>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <BarRechartComponentTemp width={350} height={200} barData={propsCoveragePreformence(chartsData.placements).barRechartComponent.pelephone} />
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row style={{ margin: 'auto 33%' }}>
                                        <Col  >
                                            <Row>
                                                <Col>
                                                    <p style={{ fontSize: '16px' }} class="text-center">{'Partner-4G'}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <BarRechartComponentTemp width={350} height={200} barData={propsCoveragePreformence(chartsData.placements).barRechartComponent.partner} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                            </Container>
                        </PageTemplateRef>
                        <PageTemplateRef idd={'page33'} >
                            <p style={{ fontSize: '22px', marginBottom: '16px', textDecorationLine: 'underline' }} class={"text-center"}  >Application Quality</p>
                            <ApplicationQualityTableTemp lineData={propsApplicationQuality(chartsData.placements).lineData} />
                            <p style={{ fontSize: '22px', marginTop: '20px', textDecorationLine: 'underline' }} class={"text-center"}  >Coverage Reliability Tendency</p>
                            <Row style={{ justifyContent: 'center' }}>
                                <LinechartComponentMedianTemp width={630} height={320} lineData={filterCoverageReliabilityTendencyMedian(chartsData.placements)} monthsSequence={monthsSequence} />
                            </Row>
                        </PageTemplateRef>
                    </div>
                </>
                } */}
            </Container>
        </ScrollDiv >

    );
}
export default ChartsDashboard;
