import { TECHNOLOGYS_BANDS, mapLayerValues } from "../../controller/Constants";
export const DEFAULT_LOCATION_STATS = {
  display: false,
  location: {},
  closestBinLocation: { lat: 0, lng: 0 },
  rssi: 0,
  bitRate: 0,
  address: 0,
  distance: 0, // from the closest bin.
};

export const DEFAULT_USER_PREFERENCES = {
  providers: [],
  technologies: ["4G"],
  relativeDateRange: { dateType: "date", date: null },
  polygon: { type: "Polygon", coordinates: [] },
  signalsType: ["rsrp"],
  bands: [],

};
