
import React from "react";
import { usePredictionState } from "../../../Hooks/common";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";



function PredictionModeSwitch({ mapLayer, handlePrediction, predictionPermission }) {
    // TODO: remove magic strings to constants
    const [predictionState, setPredictionState] = usePredictionState();
    const activeValue = predictionState ? "prediction" : "samples";
    const values = [
        {
            value: "prediction",
            label: "Prediction",
            active: predictionState,
            disabled: mapLayer === "heatmap" || !predictionPermission,
        },
        {
            value: "samples",
            label: "Samples",
            active: !predictionState,
            disabled: false,
        }
    ]

    const onChange = (val) => {
        const isPredictionActive = val === "prediction" ? true : false;
        setPredictionState(isPredictionActive);
        if (isPredictionActive) handlePrediction()
    }

    return <ToggleButtonGroup name="predictionSamplesToggle" type="radio" value={activeValue} onChange={onChange}>
        {values.map(option => (
            <ToggleButton
                id={option.value}
                key={option.value}
                disabled={option.disabled}
                value={option.value}
                variant={option.active ? "primary" : "secondary"}>
                {option.label}
            </ToggleButton >
        ))}
    </ToggleButtonGroup>

}

export default PredictionModeSwitch;