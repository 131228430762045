import styled from "styled-components";
import PercentProgress from "./Common/Progress";
import Badge from "react-bootstrap/Badge";

export const StyledProgress = styled(PercentProgress)`
  //max-width: 100px;
  flex-grow: 1;
  margin: 5px 10px;
  width: 100px;
`;
export const Li = styled.li`
  &:not(:first-of-type) {
    margin-left: 10px;
  }
`;
export const Div = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
  padding-top: 2px;
  min-width: 100px;
  width: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
export const A = styled.a`
  margin-left: 10px;
  &.btn-outline-secondary {
    box-shadow: none;
    color: ${({theme}) => theme.palette.primary.dark};
    border-color: ${({theme}) => theme.palette.primary.dark};
    border-width: 1px;
    &:hover {
      box-shadow: none;
      color: ${({theme}) => theme.palette.common.white};
      background-color: ${({theme}) => theme.palette.primary.main};
      border-color: ${({theme}) => theme.palette.primary.main};
    }
    &:not(:disabled):not(.disabled):active {
      box-shadow: none;
      background-color: ${({theme}) => theme.palette.primary.dark};
      border-color: ${({theme}) => theme.palette.primary.dark};
      transform: translateY(0);
    }

    ${({active, theme}) =>
      active &&
      `
      box-shadow: none;
      background-color: ${theme.palette.primary.dark};
      border-color: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
      transform: translateY(0);
  `}
  }
  &&&.a-disabled,
  &&&.a-disabled:active {
    cursor: context-menu;
    pointer-events: none;
    background-color: #f1f3f4;
    color: #c1c1c1;
    border-color: #c1c1c1;
    /* background-color: ${({theme}) => theme.palette.primary.dark}; */
    &:hover {
      /* background-color: #413c4a; */
      /* color: blue; */
      /* color: #e8e8e8; */
    }
  }
`;
export const StyledBadge = styled(Badge)`
  &.badge {
    margin: 3px 10px 7px 10px;
    padding: ${({padding}) => (padding ? "5px" : "")};
    background-color: ${({variant, color, theme}) => (Boolean(theme.palette[variant]) ? theme.palette[variant].main : color)} !important;
  }
`;
export const StyledBadgeCost = styled(Badge)`
  margin: 3px 10px 7px 10px;
  cursor: pointer;
`;
export const NavbarHeader = styled.header`
  &#page-header {
    .navigation i.nav-icon {
      color: ${({theme}) => theme.palette.secondary.dark};
      opacity: 1;
    }

    .cellular-icon {
      margin-button: 2px;
    }
    .img-icon {
      width: 13px;
      border: 0.5px black solid;
      /* padding-bottom: 2px; */
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }
`;
