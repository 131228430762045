import Constants from "../../../controller/Constants";
import { deepCopy } from "../../../utils/common";
const { KM_TO_LAT_LNG } = Constants;

export const convertDataToGeoJson = (data = {}) => {
  // const { bins, binResolution, algorithm, display } = data || {};
  return convertBinsToGeoJson(data);
};

const convertBinsToGeoJson = ({ bins, binResolution, algorithm, label }) => {
  const geoJson = {
    type: "FeatureCollection",
    features: [],
  };
  debugger;
  if (bins) {
    for (const group of bins.filter((group) => group.signal !== null)) {
      geoJson.features.push(
        getGeometry({
          center: group.location,
          radius: binResolution,
          color: group.color,
          algorithm,
          label: group.label,
          bins: group.clusteredBins,
          signal: group.signal,
          providers: group.providers ?? [],
        })
      );
    }
  }

  return geoJson;
};

const getGeometry = ({
  center,
  radius,
  color,
  algorithm,
  label,
  bins,
  signal,
  providers,
}) => {
  const algorithmToPolygonPathMapper = {
    kmeans: getPolygonCornersFromCenter,
    "static-clustering": getCircleAsPolygon,
    propagation: getPolygonCornersFromCenter,
  };
  const algorithmTostyleTypeMapper = {
    kmeans: "prediction",
    "static-clustering": "sample",
    propagation: "prediction",
  };

  const feature = {
    type: "Feature",
    id: JSON.stringify(center),
    properties: {
      color,
      zIndex: 99999,
      display: {
        bins: bins,
        center: center,
        label: label,
        signal: signal,
        providers: providers,
        color,
      },
      styleType: algorithmTostyleTypeMapper[algorithm],
    },
    geometry: {
      type: "Polygon",
      coordinates: [[]],
    },
  };
  const getPolygonPath = algorithmToPolygonPathMapper[algorithm];
  const featureCopy = deepCopy(feature);
  const polygon = getPolygonPath({ center, radius });
  const geoJsonPolygon = polygon.map((point) => latLngToGeoJsonCoord(point));
  featureCopy.geometry.coordinates[0].push(...geoJsonPolygon);
  return featureCopy;
};

const latLngToGeoJsonCoord = ({ lng, lat }) => [lng, lat];

const getPolygonCornersFromCenter = ({ center, radius }) => {
  const ADDITION_TO_SOLVE_GOOGLE_MAPS_GEOMETRY_CALCULATIONS_PROBLEM_THAT_GENERATE_GAPS_BETWEEN_SHAPES = 1.001;
  const step =
    ((KM_TO_LAT_LNG * (radius / 2)) / 1000) *
    ADDITION_TO_SOLVE_GOOGLE_MAPS_GEOMETRY_CALCULATIONS_PROBLEM_THAT_GENERATE_GAPS_BETWEEN_SHAPES;
  const lng_step = step / Math.cos(toRadians(center.lat));

  const polygonCorners = [
    { lat: center.lat - step, lng: center.lng - lng_step },
    { lat: center.lat + step, lng: center.lng - lng_step },
    { lat: center.lat + step, lng: center.lng + lng_step },
    { lat: center.lat - step, lng: center.lng + lng_step },
  ];
  return [...polygonCorners, polygonCorners[0]]; // last === first
};

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

const getCircleAsPolygon = ({ center, radius }) => {
  const numOfVertexs = 18;
  const step = (KM_TO_LAT_LNG * (radius / 2)) / 1000;
  const lng_step = step / Math.cos(toRadians(center.lat));
  const polygonCorners = [];
  for (let i = 0; i < 360; i += 360 / numOfVertexs) {
    polygonCorners.push({
      lat: center.lat + Math.sin(toRadians(i)) * step,
      lng: center.lng + Math.cos(toRadians(i)) * lng_step,
    });
  }
  return [...polygonCorners, polygonCorners[0]]; // last === first
};
