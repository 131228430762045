import React, { forwardRef } from "react";
import FileManager from "./FileManager";

function PolygonFileManager({ polygonPath, getPolygonPath, setPolygonPath, projectName, centerMapAccordingToPolygon }, ref) {

  function updatePolygon(polygonPath) {
    const parsedPolygonPath = JSON.parse(polygonPath);
    setPolygonPath(parsedPolygonPath);
    centerMapAccordingToPolygon();
  }

  function getFileName({ length }) {
    return `polygon-${length}-points-${projectName || ""}-${new Date(Date.now())
      .toLocaleString()
      .split(",")[0]
      .replaceAll(".", "-")}.json`;
  }

  return <FileManager
    ref={ref}
    getFileName={() => getFileName({ length: getPolygonPath().length })}
    fileName={getFileName({ length: polygonPath.length })}
    getData={getPolygonPath}
    data={polygonPath}
    onFileChange={updatePolygon}
    accept=".json" />;
}

export default forwardRef(PolygonFileManager);