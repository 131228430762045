import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCities } from "../store/actionCreators/general";
import Api from "../controller/ApiManager";

export function useCities() {

    const dispatch = useDispatch()
    const allCities = useSelector(state => state.general.cities);

    const fetchAndSet = async () => {
            if (allCities.length === 0) {
                const cities = await Api.City.getCities()
                dispatch(setCities(cities))
            }
    };
    useEffect(() => {
        fetchAndSet()
    }, [dispatch])

    return allCities
}

