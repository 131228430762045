import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import useProject from "../../Hooks/useProject";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import styled from "styled-components";
import Api from "../../controller/ApiManager/index";
import {setProject} from "../../store/actionCreators/general";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
import Button from "../Common/Buttons/Button";
import EventEmitter from "../../controller/EventEmitter";
import {events} from "../../controller/Constants";
import {addToast} from "../../store/actionCreators/general";

const ModalContent = styled.div`
  width: 30vw;
`;

const Div = styled.div`
  height: 10px;
`;

const StyledP = styled.div`
  margin-bottom: 0px;
  padding: 0px 20px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FinishButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

function Cluster({onClose: closeDialog, onBack, addToast, setProject, ...props}) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    error: false,
  });
  const project = useProject();
  async function handleCurrentLayer() {
    setLoading(true);
    try {
      const clusterResult = await Api.Project.clusterProject(project._id);
      if (typeof clusterResult === "object" && clusterResult.success) {
        console.log("before setProject 14");
        setProject(clusterResult.project);
        setAlert({message: "Successful change!", error: false});
      } else {
        throw Error("There was problem on the server clustering the project");
      }
    } catch (e) {
      setAlert({
        message: "Error! try refreshing the page...",
        error: true,
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function handleAllLayers() {
    setLoading(true);
    try {
      const clusterResult = await Api.Project.clusterProject(project._id);
      if (typeof clusterResult === "object" && clusterResult.success) {
        console.log("before setProject 13");
        setProject(clusterResult.project);
        setAlert({message: "current map layer is clustered, you can keep working meanwhile...", error: false});
        EventEmitter.dispatch(events.REARRANGE_ALL_LAYERS, {exceptMapLayer: project.currentMapLayer});
      } else {
        throw Error("there were problem on the server clustering the project");
      }
    } catch (e) {
      setAlert({
        message: "error on server! try refreshing the page... (or contact to administrators.)",
        error: true,
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{"Rearrange Bins"}</h2>
          {typeof onBack === "function" && <BackButton className="btn btn-outline-light btn-rounded" onClick={onBack} />}
        </div>
      </Modal.Header>
      <Modal.Body>
        {alert.message && <Alert variant={alert.error ? "danger" : "success"}>{alert.message}</Alert>}
        {loading ? <ProgressBar animated variant="warning" now={100} /> : <Div />}
        {loading ? <p>this action might take a while so please be patient</p> : ""}
        {alert.message === "Successful change!" ? ( // Adir - this is anti patern. to use constant?
          <FinishButtonGroup>
            <Button onClick={closeDialog} variant={""} color={"primary"} type={"submit"}>
              <StyledP> Finish </StyledP>
            </Button>
          </FinishButtonGroup>
        ) : (
          <ButtonsGroup>
            <Button onClick={handleCurrentLayer} type={"submit"} color={"primary"} variant={""}>
              {"Rearrange current map layer"}
            </Button>
            <Button onClick={handleAllLayers} type={"submit"} color={"secondary"} variant={"outline"}>
              {"Rearrange all map layers"}
            </Button>
          </ButtonsGroup>
        )}
      </Modal.Body>
    </ModalContent>
  );
}

export default connect(() => ({}), {addToast, setProject})(Cluster);
