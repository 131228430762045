import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import { resetRelativeDateRange } from "../controller/common";
import { mapLayerValues } from "../controller/Constants";
import {setUserPreferences} from "../store/actionCreators/map";
import { DEFAULT_USER_PREFERENCES } from "../store/defaultValues/map";

const EMPTY_USER_PREFERENCES = {
  providers: [],
  technologies: [],
  relativeDateRange: {
    dateType: "",
    date:"",
  },
  polygon: {type: "Polygon", coordinates: []},
  currentMapLayer: mapLayerValues.CELLULAR,
};

function useUserPreferences() {
  const userPreferences = useSelector((state) => state.map.userPreferences);
  return userPreferences;
}

function useSetUserPreferences() {
  const dispatch = useDispatch();
  return useCallback((userPreferences) => dispatch(setUserPreferences(userPreferences)), [dispatch]);
}

function useClearUserPreferences() {
  const setUserPreferences = useSetUserPreferences();
  const userPreferencesCopy = JSON.parse(JSON.stringify(EMPTY_USER_PREFERENCES));
  const relativeDateRange = resetRelativeDateRange();
  return useCallback(() => {

    setUserPreferences({...userPreferencesCopy, relativeDateRange})}, [setUserPreferences]);

}

function useUserUpdateData() {
  const setUserPreferences = useSetUserPreferences();
  const userPreferences = useUserPreferences();
  return useCallback((_) => {
    setUserPreferences({...userPreferences});
  });
}

export function useUserPreferencesState() {
  return [useUserPreferences(), useSetUserPreferences()];
}
export function useUserPreferencesActions() {
  return {
    clearUserPreferences: useClearUserPreferences(), 
    updateUserData: useUserUpdateData(),
  };
}
