import React, { useRef } from "react";
import PolygonFileManager2 from "../../Common/PolygonFileManager2"; // 
import UserPolygon from "./components/UserPolygon";
import ProjectPolygon from "./components/ProjectPolygon";
import PolygonControls from "./components/PolygonControls";
import { usePolygonsIntersection } from "./hooks/usePolygonsIntersection";
import { getPath } from "../../../utils/googleMapsApi/Polygon";
import { useUserPolygonControls } from "./hooks/useUserPolygonControls";

function PolygonsOverlay({ onPolygonChange, projectPolygon, projectName, projects, projectId, userPolygonDisplayed, userPolygonInEditMode, toggleUserPolygonEditMode }) { // todo: take the need for project name and give filetodownload name

    const polygonFileManagerRef = useRef(null);
    const userPolygonRef = useRef(null);


    const {
        intersectedPolygon,
        setIntersectedPolygon,
        resetPolygonToCenter,
        clearPolygon

    } = usePolygonsIntersection({ userPolygonInEditMode, userPolygonDisplayed, userPolygonRef, onPolygonChange, projectPolygon });

    const showProjectPolygon = !userPolygonDisplayed || userPolygonInEditMode || intersectedPolygon.length === 0;

    return (
        <>
            <UserPolygon
                displayed={userPolygonDisplayed}
                inEditMode={userPolygonInEditMode}
                polygonParentRef={userPolygonRef}
                path={intersectedPolygon}
            />
            {projectPolygon && projects && projects.map((item, index) => (
                <ProjectPolygon
                    key={index} currentProject={projectId} projectId={item._id} currentProjectPolygon={projectPolygon} visible={showProjectPolygon} polygonPath={projects.length > 1 ? item.polygon.coordinates[0].map((point) => ({ lat: point[1], lng: point[0] })) : projectPolygon} />
            ))}
            {userPolygonDisplayed && <PolygonControls
                inEditMode={userPolygonInEditMode}
                toggleEditMode={toggleUserPolygonEditMode}
                handleReset={resetPolygonToCenter}
                handleDelete={clearPolygon}
                polygonFileManagerRef={polygonFileManagerRef}

            />
            }
            {userPolygonDisplayed && <PolygonFileManager2
                ref={polygonFileManagerRef}
                polygonPath={intersectedPolygon}
                setPolygonPath={setIntersectedPolygon}
                getPolygonPath={() => getPath(userPolygonRef.current)}
                projectName={projectName}
            />}
        </>

    );
}

export default PolygonsOverlay;