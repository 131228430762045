// TODO: trow error validations
// TODO: single config => // config: algo to styleTpe + setStyleTpe
// TODO: gather magic string to constants

export const clearGeoJsonData = (dataLayer) => {
    dataLayer.forEach((feature) => {
        dataLayer.remove(feature);
    });
    // dataLayer.setMap(null);
}

export const drawGeoJsonData = (dataLayer, data) => {
    const options = {};
    dataLayer.addGeoJson(data, options);
    // dataLayer.setMap(map); // ????????????????????????????
    // map.data.addGeoJson(data, options);
}
export const clearGeoJsonDataStyle = (dataLayer) => {
    dataLayer.setStyle(null);
}
export const initDataLayer = (dataLayer, map) => {
    dataLayer.setMap(map);
}

export const initDataLayerStyle = (dataLayer, getStyle) => {
    dataLayer.setStyle((feature) => {
        return getStyle(feature);
    })
}

// suld the parent container set the link to the map instance and then send the layer here or shuld this component do it??????????????????????

export const setGeoJsonDataStyle = (dataLayer, getStyle) => {
    if (!getStyle) dataLayer.setStyle(null);

    dataLayer.setStyle((feature) => {
        return getStyle(feature);
    })
    // map.data.setStyle((feature) => {

    //     const styleType = feature.getProperty('styleType');
    //     const getStyle = styleConfig[styleType];

    //     return getStyle(feature);
    // })
}