import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './css/bootstrap.css'
import './css/style.css'
import './css/style-custom.scss'
import Loader from "./components/Loaders/Loader";
import './i18n'
import './configuations/builtIns'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Suspense fallback={<Loader/>}>
        <App/>
    </Suspense>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
