import Table from 'react-bootstrap/Table';
import React, { forwardRef, useEffect, useState } from "react";
import { defaultTechnologiesCoverage, techUsageCategoriesThresholds } from '../../../controller/Constants';
import styled from "styled-components";

const TableStyle = styled(Table)`
&&{
  &.table{
    // margin-bottom: 0px;
     table-layout: fixed; //prevent tables from expanding beyond their parent div
    text-align: center;
    font-size: 16px;
  }
  td{
    padding: 2px;
    height: 60px;
    vertical-align: middle;
  }
}


 `;

function ApplicationQualityTableTemp({ lineData, date, }) {

  const [tableData, setTableData] = useState();
  function getTechnologiesCoverage(binsArray) {
    function calcCoverageForCategorys(accm, binObject) {
      let tech = "4G";
      // if (useUserPreferences.technologies.includes("4G")) tech = "4G";
      for (const [category, threshold] of Object.entries(
        techUsageCategoriesThresholds[tech]
      )) {
        const {forColor: colorThreshold, forStars: starsThreshold} = threshold;
        if (binObject.signal >= colorThreshold) accm[category]["forColor"] += 1;     
       }
      return accm;
    }

    if (binsArray.length > 0) {
      const techThresholds = binsArray.reduce(calcCoverageForCategorys, {
        ...JSON.parse(JSON.stringify(defaultTechnologiesCoverage)) , // deepCopy to common
      });
      const technologiesCoverage = { ...techThresholds };
      for (const [category, value] of Object.entries(technologiesCoverage)) {
        technologiesCoverage[category]["forColor"] = Number(
          ((value["forColor"] / binsArray.length) * 100).toFixed()
        );
      }
      return technologiesCoverage;
    } else {
      return { ...defaultTechnologiesCoverage };
    }
  }

  const dataStruct =
  {
    cellcom: {

      "Email & Text message": 0,
      "Voice call": 0,
      "Audio app": 0,
      "Download files": 0,
      "Web browsing": 0,
      "Video sharing": 0,
      "Streaming app": 0,
      "Gaming": 0,
    },
    pelephone: {
      "Email & Text message": 0,
      "Voice call": 0,
      "Audio app": 0,
      "Download files": 0,
      "Web browsing": 0,
      "Video sharing": 0,
      "Streaming app": 0,
      "Gaming": 0,

    },
    partner: {

      "Email & Text message": 0,
      "Voice call": 0,
      "Audio app": 0,
      "Download files": 0,
      "Web browsing": 0,
      "Video sharing": 0,
      "Streaming app": 0,
      "Gaming": 0,

      // General: -108.75,
      // Email: 0,
      // VoiceCall: 0,
      // AudioApp: 0,
      // DownloadFiles: 0,
      // WebBrowsing: 0,
      // VideoSharing: 0,
      // StreamingApp: 0,
      // Gaming: 0,

    }
  }


  function extractData(linData) {
    if (linData !== undefined) {
      lineData.forEach(function (item, index) {
        console.log(item.placements, index);
        // const month = item.date.split("-")[1]
        // let listOfAllSignals = item.placements.map(placements => placements.signal)
        const technologiesCoverage = getTechnologiesCoverage(item.placements)
        switch (item.provider) {
          case "cellcom":
            dataStruct.cellcom['Email & Text message'] = technologiesCoverage['Email & Text message']["forColor"];
            dataStruct.cellcom['Voice call'] = technologiesCoverage['Voice call']["forColor"];
            dataStruct.cellcom['Audio app'] = technologiesCoverage['Audio app']["forColor"];
            dataStruct.cellcom['Download files'] = technologiesCoverage['Download files']["forColor"];
            dataStruct.cellcom['Web browsing'] = technologiesCoverage['Web browsing']["forColor"];
            dataStruct.cellcom['Video sharing'] = technologiesCoverage['Video sharing']["forColor"];
            dataStruct.cellcom['Streaming app'] = technologiesCoverage['Streaming app']["forColor"];
            dataStruct.cellcom['Gaming'] = technologiesCoverage['Gaming']["forColor"];
            break;
          case "pelephone":
            dataStruct.pelephone['Email & Text message'] = technologiesCoverage['Email & Text message']["forColor"];
            dataStruct.pelephone['Voice call'] = technologiesCoverage['Voice call']["forColor"];
            dataStruct.pelephone['Audio app'] = technologiesCoverage['Audio app']["forColor"];
            dataStruct.pelephone['Download files'] = technologiesCoverage['Download files']["forColor"];
            dataStruct.pelephone['Web browsing'] = technologiesCoverage['Web browsing']["forColor"];
            dataStruct.pelephone['Video sharing'] = technologiesCoverage['Video sharing']["forColor"];
            dataStruct.pelephone['Streaming app'] = technologiesCoverage['Streaming app']["forColor"];
            dataStruct.pelephone['Gaming'] = technologiesCoverage['Gaming']["forColor"];
            break;
          case "partner":
            dataStruct.partner['Email & Text message'] = technologiesCoverage['Email & Text message']["forColor"];
            dataStruct.partner['Voice call'] = technologiesCoverage['Voice call']["forColor"];
            dataStruct.partner['Audio app'] = technologiesCoverage['Audio app']["forColor"];
            dataStruct.partner['Download files'] = technologiesCoverage['Download files']["forColor"];
            dataStruct.partner['Web browsing'] = technologiesCoverage['Web browsing']["forColor"];
            dataStruct.partner['Video sharing'] = technologiesCoverage['Video sharing']["forColor"];
            dataStruct.partner['Streaming app'] = technologiesCoverage['Streaming app']["forColor"];
            dataStruct.partner['Gaming'] = technologiesCoverage['Gaming']["forColor"];
          default:
        }

      });

      setTableData(dataStruct)
    }
  }
  useEffect(() => {
    extractData(lineData)
  }, [lineData]);

  useEffect(() => {

    console.log("tableData---", tableData)


  }, [tableData]);

  return (
    <div style={{display:'table-cell'}}  >
      {tableData && <TableStyle class="table table-image" hover >
        <thead>
          <tr>
            <th>Application</th>
            <th>Cellcom</th>
            <th>Pelephone</th>
            <th>Partner</th>
          </tr>
        </thead>

        <tbody  >
          <tr>
            <td>Email & Text message</td>
            <td>{tableData.cellcom['Email & Text message']} %</td>
            <td>{tableData.pelephone['Email & Text message']} %</td>
            <td>{tableData.partner['Email & Text message']} %</td>
          </tr>
          <tr>
            <td>Voice call</td>
            <td>{tableData.cellcom['Voice call']} %</td>
            <td>{tableData.pelephone['Voice call']} %</td>
            <td>{tableData.partner['Voice call']} %</td>
          </tr>

          <tr>
            <td>Audio app</td>
            <td>{tableData.cellcom['Audio app']}%</td>
            <td>{tableData.pelephone['Audio app']}%</td>
            <td>{tableData.partner['Audio app']}%</td>
          </tr>

          <tr>
            <td>Download files</td>
            <td>{tableData.cellcom['Download files']}%</td>
            <td>{tableData.pelephone['Download files']}%</td>
            <td>{tableData.partner['Download files']}%</td>
          </tr>

          <tr>
            <td>Web browsing</td>
            <td>{tableData.cellcom['Web browsing']}%</td>
            <td>{tableData.pelephone['Web browsing']}%</td>
            <td>{tableData.partner['Web browsing']}%</td>
          </tr>

          <tr>
            <td>Video sharing</td>
            <td>{tableData.cellcom['Video sharing']}%</td>
            <td>{tableData.pelephone['Video sharing']}%</td>
            <td>{tableData.partner['Video sharing']}%</td>
          </tr>

          <tr>
            <td>Streaming app</td>
            <td>{tableData.cellcom['Streaming app']}%</td>
            <td>{tableData.pelephone['Streaming app']}%</td>
            <td>{tableData.partner['Streaming app']}%</td>
          </tr>


          <tr>
            <td>Gaming</td>
            <td>{tableData.cellcom['Gaming']}%</td>
            <td>{tableData.pelephone['Gaming']}%</td>
            <td>{tableData.partner['Gaming']}%</td>
          </tr>




          {/* <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr> */}
        </tbody>
      </TableStyle>}
    </div>
  );
}

export default ApplicationQualityTableTemp;