import { useEffect } from "react";
import Api from "../../controller/ApiManager";
import {
  algorithmsTypes,
  environmentFactor,
  mapLayerValues,
  timeOfDayFactor,
  floorsAlgo,
  mapLayerTypes,
  SIGNALS,
  featuresPermissions,
} from "../../controller/Constants";
import {
  usePredictionState,
  useFetchLoader,
  usePrevious,
} from "../../Hooks/common";
import {
  useIndoorFloorEnvState,
  useUserEnvironment,
} from "../../Hooks/useUserEnvironment";
import { useUserPreferencesState } from "../../Hooks/useUserPreferences";
import {
  bestServerToCellularMapLayer,
  groupByLocation,
  getResolution,
  getCurrentAreaOfIntrest,
  getWindowViewPolygon,
  isDeepEqual,
  isUserPreferencesValid,
  EMPTY_PLACEMENT,
  getColor,
  handleSitePlaningMapLayerPatch,
  getRange,
} from "./MapManegarIsFinalUtils";
import { useTimeOfDay } from "../../Hooks/useTimeOfDay";
import {
  useIsFeaturePermitted,
  useProjectParams,
} from "../../Hooks/useProject";
import { handlePatchToSepportSygnalType } from "../../controller/common";

export const useHandleFeachCoverage = ({
  setPlacement,
  setCellularSites: setSites,
  cellularSites,
  setSensorsPlacement,
  editAntennaSites,
  userPreferences,
  predictionState,
  debouncedMapZoom,
  debouncedMapBounds,
  prevAreaOfIntrest,
  project,
  applicationLayer,
}) => {
  const [, setPredictionState] = usePredictionState();
  const getBestServerCoverage = useGetBestServerCoverage();
  const getCoverage = useGetCoverage();
  const getSensors = useGetSensors();
  const previousUserPreferences = usePrevious(userPreferences);
  const previousPredictionState = usePrevious(predictionState);
  const previousMapZoom = usePrevious(debouncedMapZoom);
  const previousMapBounds = usePrevious(debouncedMapBounds);
  const previousEditAntennaSites = usePrevious(editAntennaSites);
  const isBestServer =
    userPreferences.currentMapLayer !== "sitePlaning" &&
    (userPreferences.bestProvider || userPreferences.providers.length > 1); // === mapLayerTypes.BEST_SERVER or more then 1 provider selected;
  const handleGetCoverage = isBestServer ? getBestServerCoverage : getCoverage;
  const isFeaturePermitted = useIsFeaturePermitted();
  useEffect(() => {
    if (isFeaturePermitted(featuresPermissions.PREDICTION))
      setPredictionState(true);
  }, [project]);

  useEffect(() => {
    // it is effectId=1. order dos MATTER! shuld be before effectId=2 !!!!
    const handleEffect = async () => {
      if (project._id === "") return;
      if (!(isUserPreferencesValid(userPreferences) && debouncedMapBounds))
        return setPlacement(EMPTY_PLACEMENT);
      if (
        predictionState &&
        userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP
      ) {
        return setPredictionState(false); // activet reRender and in the second render the whanted operation will run
      }
      if (userPreferences.currentMapLayer === mapLayerTypes.SITE_PLANING) {
        const sites = await Api.SitePlanning.getAntennas(
          project._id,
          userPreferences
        );
        const newSites = sites.map(({ antenna_id, lat, lng }) => ({
          id: antenna_id,
          location: { lat, lng },
        }));
        setSites(newSites);
      }
      if (
        !predictionState &&
        userPreferences.currentMapLayer === mapLayerTypes.SITE_PLANING
      ) {
        return setPredictionState(true); // activet reRender and in the second render the whanted operation will run
      }
      const filtersChanged =
        !isDeepEqual(previousUserPreferences, userPreferences) ||
        !isDeepEqual(previousPredictionState, predictionState);
      const mapZoomChanged = !isDeepEqual(previousMapZoom, debouncedMapZoom);
      const editAntennaSitesActionsChanged = !isDeepEqual(
        previousEditAntennaSites,
        editAntennaSites
      ); // when wee cleen it?
      const newAreaOfIntrestRevealed =
        !isDeepEqual(previousMapBounds, debouncedMapBounds) &&
        !isDeepEqual(
          prevAreaOfIntrest.current,
          await getCurrentAreaOfIntrest(debouncedMapBounds, project.polygon)
        ); // delete the await hehre!

      const shouldGoToServer =
        filtersChanged ||
        mapZoomChanged ||
        editAntennaSitesActionsChanged ||
        newAreaOfIntrestRevealed;
      // const shouldGoToServer = (editAntennaSites && editAntennaSites.length > 0) ||
      //     ((!isDeepEqual(previousUserPreferences, userPreferences) || // order dos matter!
      //         !isDeepEqual(previousPredictionState, predictionState) ||
      //         !isDeepEqual(previousMapZoom, debouncedMapZoom) ||
      //         !isDeepEqual(previousMapBounds, debouncedMapBounds)) &&
      //         !isDeepEqual(prevAreaOfIntrest.current, await getCurrentAreaOfIntrest(debouncedMapBounds, project.polygon, 2)));
      // if (userPreferences?.bands?.length === 0) {
      //     delete userPreferences.bands
      // }
      if (!shouldGoToServer) return;
      if (
        userPreferences.currentMapLayer === mapLayerValues.CELLULAR &&
        !isFeaturePermitted(featuresPermissions.PREDICTION)
      )
        setPredictionState(false);
      if (userPreferences.currentMapLayer !== mapLayerValues.IOT) {
        const userPreferencesNoBestServer = bestServerToCellularMapLayer({
          ...userPreferences,
        }); //// TODO: fix bestServerToCellularMapLayer problem
        handleSitePlaningMapLayerPatch(userPreferencesNoBestServer);
        userPreferencesNoBestServer.algorithm = predictionState
          ? algorithmsTypes.KMEANS
          : algorithmsTypes.STATIC_CLUSTERING;
        handleGetCoverage(
          project._id,
          userPreferencesNoBestServer,
          getWindowViewPolygon(debouncedMapBounds),
          setPlacement,
          setSites,
          cellularSites,
          editAntennaSites,
          debouncedMapZoom
        ); // side efects ? ? ?
      } else {
        getSensors(
          project._id,
          userPreferences,
          getWindowViewPolygon(debouncedMapBounds),
          debouncedMapZoom,
          setSensorsPlacement,
          project.demo ?? false,
          project,
          applicationLayer
        ); // side efects ? ? ?
      }
    };

    handleEffect();
  }, [
    debouncedMapZoom,
    debouncedMapBounds,
    userPreferences,
    predictionState,
    editAntennaSites,
  ]);
};

const useGetBestServerCoverage = () => {
  const [, setFetching] = useFetchLoader();
  return async function (
    projectId,
    userPreferences,
    windowViewPolygon,
    setPlacement
  ) {
    try {
      setFetching(true);
      const signalType = handlePatchToSepportSygnalType(userPreferences);
      const tasks = [];
      for (const provider of userPreferences.providers) {
        tasks.push(
          Api.getRealTimeCoverage(
            projectId,
            { ...userPreferences, providers: [provider], signalType },
            userPreferences.algorithm,
            windowViewPolygon
          )
        );
      }
      const newPlacements = await Promise.all(tasks);
      const allBinsPlacement = newPlacements.map(
        (placement) => placement.data.binsPlacements
      );
      const { binResolution, algorithm } = allBinsPlacement[0];
      const bins = allBinsPlacement.reduce(groupByLocation, []);
      const parsedPlacement = { info: { binResolution, algorithm }, bins };
      setPlacement(parsedPlacement);
    } catch (err) {
      console.log("There was a problem calling getBestServerCoverage:");
      console.log(err);
    } finally {
      setFetching(false);
    }
  };
};

const useGetCoverage = () => {
  const [, setFetching] = useFetchLoader();

  return async function (
    projectId,
    userPreferences,
    windowViewPolygon,
    setPlacement,
    setSites,
    cellularSites,
    editAntennaSites,
    mapZoom
  ) {
    try {
      setFetching(true);
      if (userPreferences.currentAntennaId) {
        const currentAntennaOriginalLocation = cellularSites.filter(
          (antenna) => antenna.id === userPreferences.currentAntennaId.id
        );
        const { binsPlacements, resolution } =
          await Api.SitePlanning.getAntennaCoverage(
            projectId,
            userPreferences,
            mapZoom,
            userPreferences.currentAntennaId.id,
            userPreferences.currentAntennaId.location,
            currentAntennaOriginalLocation[0].location,
            userPreferences.widerPrediction
          );
        const parsedPlacement = {
          info: {
            binResolution: resolution,
            algorithm: userPreferences.algorithm,
          },
          bins: binsPlacements,
        };
        setPlacement(parsedPlacement);
      } else {
        const signalType = handlePatchToSepportSygnalType(userPreferences);
        const { data } = await Api.getRealTimeCoverage(
          projectId,
          { ...userPreferences, signalType },
          userPreferences.algorithm,
          windowViewPolygon,
          editAntennaSites
        );
        const { binsPlacements, sites } = data;
        const { binResolution, algorithm, bins } = binsPlacements;
        const updatedBins = bins.map((bin) => ({
          ...bin,
          clusteredBins: [bin],
        }));
        const parsedPlacement = {
          info: { binResolution, algorithm },
          bins: updatedBins,
        };
        setPlacement(parsedPlacement);
      }
      setFetching(false);
    } catch (err) {
      console.log("There was a problem calling getCoverage:");
      console.log(err);
      setFetching(false);
    }
  };
};

const useGetSensors = () => {
  const [, setFetching] = useFetchLoader();

  return async function (
    projectId,
    userPreferences,
    windowViewPolygon,
    mapZoom,
    setPlacement,
    isDemo = false,
    project,
    applicationLayer
  ) {
    console.log("is project is demo??? ", isDemo);
    try {
      setFetching(true);
      const signalType = "rsrp";
      const resolution = getResolution(windowViewPolygon) * 2;
      const newPlacements = await Api.Sensors.getSensorsCoverage(
        projectId,
        { ...userPreferences, signalType },
        windowViewPolygon,
        resolution
      );
      const clusteredBins = newPlacements.map((sensor) => {
        const signal = !isDemo
          ? sensor.signal
          : Math.floor(Math.random() * -91) - 40;
        return {
          signal: signal,
          clusteredBins: sensor.sensors,
          providers: sensor.providers,
          location: { lat: sensor.lat, lng: sensor.lng },
          color: getColor(
            signal,
            userPreferences.currentMapLayer,
            userPreferences.technologies[0],
            SIGNALS.RSRP,
            getRange(project, userPreferences, applicationLayer)
          ),
        };
      });
      setPlacement({ bins: clusteredBins, resolution: resolution });
    } catch (err) {
      console.log("There was a problem calling getSensors");
      console.log(err);
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };
};

export const useApllyFactors = () => {
  const [userPreferences] = useUserPreferencesState();
  const userEnvironment = useUserEnvironment();
  const [indoorFloorEnv] = useIndoorFloorEnvState();
  const timeOfDay = useTimeOfDay();
  const projectParams = useProjectParams();
  const { devParams } = projectParams;
  const { systemGain } = devParams;

  const isHeatMapLayer =
    userPreferences.currentMapLayer === mapLayerValues.HEAT_MAP;
  const envFactor = isHeatMapLayer
    ? 0
    : environmentFactor[userEnvironment.toUpperCase()];
  const envIndoorFloorFactor =
    userEnvironment === "Home" ? -1 * floorsAlgo(indoorFloorEnv) : 0;
  const timeFactor = isHeatMapLayer
    ? 0
    : timeOfDayFactor[timeOfDay.toUpperCase()];

  const factor = envFactor + envIndoorFloorFactor + timeFactor - systemGain;

  return (bins) => {
    console.log({ bins });
    if (bins.clusteredBins) {
      return bins.map((bin) => ({
        ...bin,
        signal: bin.signal ? bin.signal + factor : bin.signal,
        clusteredBins: bin.clusteredBins.map((clusteredBin) => ({
          ...clusteredBin,
          signal: clusteredBin.signal + factor,
        })),
      }));
    }
    return bins.map((bin) => ({
      ...bin,
      signal: bin.signal ? bin.signal + factor : bin.signal,
    }));
  };
};
