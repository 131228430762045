import React, { forwardRef } from 'react';
import Table from 'react-bootstrap/Table';
import CoverageReliabilityTableArrows from '../../ChartsAndGraphs/CoverageReliabilityTableArrows';

function Reliability({ width, height, xaxisKey, yaxisKey, domain, yaxisLable, xaxisLable, colors, data, name }, ref) {
  return (
    <div id={name} ref={(element) => ref.current.add(element)} style={{ padding: 5 }}   >
      {data &&
        <Table class="table table-image" hover style={{ textAlign: "center ", padding: 5, height: height, width: width }} >
          <>
            {yaxisKey === "multiple" ? (
              <>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Samples</th>
                    <th>Above Threshold</th>
                    <th>Reliability</th>
                  </tr>
                </thead>
                <tbody  >
                  <tr>
                    <td></td>
                    <td> </td>
                    <td>{data.currentProvider.numberOfSamples}</td>
                    <td>{data.currentProvider.samplesAboveThreshold}</td>
                    <td>{data.currentProvider.CoverageReliability.toFixed(1)}%</td>
                  </tr>
                </tbody>
              </>) :
              <>
              <div></div>
              </>
            }
          </>
        </Table>}
    </div>


  );
}
export default forwardRef(Reliability);

