// TODO: trow error validations
// TODO: single config => // config: algo to styleTpe + setStyleTpe
// TODO: gather magic string to constants

export const initListeners = (dataLayer, listeners) => {
    listeners && listeners.forEach(({ eventName, callback }) => dataLayer.addListener(eventName, callback))
}

export const clearListeners = (dataLayer, listeners) => {
    listeners && listeners.forEach(({ eventName }) => window.google.maps.event.clearListeners(dataLayer, eventName))
}
export const clearGeoJsonData = (dataLayer) => {
    dataLayer.forEach((feature) => {
        dataLayer.remove(feature);
    });
    // dataLayer.setMap(null);
}

export const drawGeoJsonData = (dataLayer, data) => {
    const options = {};
    dataLayer.addGeoJson(data, options);
    // dataLayer.setMap(map); // ????????????????????????????
    // map.data.addGeoJson(data, options);
}
export const clearGeoJsonDataStyle = (dataLayer) => {
    dataLayer.setStyle(null);
}
export const initDataLayer = (dataLayer, map) => {
    dataLayer.setMap(map);
}
// export const setDisplay = (map, displayCallback) => {
//     console.log("setDisplay displayCallback: ", displayCallback)
//     map.data.addListener('mouseover', function (event) {
//         if (event.feature.getProperty('display')){
//             displayCallback(event.feature.getProperty('display'))
//         }
//     })
// }


export const initDataLayerStyle = (dataLayer, styleConfig) => {
    dataLayer.setStyle((feature) => {
        const styleType = feature.getProperty('styleType');
        const getStyle = styleConfig[styleType];

        return getStyle(feature);
    })
}


// suld the parent container set the link to the map instance and then send the layer here or shuld this component do it??????????????????????

export const setGeoJsonDataStyle = (dataLayer, map, styleConfig) => {
    if (!styleConfig) dataLayer.setStyle(null);

    dataLayer.setStyle((feature) => {

        const styleType = feature.getProperty('styleType');
        const getStyle = styleConfig[styleType];

        return getStyle(feature);
    })
    // map.data.setStyle((feature) => {

    //     const styleType = feature.getProperty('styleType');
    //     const getStyle = styleConfig[styleType];

    //     return getStyle(feature);
    // })
}