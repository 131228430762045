import { useState } from 'react';
import styled from "styled-components"
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import PasswordSubPage from "./PasswordSubPage";

const MainDiv = styled.div`
    height:  100%;
    width: 100%
    padding: 3% 19% 5%;
    background: rgb(270, 270, 270);

    @media screen and (max-width: 1000px) {
        padding: 0% 0% 0% 0%;
    }
    .border-card{
        height:  100%;
        width: 100%;
        border-width: 0.3rem;
        border: 0.2rem solid #ececec;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);   
    }

    .row:nth-child(1) {
        height: 9%;
        padding-Left: 0.8%;
        padding-Top: 0.8%;
        background-color: #fafafa;
        .icon-style{
            padding-Top: 2px;
            font-Size: 19px;
        }
        .text-style{
            margin-Left: 13px;
            font-Weight: bolder;
            font-Size: 16px;
            opacity: 0.8;
        }
        .hader-row-wrapper{
            width: 100%;
            height: 100%;
            background: white;
            border-Bottom-Color: #d8dbde;
            border-Bottom-Style: solid;
            margin-Left: 0px;
            border-Width: 2px;
        }
    }
    .row:nth-child(2) {
        height: 91%;
        .left-navbar-wrapper{
            border-right-style: solid;
            border-right-color: #d8dbde;
            background-color: #fafafa;
            border-width: thin;
            padding-top: 14px;
            padding-bottom: 14px;
        } 
        .col-wrapper{
        padding-Right: 5%;
        padding-Left: 3.5%;
        padding-Top: 23px;
        }
        .left-navbar{
            button.list-group-item {
                border: 0;
                background: none;
                i.fa{
                    margin-right: 11px;
                }
            }
            .list-group-item.active{
                border-color: rgb(237 240 247);
                color: rgb(19 11 64);
                font-weight: bold;
            }
        }

    }
`;


const ListPages = {
    password: 'PASSWORD',
    emailGroup: 'EMAIL-GROUP'
}

function Settings() {
    const [subPageSelected, setSubPageSelected] = useState(ListPages.password);

    function clickList(e) {
        console.log(e)
        setSubPageSelected(e)
    }

    return (
        <MainDiv>
            <Container fluid className="border-card" >
                <Row >
                    <Row className='hader-row-wrapper'>
                        <Col className="col-md-2">
                            <i className={"fa fa-solid fa-gear icon-style"} />
                            <span className={'text-style'}>Settings</span>
                        </Col>
                        <Col className="col-md-10">
                            {/* <div style={{ height: '100%', width: 'fit-content', borderBottomStyle: 'solid', borderBottomColor: 'rgb(19 11 64)' }}><span style={{ color: 'rgb(19 11 64)' }}>Profile</span></div> */}
                        </Col>
                    </Row>
                </Row>
                <Row  >
                    <Col className="col-md-2 left-navbar-wrapper">
                        <ListGroup className='left-navbar' onSelect={clickList} variant="flush">
                            <ListGroup.Item eventKey={ListPages.emailGroup} action >
                                <i class="fa fal fa-envelope"></i>Email
                            </ListGroup.Item>
                            <ListGroup.Item eventKey={ListPages.password} action >
                                <i class="fa fas fa-key"></i>Password
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col className="col-md-10 col-wrapper">
                        {subPageSelected === 'PASSWORD' ? <PasswordSubPage /> : <div />}
                    </Col>
                </Row>
            </Container>
        </MainDiv>
    )
}


export default Settings;

//  <i className={"fa fa-cog nav-icon"} />
