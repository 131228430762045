import {LOGOUT_USER, SET_CURRENT_USER} from "../actionTypes";

function logoutUser(){
    return {type:LOGOUT_USER};
}

export {logoutUser};

function setUser({user, idToken,refreshToken, accessToken}) {
    return {
        type: SET_CURRENT_USER,
        user,
        idToken,
        refreshToken,
        accessToken
    }
}

export {setUser};