import styled from "styled-components";
import Constants, { POLYGON_BLUE } from "../../controller/Constants";
import { useUserPolygonDisplayedState } from "../../Hooks/common";
import { useMapStateState } from "../../Hooks/map";

const Div = styled.div`
  position: absolute;
  top: 10px;
  right: 236px;
`;

const ControlButton = styled.button`
  transition: 0.18s;
  height: 40px;
  width: 40px;
  background-color: ${({ isActive }) => isActive ? POLYGON_BLUE : "#ffffff"};
  //color: ${({ isActive }) => isActive ? "#ffffff" : "rgba(0, 0, 0, 0.7)"};
  padding: 0px !important;
  border: 0px !important;
  cursor: pointer;
  outline: none !important;
  &:hover {
    svg>g{
      fill: ${({ isActive }) => isActive ? "rgba(0, 33, 33, 1)" : "rgba(0, 33, 33, 0.9)"};
  }

}

`;

function SelectAreaButton() {
  const [toggleUserPolygonDisplayedState, toggleUserPolygonDisplayed] = useUserPolygonDisplayedState();
  const [mapState, setMapState] = useMapStateState();
  const { AREA_SELECTION, SITES_COVERAGE } = Constants.mapStates;

  function selectAreaState() {
    setMapState(mapState === AREA_SELECTION ? SITES_COVERAGE : AREA_SELECTION);
    toggleUserPolygonDisplayed();
  }

  return (
    <Div>
      <ControlButton isActive={toggleUserPolygonDisplayedState} onClick={selectAreaState} title="Edit displayed area">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="23.000000pt" height="23.000000pt" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="rgba(0, 33, 33, 0.7)" stroke="">
            <path d="M782 4469 c-46 -14 -118 -88 -131 -135 -15 -54 -14 -297 1 -348 18
-60 83 -120 146 -137 l52 -13 0 -1277 0 -1277 -40 -7 c-50 -8 -110 -52 -141
-102 -22 -35 -24 -50 -27 -189 -2 -93 1 -167 8 -193 15 -55 81 -122 136 -139
54 -16 294 -16 348 0 76 23 146 118 146 197 0 11 4 21 8 21 4 0 577 88 1272
195 695 107 1266 195 1270 195 4 0 12 -18 19 -40 15 -50 62 -105 112 -131 33
-16 59 -19 196 -19 180 0 215 10 270 73 48 54 55 93 51 263 -3 147 -4 152 -33
199 -30 49 -89 88 -147 100 l-28 5 0 850 0 850 28 5 c58 12 117 51 147 100 29
47 30 52 33 199 4 170 -3 209 -50 262 -58 66 -87 74 -268 74 -139 0 -166 -3
-199 -19 -50 -26 -97 -81 -112 -131 -7 -22 -15 -40 -19 -40 -4 0 -575 88
-1270 195 -695 107 -1268 195 -1272 195 -4 0 -8 10 -8 21 0 79 -70 174 -146
197 -49 14 -303 15 -352 1z m283 -309 l0 -105 -105 0 -105 0 -3 94 c-1 52 0
101 2 108 4 11 29 13 108 11 l103 -3 0 -105z m1490 -320 c682 -104 1250 -192
1261 -195 16 -4 23 -15 27 -46 12 -87 89 -166 180 -184 l27 -5 0 -850 0 -850
-27 -5 c-91 -18 -168 -97 -180 -184 -4 -31 -11 -42 -27 -46 -35 -8 -2500 -385
-2518 -385 -10 0 -21 14 -28 37 -25 77 -88 134 -162 148 l-38 7 0 1277 0 1277
52 13 c64 17 128 77 146 139 8 28 19 42 30 42 9 0 575 -85 1257 -190z m1715
-105 l0 -105 -110 0 -110 0 0 105 0 105 110 0 110 0 0 -105z m0 -2350 l0 -105
-110 0 -110 0 0 105 0 105 110 0 110 0 0 -105z m-3205 -425 l0 -105 -105 0
-105 0 -3 94 c-1 52 0 101 2 108 4 11 29 13 108 11 l103 -3 0 -105z"/>
          </g>
        </svg>
      </ControlButton>
    </Div>
  );
}

export default SelectAreaButton;
