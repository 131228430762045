import { useEffect, useState } from "react";
import { getCenterdPolygon } from "../../../../controller/common.js";
import { polygonSchemaTypes } from "../../../../controller/Constants.js";
import { useUserPolygonDisplayedState } from "../../../../Hooks/common.js";
import { getIntersection } from "../../../../utils/mapGeometry/Polygon"
import { getPath, isPolygonEmpty } from "../../../../utils/googleMapsApi/Polygon";
import { deepCopy } from "../../../../utils/common.js";
const { LAT_LNG } = polygonSchemaTypes;

export const usePolygonsIntersection = ({ userPolygonInEditMode, userPolygonDisplayed, userPolygonRef, projectPolygon, onPolygonChange }) => {
    const [intersectedPolygon, setIntersectedPolygon] = useState([]);
    const [, toggleUserPolygonDisplayed] = useUserPolygonDisplayedState();

    useEffect(() => {
        const handleIntersection = async () => {
            const userPolygon = getPath(userPolygonRef.current);
            if (isPolygonEmpty(userPolygon)) return
            const intersection = await getIntersection({ responesType: LAT_LNG, polygons: [projectPolygon, userPolygon] });
            const isIntersectionEmpty = isPolygonEmpty(intersection);
            if (isIntersectionEmpty) {
                userPolygonRef.current.setPath(getCenterdPolygon(window.mainMap));
                clearPolygon()
                onPolygonChange(projectPolygon);
                return
            }
            const fainalPolygon = isIntersectionEmpty ? projectPolygon : intersection;
            onPolygonChange(fainalPolygon);
            setIntersectedPolygon(deepCopy(fainalPolygon)); // intersection state holds the lase setIntersection and it was projectPolygon, it dos not hold the current 'live' polygon instanse state but gets it onTrigger so we force update
        }
        if (!userPolygonInEditMode) {
            handleIntersection();
        }

    }, [userPolygonInEditMode])


    useEffect(() => {
        if (!userPolygonDisplayed && !userPolygonInEditMode) {
            onPolygonChange([]);
        }
    }, [userPolygonDisplayed])

    const resetPolygonToCenter = () => {
        setIntersectedPolygon(getCenterdPolygon(window.mainMap));

    }
    const clearPolygon = () => {
        setIntersectedPolygon([]);
        toggleUserPolygonDisplayed();
    }

    return {
        intersectedPolygon,
        resetPolygonToCenter,
        clearPolygon,
        setIntersectedPolygon
    }
}
