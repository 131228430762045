import React from "react";
import PropTypes from "prop-types";
import SubDropdown from "./SubDropdown";
import styled from "styled-components";

const Li = styled.li`
  cursor: pointer;
  background-color: ${props => props.selected ? "#f2f2f2 !important" : ""};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
function DropdownItem({children, onClick, title, icon, content, isSelected, value}) {
  const myOnClick=(e)=>{
    onClick(value);

  }
  return (
    <Li className={children && `has-child`} selected={isSelected} >
      <a onClick={myOnClick}>
        <FlexDiv>
          <div>
            {icon}
            {title}
            
          </div>
          {children && <i class="fa fa-solid fa-angle-right"></i>}
          {content}
        </FlexDiv>
      </a>
      {children && <SubDropdown>{children}</SubDropdown>}
    </Li>
  );
}

DropdownItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default DropdownItem;
