const IN_EDIT_MODE = "inEditMode";
const DISABLED = "disabled";
const LOCATION = "location";
const STYLE_TYPE = "styleType";

export const toggleProperty = (instance, property) => {
    if (!instance) return;
    const value = instance.getProperty(property);
    if (typeof value !== "boolean") throw Error("The function 'toggleProperty' works only on Booleans values");
    instance.setProperty(property, !value);
}

export const reDrawStyle = (dataLayer) => {
    dataLayer.setStyle((feature) => {
        return getStyle(feature);
    })
}

export const clearSites = (dataLayer) => {
    dataLayer.forEach((feature) => {
        dataLayer.remove(feature);
    });
}

export const getNewPoint = ({ location, id }) => {
    return new window.google.maps.Data.Feature({
        geometry: new window.google.maps.Data.Point({ lat: location.lat, lng: location.lng }),
        id,
        properties: {
            "styleType": "cellularSite",
            "icon": '/assets/tower-cell.png',
            "inEditMode": false,
            "disabled": false,
            "location": location
        }
    })
}

export const removeSitesThatArntInTheNewData = (dataLayer, data) => {
    const newSites = {};
    data.reduce((acc, site) => {
        acc[site.id] = true;
        return acc;

    }, newSites)

    dataLayer.forEach((feature) => {
        const id = feature.getId();
        const siteOriginalId = id.includes("copy") ? id.split("-copy-")[0] : id;
        const siteOriginalIdInNewSitesIds = siteOriginalId in newSites;
        if (!siteOriginalIdInNewSitesIds) dataLayer.remove(feature);
    });

}

export const handleNewData = (dataLayer, data) => { // getNewData
    let updatedData;
    const currentSitesIds = {};
    const newSites = [];

    dataLayer.forEach((feature) => {
        currentSitesIds[feature.getId()] = true;
    });

    const currentSitesAreEmpty = Object.keys(currentSitesIds).length === 0;
    if (currentSitesAreEmpty) {
        updatedData = data.map(site => getNewPoint({ id: site.id, location: site.location }));
    } else {
        for (const site of data) {
            if (!(site.id in currentSitesIds)) newSites.push(getNewPoint({ id: site.id, location: site.location }));
        }
        updatedData = newSites;

    }
    return updatedData;
}

export const getOnlyDisabledSites = (dataLayer) => {
    const actions = [];
    dataLayer.forEach((site) => {
        const id = site.getId();
        const isDuplocate = id.includes("copy");
        const isDisabled = site.getProperty(DISABLED);
        if (isDisabled && !isDuplocate) {
            actions.push({
                'action': 'delete',
                'antennaId': site.getId()
            })
        }
    });
    return actions;
}

export const getOnlyDuplocatesSites = (dataLayer) => {
    const sites = [];
    dataLayer.forEach((site) => {
        const id = site.getId();
        const isDuplocate = id.includes("copy");
        const isDisabled = site.getProperty(DISABLED);
        if (isDuplocate && !isDisabled) {
            sites.push({
                'action': 'add',
                'sourceAntennaId': id.split("-copy-")[0],
                'location': { type: "Point", coordinates: [site.getGeometry().get().lng(), site.getGeometry().get().lat()] }
            })
        }
    });
    return sites;
}


export const getStyle = (feature) => {
    const getStyleObject = ({ iconUrl, draggable }) => ({
        icon: {
            url: iconUrl,
            scaledSize: new window.google.maps.Size(50, 50),
        },
        draggable,
    })

    const inEditMode = feature.getProperty(IN_EDIT_MODE);
    const isDisabled = feature.getProperty(DISABLED);

    if (inEditMode && isDisabled) return getStyleObject({ iconUrl: '/assets/tower-cell-disabled-active.png', draggable: true });
    if (inEditMode && !isDisabled) return getStyleObject({ iconUrl: '/assets/tower-cell-green.png', draggable: true });
    if (!inEditMode && !isDisabled) return getStyleObject({ iconUrl: '/assets/tower-cell.png', draggable: false });
    if (!inEditMode && isDisabled) return getStyleObject({ iconUrl: '/assets/tower-cell-disabled.png', draggable: false });

}

export const getSitesThatWasOnlyMoved = (dataLayer) => {
    const actions = [];
    dataLayer.forEach((site) => {
        const id = site.getId();
        const isDuplocate = id.includes("copy");
        const isDisabled = site.getProperty(DISABLED);
        if (!isDuplocate && !isDisabled) {
            const sameLocation = site.getProperty(LOCATION).lat === site.getGeometry().get().lat() && site.getProperty(LOCATION).lng === site.getGeometry().get().lng();
            if (!sameLocation) {
                actions.push({
                    'action': 'delete',
                    'antennaId': site.getId()
                })
                actions.push({
                    'action': 'add',
                    'sourceAntennaId': site.getId(),
                    'location': { type: "Point", coordinates: [site.getGeometry().get().lng(), site.getGeometry().get().lat()] }
                })
            }
        }
    });
    return actions;
}

export const getSitesAsGeoJson = (dataLayer) => {
    const sites = [];
    dataLayer.forEach((site) => {
        const properties = {};
        properties.styleType = site.getProperty(STYLE_TYPE);
        properties.location = site.getProperty(LOCATION);
        properties.disabled = site.getProperty(DISABLED);

        const lng = site.getGeometry().get().lng();
        const lat = site.getGeometry().get().lat();
        const id = site.getId();

        sites.push({
            type: 'Feature',
            id,
            geometry: {
                "type": "Point",
                "coordinates": [lng, lat]
            },
            properties: properties
        });
    });

    const geoJson = {
        "type": "FeatureCollection",
        "features": sites
    }

    return geoJson;
}

export const getAllAncestors = (event) => {
    const ancestors = [];
    let element = event.target;
    while (element) {
        ancestors.push(element);
        element = element.parentNode;
    }
    let idList = []
    for (let i = 0; i < ancestors.length; i++) {
        idList.push(ancestors[i].id);
    }
    return { ancestors, idList };
}
