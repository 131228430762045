import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllProviders } from "../store/actionCreators/general";
import Api from "../controller/ApiManager";

export function useAllProviders() {

    const dispatch = useDispatch()
    const allProviders = useSelector(state => state.general.allProviders);

    const fetchAndSet = async () => {
            if (allProviders.length === 0) {
                const providers = await Api.Provider.getAllProviders()
                dispatch(setAllProviders(providers))
            }
    };
    useEffect(() => {
        fetchAndSet()
    }, [dispatch])

    return allProviders
}

