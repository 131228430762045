import React, {useCallback, useEffect, useRef, useState} from "react";
import PlacesAutoComplete from "../Common/GoogleAutoComplete";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useLocationStatsState} from "../../Hooks/map";
import EventEmitter from "../../controller/EventEmitter";
import {events} from "../../controller/Constants";
import DeleteButton from "../Common/Buttons/DeleteButton";
import {DEFAULT_LOCATION_STATS} from "../../store/defaultValues/map";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors"; // "../../Hooks/displayedAntennas"
import {useTranslation} from "react-i18next";
import useProject from "../../Hooks/useProject";

const Div = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: calc(15vw + 40px);
  align-items: start;
  .search-location {
    width: 15vw;
    padding: 8px;
    border-radius: 0;
  }
`;

function SearchLocation({}) {
  const displayedAntennas = useDisplayedAntennas();// ?
  const [locationStats, setLocationStats] = useLocationStatsState();
  const mapLoaded = useSelector((state) => state.map.loaded);
  const map = window.mainMap;
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);
  const justChangedRef = useRef(false);
  const project = useProject();
  
  useEffect(() => {
    setLocationStats(DEFAULT_LOCATION_STATS);
  }, [project._id]);

  useEffect(() => {
    if (displayedAntennas.length === 0) {
      setLocationStats(DEFAULT_LOCATION_STATS);
    }
  }, [displayedAntennas]);
  const handleChangeLocation = useCallback(() => {
    try {
      justChangedRef.current = true;
      const place = autoCompleteRef.current.getPlace();
      const location = place.geometry.location.toJSON();
      map.setCenter(location);
      map.setZoom(18);
      setLocationStats({...DEFAULT_LOCATION_STATS, display:true, location:location});
      if (displayedAntennas.length > 0) EventEmitter.dispatch(events.SET_LOCATION_STATS, {location, address: place.name});
    } catch (e) {
      console.warn(e);
    }
  }, [displayedAntennas]);
  useEffect(() => {
    // update string on input according to location on map
    const markerJustMoved = locationStats.address && inputRef.current && !justChangedRef.current;
    const inputEntered = locationStats.address && inputRef.current.value && justChangedRef.current;
    const markerRemoved = !locationStats.display;
    if (markerJustMoved) {
      inputRef.current.value = locationStats.address;
    } else if (inputEntered) {
      justChangedRef.current = false;
    } else if (markerRemoved) {
      inputRef.current.value = "";
    }
  }, [locationStats]);
  return (
    mapLoaded && (
      <Div>
        <PlacesAutoComplete
          placeholder={"Search location"}
          inputProps={{ref: inputRef}}
          ref={autoCompleteRef}
          onPlaceChanged={handleChangeLocation}
          className={"search-location"}
        />
        {locationStats.display && (
          <DeleteButton
            label={""}
            onClick={() => {
              setLocationStats(DEFAULT_LOCATION_STATS);
            }}
          />
        )}
      </Div>
    )
  );
}

export default SearchLocation;
