import React,{useState} from 'react';
import styled from 'styled-components';

const ChipStyle = styled.li`
  display: flex;
  background-color: #e0e0e0;
  padding: 8px 12px;
  border-radius: 16px;
  :hover {
    box-shadow: 0 0 0 calc(1px + .15rem) rgb(194,194,194);
  }
`;

const CloseButton = styled.button`
  border: none;
  border-radius: 50%;
  background: transparent;
  padding: 0;
  cursor: pointer;
  position: relative;
  top: -2px;
  right: -8px;
  font-weight: bold;
`;

const ChipListContainer = styled.ul`
gap: 4px;
display: flex;
padding-Left: 0px;
list-Style-Type: none;
flex-Wrap: wrap;
margin: 13px 0 13px 0;
`;

const ChipItem = ({ children, onClose }) => {
  return (
    <ChipStyle>
      {children}
      <CloseButton onClick={onClose} type="button">×</CloseButton>
    </ChipStyle>
  );
};

const ChipList = ({data: chips, setData:setChips, valueInChipsToDisplay }) => {

    const addChip = (label) => {
      setChips([...chips, label]);
    };
  
    const removeChip = (index) => {
      setChips(chips.filter((chip, i) => i !== index));
    };
  
    return (
      <ChipListContainer> 
        {chips.map((chip, index) => (
            
            <ChipItem 
              key={chip._id}
              onClose={() => removeChip(index)}
            >
              {valueInChipsToDisplay?valueInChipsToDisplay(chip):chip}
            </ChipItem>
            
          ))}
      </ChipListContainer>
    );
  };
  
export default ChipList;