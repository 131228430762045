import styled from "styled-components"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from "./Common/Dropdown/DropdownItem";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import {useHistory} from "react-router-dom";
import {useLocationStatsState} from "../Hooks/map"
import {DEFAULT_LOCATION_STATS} from "../store/defaultValues/map"
import {UserApi} from "../controller/ApiManager";
import  { useSetProjects, useResetProjectDefault} from "../Hooks/useProject";
import  { useLogoutUser} from "../Hooks/common";
import { useUser} from "../Hooks/common";

const StyledButton = styled.button`
&&{
    outline: none;
}
border: 0;
border-right: 0;
background-color: transparent;
padding: 0;
&& .fa-solid.fa-user{
    font-size: 16px;
}
`;

export default function PfofileButton() {
    const history = useHistory();
    const setProjects = useSetProjects();
    const resetProjectDefault = useResetProjectDefault();
    const logoutUser = useLogoutUser();
    const [,setLocationStats ] = useLocationStatsState();
    const {user} = useUser();  

    function pathSettingsPage() {
        history.push("/settings");
    }

    const cleanReduxBeforeLogout = ()=> {
        setLocationStats(DEFAULT_LOCATION_STATS)
    }
    const logout = async () => {
        try {
          //delete from Redux the state that set the location marker
          cleanReduxBeforeLogout();
          const logoutRes = await UserApi.logout(user.email);
          console.log("logout:", logoutRes);
          resetProjectDefault();
          setProjects([])
          logoutUser(); 
          history.push("/");
        } catch (e) {
          console.log({e});
          console.error(e);
        }
      };
    
    return (
        <StyledButton>
            <div className="primary-nav has-mega-menu" style={{borderRight: '0'}}>
                <ul className="navigation">
                    <NavbarDropdown title={<>
                        <i className="fa fa-solid fa-user" />
                        <span>{""}</span>
                    </>}>
                        <DropdownItem
                            title={
                                <>
                                    <i className="fa fa-solid fa-gear nav-icon" />
                                    <span>{"Settings"}</span>
                                </>
                            }
                            onClick={
                                pathSettingsPage
                            }

                        />
                        <DropdownItem
                            title={
                                <>
                                    <i class="fa fa-sign-out nav-icon" aria-hidden="true"></i>
                                    <span>{"Log out"}</span>
                                </>
                            }
                            onClick={
                                logout
                            }

                        />

                    </NavbarDropdown>
                </ul>
            </div>
        </StyledButton>


    )

}