import { useCallback, useEffect, useRef, useState } from "react";
import { openDialog } from "../store/actionCreators/general";
import Constants from "../controller/Constants";
import { modals, events } from "../controller/Constants";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { UserApi } from "../controller/ApiManager/index";
import EventEmitter from "../controller/EventEmitter";
import { mapLoadedCreator } from "../store/actionCreators/map";
import { useSetUserToRedux } from "./common";
import { getTokenIdParameter, decodeJwtToken } from "../controller/common";

export function useSubscribeToSetNewTockensAfterRefreshTokenEvent() {  
  // THE USER STORY: (the problom)
  //  When an api function is called, we check if the idToken expiried and if it is,
  //  we whant to fetch new tokens and save the localy in the browser+redux and then send the original api call.
  // THE IMPLEMENTATION: (the solution)
  //  because all the api calls are class static method we can't save the tokens to redux from thos methods (only from hooks),
  //  so we use event emitter by subscribing with custom hook in the 'initApp' to the 'REFRESH_TOKEN' evet,
  //  and the api call will dispatch the event and trigger the costum hook that will save the tokens to redux
  const handleTokenExpiration = useHandleTokenExpiration();
  useEffect(() => {
    const eventId = EventEmitter.subscribe(events.SET_TOKENS_AFTER_REFRESH_TOKEN, handleTokenExpiration);
    return () => {
      EventEmitter.unsubscribe(events.SET_TOKENS_AFTER_REFRESH_TOKEN, eventId);
    };
  }, []);
}
 
export const handleTokenExpirationEvent = async () => {
  console.log("Refreshing Token");
  const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
  const email = getTokenIdParameter('email')
  const tokens = await UserApi.refreshToken({ email, refreshToken });
  const { idToken, refreshToken: refreshRes, accessToken } = tokens;
  EventEmitter.dispatch(events.SET_TOKENS_AFTER_REFRESH_TOKEN, {idToken, refreshToken: refreshRes, accessToken});
  return idToken;
}

export function useHandleTokenExpiration() {
  const setUserToRedux = useSetUserToRedux();
  
  return function ({ idToken, refreshToken, accessToken }) {
    setUserToRedux(idToken, refreshToken, accessToken);
  };
}
 
export function getCognitoId() {
  const cognitoId = getTokenIdParameter("cognito:username");
  return cognitoId;
}
export function isIdTokenExpired(idToken) {
  if (!Boolean(idToken)) {
    throw Error(`"${idToken}" is not a valid id token`);
  }
  const tokenDecoded = decodeJwtToken(idToken);
  const { exp } = tokenDecoded;
  const expirationDate = new Date(exp * 1000);
  const now = new Date(Date.now());
  const isExpired = Boolean(now.getTime() >= expirationDate.getTime());
  return isExpired;
}


function useIsAuthenticated(setError, setFetching, setFetched) {
  // When userRedux.isAuthenticated change, set mapLoaded = false
  const userRedux = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userRedux.isAuthenticated) {
      setError(null);
      setFetching(false);
      setFetched(true);
    } else {
      dispatch(mapLoadedCreator(false));
    }
  }, [userRedux.isAuthenticated]);
}

function useFetchRefreshToken(setFetching, fetchingRef, setError, setFetched) {
  const dispatch = useDispatch();
  const openLoginDialog = useCallback(() => {
    return dispatch(openDialog(modals.LOGIN));
  }, [dispatch]);
  const setUserToRedux = useSetUserToRedux();

  return useCallback(
    async function (email, refreshToken) {
      let idToken;
      try {
        setFetching(true);
        fetchingRef.current = true;
        const tokens = await UserApi.refreshToken({email, refreshToken});
        const {refreshToken: refreshRes} = tokens;
        idToken = tokens.idToken;
        if (typeof idToken !== "string" || typeof refreshRes !== "string") {
          console.log("token: ", tokens);
          throw Error("result from refresh token aren't string!");
        }
        setUserToRedux(idToken,refreshRes, tokens.accessToken);
        setError(null);
      } catch (error) {
        console.error(error);
        setError(error);
        openLoginDialog();
      } finally {
        fetchingRef.current = false;
        setFetching(false);
        setFetched(true);
      }
    },
    [setUserToRedux, openLoginDialog]
  );
}
export async function useGetRefreshedToken() {
  const setUserToRedux = useSetUserToRedux();
  return async function () {
    const refreshToken = localStorage.getItem(Constants.localStorage.REFRESH_TOKEN);
    const email = getTokenIdParameter('email')
    const tokens = await UserApi.refreshToken({ email, refreshToken });
    const { idToken, refreshToken: refreshRes, accessToken } = tokens;
    setUserToRedux(idToken, refreshRes, accessToken);
  };
}

function useCheckRefreshToken(
  setFetching,
  setFetched,
  fetchRefreshToken,
  setError
) {
  const dispatch = useDispatch();
  const setUserToRedux = useSetUserToRedux();
  const location = useLocation();
  const openLoginDialog = useCallback(() => {
    return dispatch(openDialog(modals.LOGIN));
  }, [dispatch]);
  const openTermsDialog = useCallback(() => {
    return dispatch(openDialog(modals.TERMS));
  }, [dispatch]);


  useEffect(() => {
    const idTokenStored = localStorage.getItem(Constants.localStorage.ID_TOKEN);
    const refreshToken = localStorage.getItem(
    Constants.localStorage.REFRESH_TOKEN
    );
    const accessToken = localStorage.getItem(
      Constants.localStorage.ACCESS_TOKEN
      );
    if (Boolean(idTokenStored)) {
      const { isTermsChecked, email } = setUserToRedux(idTokenStored, refreshToken, accessToken);
      const isExpired = isIdTokenExpired(idTokenStored);
      if (!isExpired) { 
        setFetching(false);
        setFetched(true);
        if (!isTermsChecked) {
          openTermsDialog();
        }
      } else if (isExpired && Boolean(idTokenStored) && Boolean(refreshToken)&&Boolean(accessToken)) {
        fetchRefreshToken(email, refreshToken);
      }
    } else {
      openLoginDialog();
      setFetching(false);
      setFetched(true);
      setError(Error("Require Login"));
    }
  }, [
    location.pathname,
    fetchRefreshToken,
    setUserToRedux,
    openLoginDialog,
    openTermsDialog,
  ]);
}

function useFetchRefreshTokenEvent(fetchingRef, fetchRefreshToken) {
  useEffect(() => {
    const eventId = EventEmitter.subscribe(
      events.REFRESH_USER_TOKEN,
      async (data) => {
        const refreshToken = localStorage.getItem(
          Constants.localStorage.REFRESH_TOKEN
        );
        const email = getTokenIdParameter('email')
        if (refreshToken && !fetchingRef.current) {
          await new Promise((resolve, reject) =>
            setTimeout(
              () =>
                fetchRefreshToken(email, refreshToken)
                  .then(resolve)
                  .catch(reject),
              10
            )
          );
        }
        if (fetchingRef.current) {
        }
      }
    );
    return () => {
      EventEmitter.unsubscribe(events.REFRESH_USER_TOKEN, eventId);
    };
  }, [fetchRefreshToken]);
}

export function useAuth() {
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [fetching, setFetching] = useState(false);
  const fetchingRef = useRef(false);
  useIsAuthenticated(setError, setFetching, setFetched);
  const fetchRefreshToken = useFetchRefreshToken(
    setFetching,
    fetchingRef,
    setError,
    setFetched
  );
  useCheckRefreshToken(setFetching, setFetched, fetchRefreshToken, setError);
  useFetchRefreshTokenEvent(fetchingRef, fetchRefreshToken);
  return { error, fetched, fetching };
}