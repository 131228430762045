import React, { forwardRef } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,

} from "recharts";
import { extractData } from './utils';

const CustomTooltip = ({ active, payload, label, xaxisLable }) => {
  if (active && payload && payload.length) {
    return (
      <div className=" recharts-legend-wrapper">
        <div className="recharts-default-tooltip" style={{ backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", margin: "0px", padding: "10px" }}>
          <p className="recharts-tooltip-label">{`${label} ${xaxisLable}`}</p>
          {payload.map((item) => (
            <p key={item.name} className="recharts-tooltip-label">{`${item.value.toFixed(2) * 100} ${"%"} `}</p>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

function AreasChart({ width, height, xaxisKey, yaxisKey, domain, yaxisLable, xaxisLable, colors, data, name, providers }, ref) {
  let dataStruct = [];
  const providersColors = ["#E0B0FF", "#0096FF", "#48D1CC", "#d1cc48"];
  // const randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}` //to do: random color with linearGradients
  const linearGradientList = ["colorCellcom", "colorPelephone", "colorPartner"]
  return (
    <div id={name} ref={(element) => ref.current.add(element)} style={{ padding: 5 }}>
      {data &&
        <ResponsiveContainer width={width} height={height}>
          <AreaChart
            data={extractData(data, dataStruct) || []}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id="colorMultiple" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.MULTIPLE} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors.MULTIPLE} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorCellcom" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.CELLCOM_COLOR} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors.CELLCOM_COLOR} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPelephone" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.PELEPHONE_COLOR} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors.PELEPHONE_COLOR} stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPartner" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.PARTNER_COLOR} stopOpacity={0.8} />
                <stop offset="95%" stopColor={colors.PARTNER_COLOR} stopOpacity={0} />
              </linearGradient>
            </defs>


            <XAxis dataKey={xaxisKey} style={{
              fontSize: '0.6rem'
            }}>
              <Label value={xaxisLable} offset={16} position="right" fontSize={7} />
            </XAxis>
            <YAxis type="number" hide={true} />
            <Tooltip content={<CustomTooltip xaxisLable={xaxisLable} />} />
            <Legend position="insideBottom" />
            <>
              {yaxisKey === "signal level" ? (
                <Area type="monotone" dataKey={yaxisKey} stroke={colors.MULTIPLE} fillOpacity={1} fill="url(#colorMultiple)" />)
                :
                (<>
                  {providers.map((item, index) => (
                    // <Area type="monotone" dataKey={item} stroke={colors.CELLCOM_COLOR} fillOpacity={1} fill="url(#{colorCellcom})" />
                    <Area type="monotone" dataKey={item} stroke={providersColors[index % 3]} fillOpacity={1} fill={`url(#${linearGradientList[index % 3]})`} />
                  ))}
                </>)
              }
            </>
          </AreaChart>
        </ResponsiveContainer>
      }
    </div>
  );
}
export default forwardRef(AreasChart);

