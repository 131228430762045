
import { Col, Row, Container } from "react-bootstrap"
import BarRechartComponent from "./barcharts/bar.recharts";
import AreachartComponent2 from "./areacharts2/area.rechart";
import { forwardRef } from 'react';
import { chartsTypes } from "./chartsDashboard";
import { chartGroup, chartType, errors, PROVIDERS, TECHNOLOGYS } from '../../controller/Constants'
import AreasChart from "../Common/areasChart/AreasChart";
import { providersColors } from "./Constants";


function CoveragePerformance({ data, providers }, ref) {

  return (<>
    {data &&
      <Container fluid style={{ textAlign: "center", paddingRight: 0, width: "100%" }}>
        <Row >
          {providers.map((item, index) => (
            <Col md={12} lg={6} xl={4}>   <h4  > {providers[index]}</h4>  <BarRechartComponent data={data.coveragePerformance.filter(placement => placement[providers[index]]).map((placement) => ({ data: placement[providers[index]] }))[0]} name={`${chartsTypes.COVERAGE_PERFORMANCE}-${providers[index]}-${TECHNOLOGYS._4G}`} ref={ref} />   </Col>
          ))}
        </Row>
        <Row style={{ width: "100%" }}  >
          <Col md={{ span: 4, offset: 2 }} lg={6} xl={4}> <h4> Distribution</h4> <AreasChart providers={providers} data={data.coveragePerformanceDistribution[0]} width={"100%"} height={280} yaxisKey={"multy"} xaxisKey={"range"} domain={[0, 100]} yaxisLable={"%"} xaxisLable={"dBm"} colors={providersColors} name={`${chartGroup.DYNAMIC_POLYGON}-${chartGroup.COVERAGE_PERFORMANCE}-${chartType.DISTRIBUTION}`} ref={ref} />  </Col>
          <Col md={12} lg={6} xl={1}> <h4> </h4>   </Col>
          <Col md={12} lg={6} xl={4}> <h4> Best Server</h4>{providers.length > 0 && < BarRechartComponent data={data.coveragePerformanceBestServer[0]} name={`${chartsTypes.COVERAGE_PERFORMANCE}-${TECHNOLOGYS._4G}-best-server`} ref={ref} />}  </Col>
        </Row>
      </Container>
    }
  </>
  );
}
export default forwardRef(CoveragePerformance);


