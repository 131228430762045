import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserEnvironment, setIndoorFloorEnv } from "../store/actionCreators/map";

export function useUserEnvironment() {
    const userEnv = useSelector(state => state.map.userEnvironment);
    return userEnv;
}

export function useSetUserEnvironment() {
    const dispatch = useDispatch();
    return useCallback((environment) => dispatch(setUserEnvironment(environment)), [dispatch]);
}

function useIndoorFloorEnv() {
    const indoorFloorEnv = useSelector(state => state.map.indoorFloorEnv);
    return indoorFloorEnv;
}

function useSetIndoorFloorEnv() {
    const dispatch = useDispatch();
    return useCallback((indoorFloorEnv) => dispatch(setIndoorFloorEnv(indoorFloorEnv)), [dispatch]);
}

export function useIndoorFloorEnvState() {
     return [useIndoorFloorEnv(), useSetIndoorFloorEnv()];
}